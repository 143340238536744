export const HomeAffiliation = () => {

  return (
    <div className="col-md-9 mt-5 mx-auto">
      <div className="row">
        <div className="col-md-3 text-center">
          <div className="card border-0">
            <div className="card-body">
              <a href="https://www.bqa.gov.bh" target="_blank" rel="noreferrer">
                <img src={require('../../assets/logo/bqa_logo.png')} className="img-fluid" alt='...' />
              </a>
              <p className='text-xs mt-2'><a className="text-danger d-block" href="https://drive.google.com/file/d/1jlr-L2V9SBYpKa67iard04gUQf2mUxZy/view?usp=sharing" target="_blank" rel="noreferrer">
                BQA Self-Evaluation Form and Pre-review Brief, July 2024</a></p>

              <p className='text-xs'><a className="text-danger d-block" href="https://www.bqa.gov.bh/En/Reports/SchoolsReports/SchoolReport/IKNS%20review%20report%20English.pdf" target="_blank" rel="noreferrer">
                Second Cycle Report, March 2017</a></p>

              <p className='text-xs'><a className="text-danger d-block" href="https://www.bqa.gov.bh/En/Reports/SchoolsReports/SchoolReport/Ibn%20Khuldoon%20National%20ENn.pdf" target="_blank" rel="noreferrer">
                First Cycle Report, September 2013</a></p>

            </div>
          </div>
        </div>

        <div className="col-md-3 text-center">
          <div className="card border-0">
            <div className="card-body">
              <a href="https://www.msa-cess.org/" target="_blank" rel="noreferrer">
                <img src={require('../../assets/logo/msa_logo.png')} className="img-fluid" alt='...' />
              </a>

              <p className='text-xs mt-2'><strong>MSA Accreditation 2023-2030</strong></p>
              <p className='text-xs'><a className="text-danger d-block" href="https://drive.google.com/file/d/1PcsZnaIWa0TqI8R-ODAg5t4UzntfO57C/view" target="_blank" rel="noreferrer">
                MSA Certificate of Accreditation 2023-2030</a></p>

              <p className='text-xs'><a className="text-danger d-block" href="https://drive.google.com/file/d/1Ib-Bt33kFWLbFZ6QIRgqRHCwHiXhhfUd/view" target="_blank" rel="noreferrer">
                MSA Standard Self Assessment & Visiting Team Report, May 2023</a></p>


              <p className='text-xs mt-5'><strong>MSA Accreditation 2016-2023</strong></p>
              <p className='text-xs'><a className="text-danger d-block" href="https://drive.google.com/file/d/1ExgptQ_YNOI9o1lvcmf4Ze5hYTjljyXm/view" target="_blank" rel="noreferrer">
                MSA Accreditation Award Letter
              </a></p>

              <p className='text-xs'><a className="text-danger d-block" href="https://drive.google.com/file/d/1RxpWQRILsrq0B6utWsRm8DTP5EQIPJSt/view" target="_blank" rel="noreferrer">
                MSA Accreditation Team Report
              </a></p>



            </div>
          </div>
        </div>


        <div className="col-md-3 text-center">
          <div className="card border-0">
            <div className="card-body">
              <a href="http://www.theptc.org" target="_blank" rel="noreferrer">
                <img src={require('../../assets/logo/ptc_logo.png')} className="img-fluid" alt='...' />
              </a>
              <p className='text-xs mt-2'><a className="text-danger d-block" href="https://www.theptc.org" target="_blank" rel="noreferrer">
                PTC Member School</a></p>
            </div>
          </div>
        </div>


        <div className="col-md-3 text-center">
          <div className="card border-0">
            <div className="card-body">
              <a href="http://www.theptc.org" target="_blank" rel="noreferrer">
                <img src={require('../../assets/logo/school_in_motion.png')} className="img-fluid" alt='...' />
              </a>
              <p className='text-xs mt-2'><a className="text-danger d-block" href="https://drive.google.com/file/d/1Hxp7KhQLg2fANVZrVuqcWtKO-lr5P020/view" target="_blank" rel="noreferrer">
                ISF Schools in Motion Certificate</a></p>

              <p className='text-xs mt-2'><a className="text-danger d-block" href="https://drive.google.com/file/d/1ywlME0jZGrF49ixavmgOTts5XKci_KPw/view" target="_blank" rel="noreferrer">
                IKNS Schools in Motion Interview</a></p>
            </div>
          </div>
        </div>



      </div>
    </div>
  )
}