export const SidebarLinks = [
  {
    label: "IB Learner Profile",
    path: "/ib-learner-profile"
  },

  {
    label: "Approaches to Learning",
    path: "/approaches-to-learning"
  },
  {
    label: "Approaches to Teaching",
    path: "/approaches-to-teaching"
  },

];

export default SidebarLinks;
