import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import '../../styles/headers.css';
import '../../styles/innerpage.css';
import sidebarLinks from '../../components/sidebar/admission';

export const EntranceExamsRequirements = () => {

  const location = useLocation();

  return (
    <div>
      <Navigation />
      <div id="admission" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">

        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      {link.target ? (
                        <a className="text-sm" href={link.path} target={link.target}>
                          {link.label}
                        </a>
                      ) : (
                        <Link className="text-sm" to={link.path}>
                          {link.label}
                        </Link>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="d-block d-md-none">
              <select
                className="form-select"
                onChange={(e) => window.location.href = e.target.value}
                value={location.pathname}
              >
                {sidebarLinks.map((link, index) => (
                  <option key={index} value={link.path}>
                    {link.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="/">Admission</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>Entrance Exams Requirements</strong></Link></li>
                </ol>
              </div>
            </div>

            <h4 className="mt-3 page-title">Entrance Exams Requirements</h4>
            <div className="col-md-12 mx-auto">

              <p>Please check the links in the table below to view the relevant topics / skills for each grade level.</p>


              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th style={{ background: '#2b2729', color: '#fff', verticalAlign: 'middle' }} className="p-3">Grade Level</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td><a href="https://drive.google.com/file/d/14D_spe9jVPuafMSEjxJFyrA2lMFXFbUU/view" target="_blank" rel="noreferrer" className="text-link fw-normal">Kindergarten 1</a></td>
                  </tr>
                  <tr>
                    <td><a href="https://drive.google.com/file/d/1GT4jQ6vNXhXpD0TJ30sdcwhDMjJY0pmG/view" target="_blank" rel="noreferrer" className="text-link fw-normal">Kindergarten 2</a></td>
                  </tr>
                  <tr>
                    <td><a href="https://drive.google.com/file/d/1U7HsqAu1KQMeEltNHVXz6qqwlD3VmO3-/view" target="_blank" rel="noreferrer" className="text-link fw-normal">Grades 1 to 5</a></td>
                  </tr>

                  <tr>
                    <td><a href="https://drive.google.com/file/d/18cTdhikt9QNNkDfABrlf_U81S_bKrd8i/view" target="_blank" rel="noreferrer" className="text-link fw-normal">Grade 6</a></td>
                  </tr>
                  <tr>
                    <td><a href="https://drive.google.com/file/d/1hQhAd0p8VJHXXo72E9lOp6_NPenu8RF5/view" target="_blank" rel="noreferrer" className="text-link fw-normal">Grade 7</a></td>
                  </tr>
                  <tr>
                    <td><a href="https://drive.google.com/file/d/1MiAd-zNdihAQ8c8HzsU8ty4Ip0wXxtsU/view" target="_blank" rel="noreferrer" className="text-link fw-normal">Grade 8</a></td>
                  </tr>
                  <tr>
                    <td><a href="https://drive.google.com/file/d/12r8bX3VrDOYd9ykzDF3jnENK0BPljv8v/view" target="_blank" rel="noreferrer" className="text-link fw-normal">Grade 9</a></td>
                  </tr>
                  <tr>
                    <td><a href="https://drive.google.com/file/d/1GW-LYmoTI7Tzd-rzAtjX_rEtQEFtoLn3/view" target="_blank" rel="noreferrer" className="text-link fw-normal">Grade 10</a></td>
                  </tr>
                  <tr>
                    <td><a href="https://drive.google.com/file/d/1PPw6l9jTjBJEP7rTaPCR_nd3a89EsQMz/view" target="_blank" rel="noreferrer" className="text-link fw-normal">Grade 11</a></td>
                  </tr>
                </tbody>
              </table>




            </div>
          </div>
        </div>

        <div className="contact-section col-lg-9 mx-auto">
          <h4 className="mt-3 page-title">Contact Us</h4>
          <p>If you have questions about the School Admission, we are here to help.</p>
          <Link to="/contact-us" className="text-link">Go to contact form <i className="fas fa-arrow-right text-right"></i></Link>
        </div>

      </div>
      <Footer />
    </div>
  )
}


