import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/pyp';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const PypatIkns = () => {

  const location = useLocation();

  return (
    <div>
      <Navigation />
      <div id="pyp" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="d-block d-md-none">
              <select
                className="form-select"
                onChange={(e) => window.location.href = e.target.value}
                value={location.pathname}
              >
                {sidebarLinks.map((link, index) => (
                  <option key={index} value={link.path}>
                    {link.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>The PYP at IKNS</strong></Link></li>
                </ol>
                <h4 className="mt-3 page-title">The PYP at IKNS</h4>
              </div>
            </div>


            <div className="img-right col-md-6">
              <img src={require('../../assets/img/pyp/pyp_at_ikns.png')} className="img-fluid rounded" alt='pyp' />
            </div>


            <p>
              The instructional programme at IKNS addresses four of the six transdisciplinary
              themes every school year in KG1 and KG2, and all six themes in grades 1 to 5.
            </p>

            <p>
              Each transdisciplinary theme is introduced through a Unit of Inquiry (UoI)
              that carries the same title as the transdisciplinary theme itself. Hence,
              in each school year, KG1 and KG2 students study four Units of Inquiry,
              and students in grades 1 to 5 study six Units of Inquiry.
            </p>

            <p>
              In school, students take responsibility for their learning and collaborate
              with teachers and other students to plan, present and assess learning needs.
              Students inquire, question, wonder and theorize about themselves, others and
              the world around them. They are keen observers and explorers.
            </p>

            <p>
              Since the development of mother tongue language is crucial for maintaining cultural
              identity, we believe that education in mother tongue language, particularly in the
              early years of schooling, is vital for concept formation as well as literacy and
              numeracy attainment.
            </p>

            <p>
              From KG1 up to Grade 2 (the Lower Elementary), the Unit of Inquiry (UoI) is
              taught in Arabic, while English is offered as a subject. The UoI includes
              Science, Social Studies, Mathematics and Arabic, and is taught by specialist
              homeroom teachers and English is taught by native English speaking teachers.
              In addition, students attend specialist lessons for Visual Arts, Performing Arts,
              Physical, Social and Personal Education, Math skills and Library research skills,
              as well as Islamic Studies, National and Social Studies, as per the requirements
              of the Bahrain Ministry of Education.
            </p>

            <p>
              In Grades 3, 4 and 5 (the Upper Elementary), the UoI is taught in English, while Arabic
              is taught as a subject. Similar to the Lower Elementary, the UoI in the upper Elementary
              includes Science, Social Studies, Mathematics and English, and is taught by specialist
              homeroom native English speaking teachers and Arabic is taught by specialist teachers.
              In addition, students attend specialist lessons for Visual Arts, Performing Arts,
              Physical, Social and Personal Education, Math skills, ICT skills and Library research
              skills, as well as Islamic Studies, National and Social Studies, as per the requirements
              of the Bahrain Ministry of Education.
            </p>

            <p>
              Adequate access to digital technologies is increasingly important to support student
              inquiries as they develop in the classroom or other learning spaces. ICT and computing
              skills are incorporated within the different subjects from KG1 to Grade 5. In addition,
              students in Grades 3, 4 and 5 attend one lesson per week focusing on main ICT and
              computing skills.
            </p>

            <a href="https://drive.google.com/file/d/1iHLOI1hfE9318V4EhJqz6PrY-fsL0qa-/view?usp=sharing" target="_blank" rel="noreferrer" className="text-link">
              View The Programme of Inquiry 2019 - 2020 <i className="fas fa-arrow-right text-right"></i></a>

          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}


