import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/ibdp-programme';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const IbdpAssessment = () => {

  const location = useLocation();

  return (
    <div>
      <Navigation />
      <div id="ibdp" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="d-block d-md-none">
              <select
                className="form-select"
                onChange={(e) => window.location.href = e.target.value}
                value={location.pathname}
              >
                {sidebarLinks.map((link, index) => (
                  <option key={index} value={link.path}>
                    {link.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                  <li className="breadcrumb-item"><Link to="#">The IB Diploma Programme (G11-G12)</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>IBDP Assessment</strong></Link></li>
                </ol>
                <h4 className="mt-3 page-title">IBDP Assessment</h4>
              </div>
            </div>


            <img src={require('../../assets/img/ibdp/IBDPAssessment.png')} className="rounded img-fluid" alt='ibdp' />

            <p className="content-title content-qoute mt-3  ">
              IBDP students sit for cumulative examinations in the six subjects at
              the end of the second year of the programme and receive an IB Diploma
              from the International Baccalaureate.
            </p>

            <p>
              The IB Diploma score is out of 45 total possible points.
            </p>
            <p>
              Each of the six subjects is graded on a scale of 1-7. The grade is
              decided based on three or four components for each subject. The majority
              of components are externally assessed by IB examiners.
            </p>

            <p>
              Each subject has one internally assessed component that the student
              completes over the two year programme with the guidance of his/her
              teacher. This component is marked by the teacher and a sample of
              work is selected and moderated by an IB Examiner which may lead to
              an adjustment of the marks.
            </p>

            <p>
              The Extended Essay and TOK are graded on a scale of A-E giving up to
              3 diploma points according to the matrix:
            </p>


            <div className="col-md-12 text-center">
              <img src={require('../../assets/img/ibdp/IBDPAssessment_table.png')} className="rounded img-fluid col-md-8" alt='ibdp' />
            </div>


            <small className="d-block">
              Source: <a href="https://www.ibo.org/contentassets/1cdf850e366447e99b5a862aab622883/assessment-principles-and-practices-2018-en.pdf" className="text-primary" target="_blank" rel="noreferrer">
                "Understanding DP Assessment." International Baccalaureate, 2018,
                www.ibo.org/contentassets/1cdf850e366447e99b5a862aab622883/assessment-principles-and-practices-2018-en.pdf.</a>
            </small>


            <p className="content-title mt-5">
              IBDP Mock Examinations
            </p>
            <p>
              These are practice IB examinations that students at IKNS sit in April of
              their graduating year. Teachers prepare papers that match the length
              and style of the IB exams. The mock exams are marked by IKNS teachers
              and the IB subject boundaries are used to decide on the student's grade
              in the subject. These exams replace the semester exams in the second
              semester of Grade 12.
            </p>

            <p className="content-title mt-5">
              IBDP External Examinations
            </p>
            <p>
              IB External examination take place in May, and the IB results are issued on
              5th July and the official documents arrive in school at the end of August.
            </p>


            <p className="content-title mt-5">
              IBDP Achievement Levels
            </p>

            <p>
              The IKNS Assessment Policy links the IBDP Achievement levels (1 - 7) to
              percentage grades according to the table below.
            </p>


            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>IB Grade</th>
                  <th>Descriptor</th>
                  <th>Percentage Grade</th>
                </tr>

                <tr>
                  <td>7</td>
                  <td>Excellent performance</td>
                  <td>90 - 100%</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>Very good performance</td>
                  <td>80 - 89%</td>
                </tr>

                <tr>
                  <td>5</td>
                  <td>Good performance</td>
                  <td>70 - 79%</td>
                </tr>

                <tr>
                  <td>4</td>
                  <td>Good performance</td>
                  <td>60 - 69%</td>
                </tr>

                <tr>
                  <td>3</td>
                  <td>Mediocre performance</td>
                  <td>50 - 59%</td>
                </tr>

                <tr>
                  <td>2</td>
                  <td>Poor performance</td>
                  <td>40 - 49%</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Very poor performance</td>
                  <td>0 - 39%</td>
                </tr>
              </tbody>
            </table>

            <p className="content-title mt-5">
              The following conditions would lead to failing the Diploma:
            </p>

            <ul>
              <li>Candidate is found guilty of academic misconduct</li>
              <li>Obtaining less than 24 diploma points</li>
              <li>Obtaining less than 12 points in three HL subjects</li>
              <li>A grade is not awarded for one or more subjects, TOK or EE (for example for missing an examination)</li>
              <li>A grade E is obtained on TOK or EE</li>
              <li>A grade 1 is obtained in a subject</li>
              <li>Not completing CAS</li>
            </ul>


            <small className="d-block">
              Source: <a href="https://www.ibo.org/contentassets/1cdf850e366447e99b5a862aab622883/assessment-principles-and-practices-2018-en.pdf" className="text-primary" target="_blank" rel="noreferrer">
                "Understanding DP Assessment." International Baccalaureate, 2018, www.ibo.org/contentassets/1cdf850e366447e99b5a862aab622883/assessment-principles-and-practices-2018-en.pdf.</a>
            </small>



          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}


