export const SidebarLinks = [

  {
    label: "General",
    path: "/video-gallery"
  },
  {
    label: "Honor Society",
    path: "/honor-society"
  },
  {
    label: "Facilities Video",
    path: "/facilities-video"
  },
  {
    label: "IKNS Virtual Tour",
    path: "/vtour/start"
  },


];

export default SidebarLinks;
