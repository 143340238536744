import { Link } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const SecondarySchool = () => {
  return (
    <div>
      <Navigation />
      <div id="secondary_school" className="container-fluid col-md-12 page-header"></div>
      <div id="one-page" className="wrapper">

        <div className="row">
          <div className="col-md-10">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item"><Link to="#">Key Info</Link></li>
              <li className="breadcrumb-item"><Link to="#"><strong>Secondary School (9-12)</strong></Link></li>
            </ol>
          </div>
        </div>

        <div className="row g-0">
          <div className="col-md-12 col-lg-6">
            <img src={require('../../assets/img/secondary/cover.png')} className="w-100 h-100 img-fluid" alt='...' />
          </div>
          <div className="col-md-12 col-lg-6 bg-gray p-3">
            <div className="container d-flex h-100">
              <div className="row justify-content-center align-self-center">
                <h4 className="mt-3 page-title">Secondary School (9-12)</h4>

                <p className="mt-3">The Secondary School is from Grade 9 to Grade 12. Starting September 2020,
                  the Secondary School will start offering the IB MYP as a candidate school
                  for Students in Grades 9 and 10 who already started the programme while at
                  the Middle School.</p>
                <p>Students in Grades 11 and 12 can choose between either the International
                  Baccalaureate Diploma Programme (IBDP) or the American High School Diploma
                  (AHSD). The IBDP and the AHSD are, both, tertiary education preparatory
                  programmes, and are considered to be equivalent to the Bahraini General
                  Certificate of Education. Students in the AHSD can also opt to study some
                  subjects from the IBDP.</p>
                <p>IKNS has been offering the IBDP since 1990. More information about the IBDP
                  the AHSD at IKNS can be found under the tab <Link to="/ibdp">ACADEMICS</Link> on the main page of the website.</p>


                <Link to="/ibdp" className="mt-2 d-block text-link">The IB Diploma Programme (G11-G12)</Link>
                <Link to="/american-high-school-diploma" className="mt-2 d-block text-link">The American High School Diploma (G11-G12)</Link>
                <Link to="/myp" className="mt-2 d-block text-link">The IB MYP Programme (G6-G10)</Link>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-section col-lg-9 mx-auto">
          <h4 className="mt-3 page-title">Contact Us</h4>
          <p>If you have questions about the  Secondary School, we are here to help.</p>
          <Link to="/contact-us" className="text-link">Go to contact form <i className="fas fa-arrow-right text-right"></i></Link>
        </div>
      </div>
      <Footer />
    </div>
  )
}


