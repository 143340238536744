import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/ibdp-programme';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const IbDiplomaProgramme = () => {

  const location = useLocation();

  return (
    <div>
      <Navigation />
      <div id="ibdp_programme" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="d-block d-md-none">
              <select
                className="form-select"
                onChange={(e) => window.location.href = e.target.value}
                value={location.pathname}
              >
                {sidebarLinks.map((link, index) => (
                  <option key={index} value={link.path}>
                    {link.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>The IB Diploma Programme (G11-G12)</strong></Link></li>
                </ol>
                <h4 className="mt-3 page-title">The IB Diploma Programme (G11-G12)</h4>
              </div>
            </div>

            <p>
              Ibn Khuldoon National School has been affiliated with the International Baccalaureate since September 1990. The first group of IKNS graduates sat for the IB Diploma Programme (IBDP) examinations in May 1992.
            </p>

            <p>
              The IBDP gives students in grades 11 and 12 a rigorous and balanced education that opens the doors for admission to the top universities around the world. It is a two-year programme ending with external examinations.
            </p>

            <p className="content-title mt-5">
              The IBDP Programme Model
            </p>



            <div className="img-right col-md-6">
              <img src={require('../../assets/img/ibdp/dp-model-en.png')} className="img-fluid" alt='pyp' />
            </div>


            <p>
              The curriculum is made up of three DP core elements and the six subject groups.
              The three DP core elements aim “to broaden the student's educational experience
              and challenge them to apply their knowledge and skills.
            </p>

            <p className="content-title mt-5">
              The three core elements are:
            </p>

            <ul>
              <li>Theory of Knowledge, in which students reflect on the nature of knowledge and on how we know what we claim to know.</li>
              <li>The Extended Essay, which is an independent, self-directed piece of research, finishing with a 4,000-word paper.</li>
              <li>Creativity, Activity, Service, in which students complete a project related to those three concepts.</li>
            </ul>

            <p className="content-title mt-3">
              The six subject groups are:
            </p>
            <ul>
              <li>Studies in Language and Literature</li>
              <li>Language Acquisition</li>
              <li>Individuals and societies</li>
              <li>Sciences</li>
              <li>Mathematics</li>
              <li>The Arts</li>
            </ul>

            <small>Source: <a href="http://www.ibo.org/programmes/diploma-programme/curriculum/" className="text-primary" target="_blank" rel="noreferrer">
              Iborganization. "DP Curriculum." International Baccalaureate®, 2005,
              www.ibo.org/programmes/diploma-programme/curriculum/.</a></small>


            <p className="content-title mt-5">
              Languages in the IBDP
            </p>

            <p>
              Languages in the IBDP are categorised into two subject groups, allowing
              students to study two languages at different levels of mastery. An IBDP
              student must study one Language from the subject group (Studies in Language
              and Literature). The student can choose his/her second language from the
              same subject group (Studies in Language and Literature) or from the second
              language group (Language Acquisition). IBDP Students who study two language
              courses from the language subject group (Studies in Language and Literature)
              receive the prestigious bilingual IB diploma.
            </p>

            <p className="content-title mt-5">
              The IBDP at IKNS
            </p>

            <p><strong>IKNS students have the opportunity to participate in the IBDP on two levels:</strong></p>

            <ol>
              <li>An IBDP candidate - this means completing the six subjects (with three or four subjects at the higher level) as well as the core elements of the IBDP</li>
              <li>A Course candidate - students can select a single or several DP subjects at either higher or standard level</li>
            </ol>

            <p className="mt-3"><strong>Students opting for the IBDP select three (or four) subjects at the Higher Level (HL) and three (or two) subjects at the Standard Level (SL), including:</strong></p>
            <ul>
              <li>Two languages: One of them must be from the Studies in Language and Literature subject group. Languages offered at IKNS are Arabic and English.</li>
              <li>Mathematics</li>
              <li>A Science</li>
              <li>An Individuals And Societies subject</li>
              <li>A sixth subject, which could be an Arts subject, or another Science or Individuals and Societies subject.</li>
            </ul>

            <p className="content-title mt-5">
              Higher and Standard Level Courses
            </p>
            <p>Higher Level (HL) courses require 240 hours to be covered. They provide students with in-depth preparation for their university studies. UK universities treat HL courses as equivalent to A-levels. While Standard Level (SL) subjects require 150 hours to be covered. They provide students with breadth and a well-balanced education.</p>
            <p>IKNS allocates five hours (lessons) per cycle (1 cycle is 6 school days) for HL courses over Grades 11 and 12, while SL courses are allocated four hours in Grade 11 and three hours in Grade 12.</p>
            <p>IBDP students have three teacher-supervised Study Periods in grade 11 which they use for managing the demands of the programme. This includes organizing their Extended Essay, Internal Assessments, reading and working on assignments, and later on, working on college applications and personal statement, among other things. The teacher-supervised Study Periods is time provided to students to develop their Approaches to Learning Skills.</p>
            <p>Grade 12 students continue to have three teacher-supervised study periods, and also have two free periods. During the free periods, students may use the Media Centre, the Career Lab or the Students’ Lounge for private study.</p>
            <p>HL courses have an added value of 1.5 GP and SL courses have an added value of 1.0 GP. When calculating a student’s annual average 15% is added to an HL average and 10% is added to an SL average.</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}


