import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/ibdp-programme';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const TheoryofKnowledge = () => {

  const location = useLocation();

  return (
    <div>
      <Navigation />
      <div id="ibdp" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="d-block d-md-none">
              <select
                className="form-select"
                onChange={(e) => window.location.href = e.target.value}
                value={location.pathname}
              >
                {sidebarLinks.map((link, index) => (
                  <option key={index} value={link.path}>
                    {link.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                  <li className="breadcrumb-item"><Link to="#">The IB Diploma Programme (G11-G12)</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong> Theory of Knowledge</strong></Link></li>
                </ol>
                <h4 className="mt-3 page-title"> Theory of Knowledge</h4>
              </div>
            </div>



            <div className="img-right col-md-6">
              <img src={require('../../assets/img/ibdp/theory-of-knowledge.png')} className="rounded img-fluid" alt='pyp' />
            </div>

            <p>
              Theory of Knowledge (ToK) is one of the three core elements of the IBDP.
              It is a course in which students reflect on the nature of knowledge and
              on how we know what we claim to know.
            </p>


            <p>
              TOK at IKNS is allocated two periods per cycle over the two-year programme.
              Students submit their ToK Presentations in the second semester of Grade 11,
              and submit their ToK Essay on one of the Prescribed Questions in the second
              semester of Grade 12. Subject teachers are encouraged to discuss relevant
              TOK questions in their subject classroom to enrich the experience of
              students and help them appreciate how knowledge is created in each subject.
            </p>

          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}


