import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/ib-learner-profile';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const ApproachestoLearning = () => {

  const location = useLocation();


  return (
    <div>
      <Navigation />
      <div id="ib_learner_profile" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>Approaches to Learning</strong></Link></li>
                </ol>
              </div>
            </div>

            <h4 className="mt-3 page-title">Approaches to Learning</h4>


            <div className="col-md-12 mx-auto">
              <p>Approaches to Learning (ATL) are skills and attitudes that all IB programmes aim to help students develop. These skills support the IB learner profile and enhance learning in school and beyond.</p>
              <p>The IB summarizes ATL in five main categories that infiltrate all IB programs. The table below states the five categories of ATL and a few examples of each element:</p>


              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td className="align-middle" style={{ color: "#fff", backgroundColor: "#6A1F41" }}>Thinking Skills </td>
                    <td>• Critical thinking • Creativity and innovation • Transfer</td>
                  </tr>

                  <tr>
                    <td className="align-middle" style={{ color: "#fff", backgroundColor: "#6A1F41" }}>Communication Skills</td>
                    <td style={{ backgroundColor: "#ddd" }}>• Listening • Writing • Presenting • Viewing • Non-verbal communication • Reading</td>
                  </tr>

                  <tr>
                    <td className="align-middle" style={{ color: "#fff", backgroundColor: "#6A1F41" }}>Social Skills</td>
                    <td>• Accepting responsibility • Group decision making • Resolving conflict</td>
                  </tr>

                  <tr>
                    <td className="align-middle" style={{ color: "#fff", backgroundColor: "#6A1F41" }}>Self Management Skills</td>
                    <td className="align-middle" style={{ backgroundColor: "#ddd" }}>• Organization • Affective • Reflection</td>
                  </tr>

                  <tr>
                    <td className="align-middle" style={{ color: "#fff", backgroundColor: "#6A1F41" }}>Research Skills</td>
                    <td>• Information literacy • Media literacy</td>
                  </tr>

                </tbody>
              </table>

              <p style={{ fontSize: "13px" }}><em>Source: Approaches to teaching and learning. International Baccalaureate, 2014, <a href="https://www.ibo.org/globalassets/digital-tookit/flyers-and-artworks/approaches-to-teaching-learning-dp-en.pdf" className="text-link" target="_blank" rel="noreferrer">
                https://www.ibo.org/globalassets/digital-tookit/flyers-and-artworks/approaches-to-teaching-learning-dp-en.pdf</a></em></p>





            </div>
          </div>
        </div>
      </div>



      <Footer />
    </div>
  )
}

