
export const SidebarLinks = [
  {
    label: "Schoology/Powerschool Tutorial and Information",
    path: "/schoology-powerschool-tutorial-and-information"
  },


];

export default SidebarLinks;
