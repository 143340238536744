import React, { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import '../../styles/headers.css';
import '../../styles/innerpage.css';
import menuSidebar from './menu.json';



export const NewTerm = () => {

  const location = useLocation();
  const [sidebar, setSidebar] = useState({ sideMenu: [] });
  const [expandedItems, setExpandedItems] = useState({});

  useEffect(() => {
    setSidebar(menuSidebar);
  }, []);

  const toggleExpand = (index) => {
    setExpandedItems(prev => {
      const newState = {};
      // Collapse all items
      Object.keys(prev).forEach(key => {
        newState[key] = false;
      });
      // Toggle the clicked item
      newState[index] = !prev[index];
      return newState;
    });
  };

  // Helper function to render menu item
  const renderMenuItem = (item) => {
    // For items with '#' slug
    if (item.slug === '#') {
      return (
        <Link to="#" className="text-sm">
          {item.title}
        </Link>
      );
    }

    // For external links (starting with http/https)
    if (item.slug.startsWith('http')) {
      return (
        <a
          href={item.slug}
          className="text-sm"
          target={item.target || '_self'}
          rel={item.target === '_blank' ? "noopener noreferrer" : undefined}
        >
          {item.title}
        </a>
      );
    }

    // For internal links
    return (
      <Link to={`/${item.slug}`} className="text-sm">
        {item.title}
      </Link>
    );
  };


  return (
    <div>
      <Navigation />
      <div id="governing_body" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {/* Desktop view */}
                <div className="d-none d-md-block">
                  {sidebar.sideMenu.map((link, index) => (
                    <div key={index}>
                      <div
                        className={location.pathname === `/${link.slug}` ? "active-menu" : "inactive"}
                        onClick={() => toggleExpand(index)}
                        style={{ cursor: 'pointer' }}
                      >
                        {renderMenuItem(link)}
                      </div>
                      {link.subItems && expandedItems[index] && (
                        <div className="sub-menu ms-3">
                          {link.subItems.map((subItem, subIndex) => (
                            <div
                              key={subIndex}
                              className={location.pathname === `/vtour/${subItem.slug}` ? "active-menu" : "inactive"}
                            >
                              {renderMenuItem(subItem)}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">IKNS Community</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>2025 New Term</strong></Link></li>
                </ol>
                <h4 className="mt-3 page-title">2025 New Term</h4>
              </div>
            </div>


            <p className="tag-arabic" dir="rtl" lang="ar">
              السادة أسرة مدرسة إبن خلدون الوطنية المحترمين،
            </p>

            <p className="tag-arabic" dir="rtl" lang="ar">
              تحية طيبة وبعد،
            </p>


            <p className="tag-arabic" dir="rtl" lang="ar">
              يسرني أن أكتب إليكم لإعلامكم بانتهاء دورة مجلس أمناء المدرسة ومجلس إدارتها مع نهاية العام الدراسي ٢٠٢٣-٢٠٢٤. ووفقًا للنظام الأساسي لمجلس أمناء المدرسة، عقد المجلس اجتماعًا بتاريخ ١٥ يناير ٢٠٢٥ لتعيين رئيس جديد للمجلس في دورته القادمة، خلفًا للمرحوم الوجيه فاروق يوسف المؤيد، رحمه الله وأسكنه فسيح جناته، بالإضافة إلى تعيين نائب لرئيس مجلس الأمناء. وقد تم التوافق على القرارات التالية:
            </p>


            <ol className="tag-arabic" dir="rtl" lang="ar">
              <li>
                <span className="fw-bold">تعيين السيد سطام سليمان القصيبي، </span>نائب رئيس مجلس الأمناء السابق، رئيسًا لمجلس الأمناء.
              </li>

              <li><span className="fw-bold">تعيين السيد فؤاد علي تقي،</span>  نائب رئيس مجلس الإدارة السابق، نائبًا لرئيس مجلس الأمناء.
              </li>
            </ol>


            <p className="tag-arabic" dir="rtl" lang="ar">
              كما انتهت دورة مجلس إدارة المدرسة، وتم الاتفاق على التعيينات التالية:
            </p>



            <ol className="tag-arabic" dir="rtl" lang="ar">
              <li>
                <span className="fw-bold">تعيين السيدة هلا فاروق المؤيد، </span>
                عضو مجلس الأمناء وعضو مجلس الإدارة وعضو اللجنة التربوية، رئيسةً لمجلس إدارة المدرسة، خلفًا للأستاذة فريدة عبدالرحمن المؤيد.
              </li>
              <li>

                <span className="fw-bold">تعيين السيد يوسف أحمد الحمادي،</span> رئيس اللجنة المالية، نائبًا لرئيس مجلس الإدارة، خلفًا للسيد فؤاد علي تقي.

              </li>
            </ol>


            <p className="tag-arabic" dir="rtl" lang="ar">

              كما أود أن أعبر عن شكري العميق للأستاذة

              <spam className="fw-bold"> فريدة عبدالرحمن المؤيد،</spam>
              أطال الله في عمرها وأدام عليها ثوب الصحة والعافية، على قيادتها الحكيمة لمجلس الإدارة منذ عام ٢٠٠٨ وحتى نهاية الدورة الحالية. لقد عملت بلا كلل، بالتعاون مع رؤساء وأعضاء اللجان المتخصصة التابعة للمجلس، على تعزيز القواعد المؤسسية التي دعمت تطور المدرسة واستدامتها. وأخص بالشكر كل من:

            </p>





            <ul className="tag-arabic" dir="rtl" lang="ar">
              <li>
                السيد <span className="fw-bold">فؤاد علي تقي،</span>
                نائب رئيس مجلس الإدارة.
              </li>
              <li>
                الدكتورة
                <span className="fw-bold"> رنده حمادة،</span>
                رئيسة اللجنة التربوية.
              </li>

              <li>
                السيدة <span className="fw-bold">سونيا قمبر،</span>
                أمين سر مجلس الإدارة.
              </li>

              <li>
                السيد <span className="fw-bold">يوسف الحمادي،</span> رئيس اللجنة المالية.
              </li>
              <li>
                سعادة السيد
                <span className="fw-bold">يوسف خلف،</span> رئيس اللجنة القانونية.
              </li>

              <li>
                الدكتور
                <span className="fw-bold"> نبيل المسقطي،</span> رئيس اللجنة الفنية.
              </li>

            </ul>


            <p className="tag-arabic" dir="rtl" lang="ar">
              لقد كان لي شرف العمل معهم ومع باقي أعضاء اللجان، وهو وسام أعتز به و أحمله في قلبي ووجداني.
            </p>


            <p className="tag-arabic" dir="rtl" lang="ar">
              وأوجه جزيل الشكر والتقدير أيضًا لمجلس أولياء الأمور والمعلمين والطلبة، والمعلمين والموظفين الأفاضل، وأولياء الأمور الكرام والطلاب الأعزاء، على دعمهم المتواصل. وأتمنى لمجلس الأمناء ومجلس الإدارة في دورتهما الجديدة كل التوفيق في تحقيق رؤية المدرسة المستقبلية، التي أرساها الآباء المؤسسون على أسس التعاون والمصداقية والشفافية والمؤسسية.  لقد أثمرت هذه الجهود في خريجي المدرسة المتميزين وطلابها الحاليين. وأترحم على روح الوجيه <span className="fw-bold"> فاروق يوسف المؤيد، </span>
              الذي غرس في نفوس الكثيرين محبة المدرسة وروح العطاء لها دون مقابل.
            </p>


            <p className="tag-arabic" dir="rtl" lang="ar">
              مع خالص التقدير،
            </p>


            <p className="tag-arabic" dir="rtl" lang="ar">
              د. كمال عبدالنور

              <br />
              رئيس المدرسة
            </p>


            <p className="mt-5">Dear Members of the IKNS Family,</p>

            <p>Greetings,</p>
            <p>I am pleased to write to you to inform you of the conclusion of the term of the School&#39;s Board of Trustees and Board of Directors at the end of the 2023-2024 academic year. In accordance with the school&#39;s Board of Trustees bylaws, a meeting was held on January 15, 2025, to appoint a new Chairman of the Board of Trustees for the upcoming term, succeeding the late Mr. Farouk Yousuf Al-Moayyed, may he rest in peace, as well as to appoint a Vice Chairman of the Board of Trustees. The following decisions were made:</p>

            <ol>
              <li className="mb-3"><span className="fw-bold">Mr. Sattam Suleiman Algosaibi,</span> the former Vice Chairman of the Board of Trustees, has been appointed Chairman of the Board of Trustees.</li>
              <li className="mb-3"><span className="fw-bold">Mr. Fuad Ali Taqi,</span> the former Vice Chairman of the Board of Directors, has been appointed Vice Chairman of the Board of Trustees.</li>
            </ol>

            <p className="mt-5">Additionally, the term of the Board of Directors has ended, and the following appointments were agreed upon:</p>

            <ol>
              <li className="mb-3"><span className="fw-bold">Ms. Hala Farouk Almoayyed,</span> a member of the Board of Trustees, the Board of Directors, and the Educational Committee, has been appointed Chairwoman of the Board of Directors, succeeding Ms. Fareeda Abdulrahman Almoayed.</li>
              <li className="mb-3"><span className="fw-bold">Mr. Yusuf Ahmed Al Hammadi,</span> Chairman of the Financial Committee, has been appointed Vice Chairman of the Board of Directors, succeeding Mr. Fuad Ali Taqi.</li>
            </ol>

            <p>On this occasion, I extend my congratulations to the esteemed members who have been appointed to their new leadership roles on the Board of Trustees and the Board of Directors. I also express my gratitude to the members who have renewed their commitment to serving on the board, as well as to those who have chosen not to renew their membership. To all of them, I offer my utmost respect and appreciation.</p>

            <p>I would also like to extend my heartfelt thanks and gratitude to Ms. Fareeda Abdulrahman Almoayed, may she enjoy good health and a long life, for her wise leadership as Chairwoman of the Board of Directors since 2008 until the end of the current term. During her tenure, she worked tirelessly, in collaboration with the heads and members of the specialized committees under the Board of Directors, to establish institutional foundations that have supported the sustainability and development of the school. I would particularly like to thank:</p>


            <ol>
              <li className="mb-3"><span className="fw-bold">Mr. Fuad Ali Taqi,</span> Vice Chairman of the Board of Directors.</li>
              <li className="mb-3"><span className="fw-bold">Dr. Randah Hamadeh,</span> Chairwoman of the Educational Committee.</li>
              <li className="mb-3"><span className="fw-bold">Ms. Sonia Qambar,</span> Secretary of the Board of Directors.</li>
              <li className="mb-3"><span className="fw-bold">Mr. Yusuf Al Hammadi,</span> Chairman of the Financial Committee.</li>
              <li className="mb-3"><span className="fw-bold">H.E. Mr. Yousef Khalaf,</span> Chairman of the Legal Committee.</li>
              <li className="mb-3"><span className="fw-bold">Dr. Nabeel Al Maskati,</span> Chairman of the Technical Committee.</li>
            </ol>


            <p className="mt-5">It has been a great honor to work with them and the other members of the committees. This opportunity is a badge of honor that I hold dearly in my heart and mind.</p>
            <p>I also express my deep gratitude and appreciation to the Parent, Teacher, Student Association, the esteemed teachers and staff, the respected parents, and the dear students for their continuous support.  I wish the Board of Trustees and the Board of Directors in their new term every success in achieving the school’s future vision. This vision was laid down by the founding fathers through diligent work rooted in collaboration, transparency, credibility, and institutional principles. The outcomes of these efforts are clearly evident in the school’s distinguished graduates and current students. I also pay tribute to the late
              <span className="fw-bold"> Mr. Farouk Yousuf Almoayyed,</span> who instilled in many a deep love for the school and a spirit of selfless giving.</p>

            <p>With sincere regards,</p>

            <p><span className="fw-bold">Dr. Kamal Abdel-Nour</span><br />
              School President</p>


          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}


