import React, { useState, useEffect } from "react";
import { useParams, Link, useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import * as Constants from "../../queries/articleDetails";
import * as Sidebar from "../../queries/articleSidebar";
import '../../styles/headers.css';
import '../../styles/innerpage.css';
// import YouTube from 'react-youtube'; // If you choose to use react-youtube

export const ArticleDetails = () => {
  const { slug } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [records, setRecords] = useState({ posts: [] });
  const [sidebar, setSidebar] = useState({ posts: [] });

  useEffect(() => {
    const fetchApi = async () => {
      try {
        const queryResult = await axios.post(
          Constants.GRAPHQL_API,
          {
            query: Constants.GET_POSTS_DETAILS,
            variables: { slug }
          }
        );
        const result = queryResult.data.data.posts;
        setRecords({ posts: result.nodes });
      } catch (error) {
        console.error("Error fetching post details:", error);
      }
    };

    fetchApi();
  }, [slug]);

  const fetchSidebar = async () => {
    try {
      const queryResult = await axios.post(
        Sidebar.GRAPHQL_API,
        {
          query: Sidebar.GET_POSTS_SIDEBAR
        }
      );
      const result = queryResult.data.data.posts;
      setSidebar({ posts: result.nodes });
    } catch (error) {
      console.error("Error fetching sidebar posts:", error);
    }
  };

  useEffect(() => {
    fetchSidebar();
  }, [slug]);

  const handleSelectChange = (e) => {
    const selectedSlug = e.target.value;
    if (selectedSlug) {
      navigate(`/article/${selectedSlug}`);
    }
  };


  // useEffect(() => {
  //   fetchApi();
  // }, [slug]); // Added [slug] dependency to avoid infinite loop

  // Example YouTube Embed Component
  // const YouTubeEmbed = ({ videoId }) => {
  //   const opts = {
  //     height: '390',
  //     width: '640',
  //     playerVars: {
  //       autoplay: 0,
  //     },
  //   };
  //   return <YouTube videoId={videoId} opts={opts} />;
  // };

  // Example function to extract YouTube video IDs from content
  // const extractYouTubeID = (content) => {
  //   const regex = /https?:\/\/www\.youtube\.com\/embed\/([a-zA-Z0-9_-]{11})/;
  //   const match = content.match(regex);
  //   return match ? match[1] : null;
  // };

  return (
    <div>
      <Navigation />
      <div id="article_header" className="container-fluid col-md-12 page-header-article"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>

              <div className="vertical-menu">
                {sidebar.posts.map((link, index) => (
                  <div key={index} className={location.pathname === "/article/" + link.slug ? "active-menu" : "inactive"}>
                    <Link to={`/article/${link.slug}`} className="text-sm">
                      {link.title}
                    </Link>
                  </div>
                ))}
                <div className="mt-3">
                  <Link to="/view-all-articles" className="text-sm text-link fw-normal">
                    View All Articles <i className="fas fa-arrow-right text-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {records.posts.map((item, index) => {
            // const videoId = extractYouTubeID(item.content);
            return (
              <div key={index} id="content" className="col-md-8 col-lg-9">
                <div className="d-block d-md-none">

                  <select
                    className="form-select mb-3"
                    onChange={handleSelectChange}
                    value={slug}
                  >
                    <option value="">Select an article</option>
                    {sidebar.posts.map((link, index) => (
                      <option key={index} value={link.slug}>
                        {link.title}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="row">
                  <div className="col-md-10">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                      <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                      <li className="breadcrumb-item"><Link to="#"><strong>{item.title}</strong></Link></li>
                    </ol>
                  </div>
                </div>


                <h4 className="mt-3 page-title">{item.title}</h4>
                <img src={item.featuredImage?.node.sourceUrl} className="mt-3 rounded img-fluid" alt='...' />
                <article className="mt-3" dangerouslySetInnerHTML={{ __html: item.content }}></article>
                {/* {videoId && <YouTubeEmbed videoId={videoId} />} */}
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
}
