import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import * as Constants from "../../queries/constants"
import axios from "axios";

export const NewsEvents = () => {
  // const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState({ posts: [] });

  const fetchApi = async () => {
    // setLoading(true);
    const queryResult = await axios.post(
      Constants.GRAPHQL_API, {
      query: Constants.GET_POSTS_QUERY
    }
    );
    const result = queryResult.data.data.posts;
    setRecords({ posts: result.nodes });
    //  setLoading(false);
  };

  useEffect(() => {
    fetchApi();

  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleString(undefined, options);
    return formattedDate;
  }

  return (
    <div id="news-events" className="col-md-11 mx-auto">
      <h3 className="text-center page-title mt-5">News & Events</h3>
      <Carousel responsive={responsive}>
        {records.posts.map((item, index) => (
          <div className="news-events" key={index}>
            <Link to={`/article/${item.slug}`}>
              <div className="card ms-2 me-2">
                <img src={item.featuredImage?.node.sourceUrl} className="img-fluid" alt='...' />
                <div className="card-body">
                  <small>{formatDate(item.date)}</small>
                  <p className="news-article-title">{item.title}</p>

                  <article className="small" dangerouslySetInnerHTML={{ __html: item.excerpt }}></article>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </Carousel>
    </div>
  )
}