import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/recruitment';
import "../../styles/innerpage.css";
import '../../styles/headers.css';

export const SupportPositions = () => {
  const location = useLocation();

  return (
    <div>
      <Navigation />

      <div id="teaching_and_learning" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="d-block d-md-none">
              <select
                className="form-select"
                onChange={(e) => window.location.href = e.target.value}
                value={location.pathname}
              >
                {sidebarLinks.map((link, index) => (
                  <option key={index} value={link.path}>
                    {link.label}
                  </option>
                ))}
              </select>
            </div>


            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>Recruitment</strong></Link>
                  </li>
                </ol>
              </div>
            </div>

            <div className="col-md-12">
              <h4 className="mt-5 mb-5 page-title text-center">There are currently no support positions open.</h4>

              <div className="contact-section col-lg-9 mx-auto">
                <h4 className="mt-3 page-title">Interested in Receiving Job Alerts</h4>
                <p>In order to receive alerts with vacancies that suit your expertise, <a href="https://mail.google.com/mail/?view=cm&amp;fs=1&amp;to=k.algosaibi@ikns.edu.bh&amp;su=I am interested in a TEACHING position" target="_blank" rel="noreferrer" className="text-link">
                  write a brief email introducing yourself and attach your CV.</a></p>
              </div>

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
