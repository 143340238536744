import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/american-highschool-diploma';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const AmericanHighSchoolDiploma = () => {

  const location = useLocation();

  return (
    <div>
      <Navigation />
      <div id="ahsd" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="d-block d-md-none">
              <select
                className="form-select"
                onChange={(e) => window.location.href = e.target.value}
                value={location.pathname}
              >
                {sidebarLinks.map((link, index) => (
                  <option key={index} value={link.path}>
                    {link.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>American High School Diploma (G11-G12)</strong></Link></li>
                </ol>
              </div>
            </div>


            <h4 className="mt-3 mb-3 page-title">American High School Diploma (G11-G12)</h4>
            <div className="img-right col-md-6">
              <img src={require('../../assets/img/ahsd/hsdiploma.png')} className="rounded img-fluid" alt='pyp' />
            </div>

            <p>
              IKNS students who select the American High School Diploma (AHSD) Programme
              study six major courses in Grade 11 and six major courses in Grade 12.
              These courses must include Arabic, English and Mathematics. Courses
              offered as part of the AHSD programme are one year courses and students
              select new courses for Grade 12. Students select their courses at the end
              of Grade 11.
            </p>
            <p>
              Students in the AHSD programme sit for internally prepared exams at the end
              of Semester 1 and Semester 2 in Grade 11 and Grade 12. These exams cover the
              material taught in that particular semester.
            </p>
            <p>
              IKNS students in the AHSD programme can also select one or more IBDP subjects
              to study. In this case, the student will sit for the IBDP external exam(s) that
              covers the material taught over the course of the two years.
            </p>



          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}


