import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
// import sidebarLinks from '../../components/sidebar/student-life';
import '../../styles/headers.css';
import '../../styles/innerpage.css';
import React, { useState, useEffect } from "react";
import menuSidebar from './menu.json';




export const SamarAlAnsari = () => {

  const location = useLocation();
  const [sidebar, setSidebar] = useState({ sideMenu: [] });
  const [expandedItems, setExpandedItems] = useState({});

  useEffect(() => {
    setSidebar(menuSidebar);
  }, []);

  const toggleExpand = (index) => {
    setExpandedItems(prev => {
      const newState = {};
      // Collapse all items
      Object.keys(prev).forEach(key => {
        newState[key] = false;
      });
      // Toggle the clicked item
      newState[index] = !prev[index];
      return newState;
    });
  };

  // Helper function to render menu item
  const renderMenuItem = (item) => {
    // For items with '#' slug
    if (item.slug === '#') {
      return (
        <Link to="#" className="text-sm">
          {item.title}
        </Link>
      );
    }

    // For external links (starting with http/https)
    if (item.slug.startsWith('http')) {
      return (
        <a
          href={item.slug}
          className="text-sm"
          target={item.target || '_self'}
          rel={item.target === '_blank' ? "noopener noreferrer" : undefined}
        >
          {item.title}
        </a>
      );
    }

    // For internal links
    return (
      <Link to={`/${item.slug}`} className="text-sm">
        {item.title}
      </Link>
    );
  };



  const economics = [
    { year: 2007, award: "Subah Bu Ali", grade: "12" },
    { year: 2008, award: "Tariq Noor", grade: "12" },
    { year: 2009, award: "Nouf Khalid Mattar", grade: "12" },
    { year: 2010, award: "Abddulla Mahmoud", grade: "12" },
    { year: 2011, award: "Muzoon Mattar", grade: "12" },
    { year: 2012, award: "Talal Al Aradi", grade: "12" },
    { year: 2013, award: "Mohammed Nabil", grade: "12" },
    { year: 2014, award: "Hala Jaffar Said", grade: "12" },
    { year: 2015, award: "Ahmed Saleh", grade: "12" },
    { year: 2016, award: "Noor Al Bosta", grade: "12" },
    { year: 2017, award: "Natalie Hanna", grade: "12" },
    { year: 2018, award: "Aya Bseisu / Salman Al Khalifa", grade: "12/12 " },
    { year: 2019, award: "Salman Dhaif / Lara Hassan", grade: "12/11" },
    { year: 2020, award: "Ali Sammak", grade: "11" },
    { year: 2021, award: "Joseph Luke", grade: "11" },
    { year: 2022, award: "Mohannad Hussain Al Sammak", grade: "11" },
    { year: 2023, award: "Ebrahim Ahmed Abdulla", grade: "11" },
    { year: 2024, award: "Kawther Ali", grade: "11" },
  ];


  const girlsSoccer = [
    { year: 2007, award: "Rania Mashouk", grade: "11" },
    { year: 2008, award: "Fatima Mahmood", grade: "12" },
    { year: 2009, award: "Zain M. Al Khalifa", grade: "12" },
    { year: 2010, award: "Leen Al Sharif", grade: "12" },
    { year: 2011, award: "Lana Basha", grade: "12" },
    { year: 2012, award: "Nadeen Al Araifi", grade: "12" },
    { year: 2013, award: "Danya Al Natour", grade: "10" },
    { year: 2014, award: "Dana Khalil", grade: "11" },
    { year: 2015, award: "Nouf Hashim", grade: "12" },
    { year: 2016, award: "Liana Al Natour", grade: "12" },
    { year: 2017, award: "Hajar Bushehri", grade: "12" },
    { year: 2018, award: "Ruqaya Janahi", grade: "12" },
    { year: 2019, award: "Leah Sarwani", grade: "11" },
    { year: 2020, award: "Farah Khalid", grade: "12" },
    { year: 2021, award: "Maya Ashraf Bseisu", grade: "12" },
    { year: 2022, award: "Mariam Jamal Al Khaja", grade: "12" },
    { year: 2023, award: "Zain Mazen Al Mahroos", grade: "12" },
    { year: 2024, award: "Fahad Almuqla", grade: "12" },
  ];



  return (
    <div>
      <Navigation />
      <div id="student_life" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {/* Desktop view */}
                <div className="d-none d-md-block">
                  {sidebar.sideMenu.map((link, index) => (
                    <div key={index}>
                      <div
                        className={location.pathname === `/${link.slug}` ? "active-menu" : "inactive"}
                        onClick={() => toggleExpand(index)}
                        style={{ cursor: 'pointer' }}
                      >
                        {renderMenuItem(link)}
                      </div>
                      {link.subItems && expandedItems[index] && (
                        <div className="sub-menu ms-3">
                          {link.subItems.map((subItem, subIndex) => (
                            <div
                              key={subIndex}
                              className={location.pathname === `/vtour/${subItem.slug}` ? "active-menu" : "inactive"}
                            >
                              {renderMenuItem(subItem)}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">IKNS Community</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Students' Life</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>Samar Al Ansari Awards for Excellence</strong></Link></li>
                </ol>

              </div>
            </div>

            <h4 className="mt-3 page-title text-center">Samar Al Ansari Awards for Excellence</h4>
            <p>
              Samar Al Ansari was a well-rounded IKNS student who excelled both academically
              and in extracurricular activities and she was a key member of the IKNS Honor
              Society and the girls soccer team. She was also known for her amiable and
              compassionate qualities.</p>

            <div className="img-right col-md-4">
              <img src={require('../../assets/img/student_life/samarAl.jpg')} className="img-fluid rounded" alt='...' />
            </div>


            <p>After Samar&#39;s tragic death, which followed her graduation in 2006 and
              in memory of her outstanding contribution to school life, Samar&#39;s family
              established two annual awards. These are given to students who excel in
              economics and girls soccer, the two areas Samar was deeply passionate about
              and in which she distinguished herself the most.
            </p>

            <p>The two awards are:</p>
            <ul>
              <li>The Samar Al Ansari Award for Analytical and Critical Thinking in Economics</li>
              <li>The Samar Al Ansari Award for Girls Soccer</li>
            </ul>

            <p className="content-title mt-5">Samar Al Ansari Award for Analytical and Critical Thinking in Economics</p>
            <p>At the annual Honor Society ceremony, the Samar Al Ansari Award for Analytical and
              Critical Thinking in Economics is presented to the high school student who best fulfills
              the following two criteria:</p>

            <ol>
              <li>Excels at applying and analyzing economic concepts and theories</li>
              <li>Demonstrates an exceptional ability to evaluate economic concepts
                and theories in the context of real world examples</li>
            </ol>

            <p>The recipient of the award is nominated by the economics teacher(s) and
              approved by the High School Principal provided that the student has not
              received the award previously.</p>

            <p><a href="#table1" className="text-link">Click here to view</a> the names of the students who have received the Samar
              Al Ansari Award for Analytical and Critical Thinking in Economics.</p>

            <p className="content-title mt-5">
              Samar Al Ansari Award for Girls Soccer
            </p>

            <p>Every year, the school celebrates an outstanding female athlete
              by awarding her the Samar Al Ansari Girls Soccer Award. The award is
              presented during the annual athletic awards ceremony.</p>

            <p>The high school student eligible for the award should exhibit the
              following qualities, which must be confirmed by her physical
              education teachers and coaches:</p>

            <ol>
              <li>Commitment to IKNS sports.</li>
              <li>Outstanding contribution to girls soccer at IKNS</li>
              <li>Excellent sportsmanship, including a keen sense of fairness, self-control and respect for her team, coaches and opponents</li>
            </ol>

            <p>The recipient of the award is selected by the coaches of the girls soccer teams and approved by the High School Principal provided that the student has not received the award previously.</p>

            <p><a href="#table2" className="text-link">Click here to view</a> the names of the students who have received the Samar Al Ansari Award for Girls Soccer.</p>

            <div className="col-md-10">
              <table className="table table-bordered" id="table1">

                <thead>
                  <tr>
                    <th style={{ backgroundColor: '#2b2729', color: '#fafafa', border: '1px solid #000' }} className="col-md-1 p-3">Year</th>
                    <th style={{ backgroundColor: '#2b2729', color: '#fafafa', border: '1px solid #000' }} className="p-3">Samar Al Ansari Award for Analytical and Critical Thinking in Economics</th>
                    <th style={{ backgroundColor: '#2b2729', color: '#fafafa', border: '1px solid #000' }} className="col-md-1 p-3">Grade</th>
                  </tr>
                </thead>
                <tbody>
                  {economics.map((item, index) => (
                    <tr key={index}>
                      <td>{item.year}</td>
                      <td>{item.award}</td>
                      <td>{item.grade}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>



            <div className="col-md-10">
              <table className="table table-bordered" id="table2">
                <thead>
                  <tr>
                    <th style={{ backgroundColor: '#2b2729', color: '#fafafa', border: '1px solid #000' }} className="col-md-1 p-3">Year</th>
                    <th style={{ backgroundColor: '#2b2729', color: '#fafafa', border: '1px solid #000' }} className="p-3">Samar Al Ansari Award for Girls Soccer</th>
                    <th style={{ backgroundColor: '#2b2729', color: '#fafafa', border: '1px solid #000' }} className="col-md-1 p-3">Grade</th>
                  </tr>
                </thead>
                <tbody>
                  {girlsSoccer.map((item, index) => (
                    <tr key={index}>
                      <td>{item.year}</td>
                      <td>{item.award}</td>
                      <td>{item.grade}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <p className="mb-4">You may visit Samar&#39;s Blog at <a href="http://samaralansari.blogspot.com" className="text-link fw-normal" target="_blank" rel="noreferrer">http://samaralansari.blogspot.com</a></p>








          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}


