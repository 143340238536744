import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/media-center';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const BorrowingPolicies = () => {
  const location = useLocation();

  return (
    <div>
      <Navigation />
      <div id="media_center" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="d-block d-md-none">
              <select
                className="form-select"
                onChange={(e) => window.location.href = e.target.value}
                value={location.pathname}
              >
                {sidebarLinks.map((link, index) => (
                  <option key={index} value={link.path}>
                    {link.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="/">Media Center</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>Borrowing Policies</strong></Link></li>
                </ol>
              </div>
            </div>



            <h4 className="mt-3 page-title">Borrowing Policies</h4>
            <div className="col-md-12 mx-auto">
              <p className="content-title mt-5">RULES AND REGULATIONS</p>
              <ol>
                <li className="mb-2">All bags should be left at the main entrance of the media centers. Only notebooks and laptops are allowed inside.</li>
                <li className="mb-2">Avoid bringing food and drinks at anytime.</li>
                <li className="mb-2">Help maintain the Media Centers as comfortable places for reflecting and studying. Show respect by lowering your voices when talking to others.</li>
                <li className="mb-2">Switch off your mobile phones and other similar devices.</li>
                <li className="mb-2">All borrowed resources should be handled with care. Avoid underlining, highlighting, writing important notes and tearing pages.</li>
                <li className="mb-2">Be responsible in making the Media Centers in order and clean. Push in the chairs back to the table and return all materials to their proper places.</li>
              </ol>


              <p className="content-title mt-5">MEDIA CENTERS SERVICES</p>

              <ol>
                <li><strong>Borrowing Services</strong>
                  <p>All students, faculty and staff members are required to bring their borrowers cards to check out books and other materials.</p>

                  <p className="mt-3"><strong>Borrowing Privileges</strong></p>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">PATRON TYPE</th>
                        <th scope="col">NO. OF ITEMS</th>
                        <th scope="col">LOAN PERIOD</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>KG</td>
                        <td>2 books</td>
                        <td>2 weeks</td>
                      </tr>

                      <tr>
                        <td>Grade 1</td>
                        <td>2 books</td>
                        <td>2 weeks</td>
                      </tr>

                      <tr>
                        <td>Grade 2</td>
                        <td>2 books</td>
                        <td>2 weeks</td>
                      </tr>

                      <tr>
                        <td>Grade 3</td>
                        <td>2 books</td>
                        <td>2 weeks</td>
                      </tr>

                      <tr>
                        <td>Grade 4</td>
                        <td>2 books</td>
                        <td>2 weeks</td>
                      </tr>

                      <tr>
                        <td>Grade 5</td>
                        <td>2 books</td>
                        <td>2 weeks</td>
                      </tr>

                      <tr>
                        <td>Grades 6, 7 & 8</td>
                        <td>3 books</td>
                        <td>2 weeks</td>
                      </tr>

                      <tr>
                        <td>Grades 9 & 10</td>
                        <td>4 books</td>
                        <td>2 weeks</td>
                      </tr>

                      <tr>
                        <td>Grades 11 & 12</td>
                        <td>Unlimited</td>
                        <td>2 weeks</td>
                      </tr>

                      <tr>
                        <td>Faculty and Staff Members</td>
                        <td>Unlimited (books, AV materials, devices, etc.)</td>
                        <td>6 weeks</td>
                      </tr>
                    </tbody>
                  </table>

                  <p>Patrons with overdue books are not allowed to check out any items unless borrowed materials are returned.</p>
                  <p>All reference books (Encyclopedias, Dictionaries, Atlases) are media centers use only.</p>
                  <p>Patrons may reserve a book upon request from the media centers staff.</p>
                  <p>All patrons will be responsible to pay for any damaged or lost items.</p>

                </li>
                <li><strong> Technology Services</strong>
                  <p>The media centers provide a number of computers and various advanced technology devices for researching, accessing online resources, teaching and other school related activities.</p>
                </li>
                <li><strong> Printing/ Photocopying/ Scanning and Laminating Services</strong>
                  <p>Al Gosaibi Media Center offers these services for a minimum fee.</p>
                </li>
              </ol>


              <p className="content-title mt-5">MEDIA CENTER ACTIVITIES</p>
              <p>To encourage the love of reading and books among the students, the IKNS media centers in cooperation with other departments host book fairs and Reading Week activities annually.</p>
              <p>The media centers provide special resources that support the IKNS students who are participating in the international reading competitions.</p>
              <p>The Elementary Media Center support the school leveled reading program to all students, organizes the battle of the books competition and uses makerspace for students to invest in their ideas and come up with their solutions and projects.</p>



            </div>
          </div>
        </div>
      </div>



      <Footer />
    </div >
  )
}

