export const SidebarLinks = [
  {
    label: "IKNS Brochure",
    path: "https://www.ikns.edu.bh/brochure",
    target: "_blank"
  },
  {
    label: "Admission",
    path: "/admission"
  },

  {
    label: "Availability of Seats & Deadlines",
    path: "/availability-of-seats-and-deadlines"
  },
  {
    label: "Admission Procedure & Application Form",
    path: "/admission-procedure-and-application-form"
  },

  {
    label: "Application Form Required Documents",
    path: "/application-form-required-documents"
  },

  {
    label: "Entrance Exams Requirements",
    path: "/entrance-exams-requirements"
  },

  {
    label: "School Fees",
    path: "/school-fees"
  },
  {
    label: "Student Withdrawal Form",
    path: "https://docs.google.com/forms/d/e/1FAIpQLScOjGAqTfA5AodRVQTmMGkJKRL2TSfAUmR-Bw6DneV_6fOZAA/viewform",
    target: "_blank"
  },

  // {
  //   label: "Apply-New Student",
  //   path: "/apply-new-student"
  // },

  // {
  //   label: "KG1 Admission Interview - Parent Guide",
  //   path: "/kg1-admission-interview-parent-guide"
  // },

  // {
  //   label: "School Fees",
  //   path: "/school-fees"
  // },

];

export default SidebarLinks;
