import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/external-exam-results';
import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const IbMyp = () => {

  const location = useLocation();

  return (
    <div>
      <Navigation />
      <div id="external_exam_results" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="d-block d-md-none">
              <select
                className="form-select"
                onChange={(e) => window.location.href = e.target.value}
                value={location.pathname}
              >
                {sidebarLinks.map((link, index) => (
                  <option key={index} value={link.path}>
                    {link.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                  <li className="breadcrumb-item"><Link to="#">External Exam Results</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>IB MYP</strong></Link></li>
                </ol>

              </div>
            </div>


            <h4 className="mt-3 page-title">MYP eAssessment </h4>
            <div className="row">
              <div className="col-md-8">
                <p>
                  In 2024, nine Grade 10 students participated in the eAssessment exams, an optional qualification offered to all IKNS students at the conclusion of the MYP years. The average score for IKNS students was 41 points, surpassing the global average of 37.48 out of 56. Three students earned the Bilingual Certificate: Sarah Jaidom with 44 points, Hasan Jamsheer with 42 points, and Nasser Raees with 37 points.
                </p>
              </div>
              <div className="col-md-4">
                <img src={require('../../assets/img/external-exams-results/e-assesment.png')} className="img-fluid rounded" alt='...' />
              </div>
            </div>



            <h4 className="mt-3 page-title">MYP Personal Project</h4>
            <div className="row">
              <div className="col-md-8">
                <p>
                  All Grade 10 students participate in the annual MYP Personal Project as a culminating assignment at the end of the MYP programme. In 2024, 123 students presented projects based on their personal interests and hobbies. The IKNS average for the Personal Project in 2024 was 4.06 points, compared to the global average of 4.02 points out of 7.
                </p>
              </div>
              <div className="col-md-4">
                <img src={require('../../assets/img/external-exams-results/personal-project.png')} className="img-fluid rounded" alt='...' />
              </div>
            </div>




          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}


