import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";
import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const SchoolFees = () => {

  return (
    <div>
      <Navigation />

      <div id="school_fees" className="container-fluid col-md-12 page-header"></div>

      <div id="one-page" className="wrapper">
        <div className="row">
          <div className="col-md-10">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item"><Link to="#">Key Info</Link></li>
              <li className="breadcrumb-item"><Link to="#"><strong>School Fees</strong></Link></li>
            </ol>
            <h4 className="mt-3 page-title">School Fees for the School Year 2025-2026</h4>
          </div>
        </div>


        <div className="col-md-12 mx-auto">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th colSpan={3} className="text-xs bg-gray" scope="col">
                  The School Fees for the 2025-2026 school year include a 3% increase on those of the
                  2024-2025 school year
                </th>

                <th colSpan={4} className="text-xs bg-gray" dir="rtl" lang="ar" style={{ textAlign: 'right' }}>
                  تشمل الرسوم المدرسية للعام الدراسي ٢٠٢٦-٢٠٢٥ زيادة بنسبة ٪٣ عن تلك للعام الدراسي ٢٠٢٥-٢٠٢٤
                </th>


              </tr>

              <tr>
                <th className="text-center bg-gray" scope="col">Installment Due Date</th>
                <th className="text-center bg-gray" scope="col">KGI</th>
                <th className="text-center bg-gray" scope="col">KGII</th>
                <th className="text-center bg-gray" scope="col">1-5</th>
                <th className="text-center bg-gray" scope="col">6-8</th>
                <th className="text-center bg-gray" scope="col">9-10</th>
                <th className="text-center bg-gray" scope="col">11-12</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><strong>First Installment (Re-enrollment)</strong> <small className="d-block">(Invoice due in March)</small></td>
                <td className="text-center">1000</td>
                <td className="text-center">1000</td>
                <td className="text-center">1000</td>
                <td className="text-center">1000</td>
                <td className="text-center">1000</td>
                <td className="text-center">1000</td>
              </tr>

              <tr>
                <td><strong>Second Installment</strong><small className="d-block">(Invoice due in August)</small></td>
                <td className="text-center">1060</td>
                <td className="text-center">1085</td>
                <td className="text-center">1460</td>
                <td className="text-center">1550</td>
                <td className="text-center">1810</td>
                <td className="text-center">2065</td>
              </tr>

              <tr>
                <td><strong>Third Installment</strong> <small className="d-block">(Invoice due in January)</small></td>
                <td className="text-center">980</td>
                <td className="text-center">980</td>
                <td className="text-center">1300</td>
                <td className="text-center">1550</td>
                <td className="text-center">1810</td>
                <td className="text-center">2065</td>
              </tr>

              <tr>
                <td className="bg-gray"><span className="text-link">Total Annual School Fees</span></td>
                <td className="bg-gray text-center"><span className="text-link">3040</span></td>
                <td className="bg-gray text-center"><span className="text-link">3065</span></td>
                <td className="bg-gray text-center"><span className="text-link">3760</span></td>
                <td className="bg-gray text-center"><span className="text-link">4105</span></td>
                <td className="bg-gray text-center"><span className="text-link">4620</span></td>
                <td className="bg-gray text-center"><span className="text-link">5130</span></td>
              </tr>
            </tbody>
          </table>
        </div>



        <div className="col-md-12 mx-auto">
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td className="col-md-6 text-xs">
                  The school fees for students in KG1 to Grade 5 include the cost of notebooks, textbooks
                  and educational software applications used to support the learning of the students, while
                  the students in Grades 6 to 12 are to buy their own textbooks and notebooks.
                </td>
                <td className="col-md-6 text-xs" dir="rtl" lang="ar">
                  تشمل الرسوم الدراسية الخاصة بصفوف الروضة األولى إلى الصف الخامس ثمن الدفاتر والكتب الدراسية
                  والتطبيقات التربوية المبرمجة التي تدعم تعلم الطلبة، في حين يقوم طالب صفوف السادس إلى الثاني عشر
                  بشراء الكتب المدرسية والدفاتر الخاصة بهم.
                </td>
              </tr>
              <tr>
                <td className="bg-gray text-center"><span className="text-link">Fees Additional</span></td>
                <td className="bg-gray text-center"><span className="text-link">رسوم إضافية</span></td>
              </tr>
              <tr>
                <td>
                  <ul className="text-xs normal-list">
                    <li>Yearly Locker Rental (BD20) to be paid by students in Grades 6 to 12 and is to be
                      invoiced in August.</li>

                    <li>The cost of the material used in the Art, Drama and Pottery courses to be paid by the
                      students in grades 6 to 12 who are registered in any of these courses, and is to be
                      invoiced separately in the second semester.</li>

                    <li>The IBMYP and IBDP Examinations Fees are to be paid by the Grade 10 and Grade 12
                      students (respectively) who register to sit for these IB external examinations. These
                      fees are invoiced separately in October.</li>

                    <li>The Graduation Ceremony fee to be paid by the Grade 12 students who wish to
                      participate in the graduation ceremony.</li>
                  </ul>
                </td>
                <td>
                  <ul className="text-xs normal-list" dir="rtl" lang="ar">
                    <li>
                      أجرة استخدام خزائن الطلبة )٢٠ دينار( ويدفعها سنويا طالب صفوف السادس إلى الثاني عشر وتضاف
                      قيمتها إلى الفاتورة التي تصدر في شهر أغسطس.
                    </li>


                    <li>
                      أثمان المواد التي يستخدمها طالب صفوف السادس إلى الثاني عشر الذين يدرسون مساقات الفن
                      والدراما والخزف، ويتم ارسال فاتورة منفصلة بهذه المبالغ خالل الفصل الثاني من العام الدراسي.
                    </li>

                    <li>
                      رسوم المشاركة في امتحانات البكالوريا الدولية الخارجية يدفعها طالب صفي العاشر والثاني عشر الذين
                      يرغبون بالتقدم لهذه االمتحانات ضمن برنامج دبلوم البكالوريا الدولية وبرنامج البكالوريا الدولية
                      للسنوات المتوسطة، ويتم ارسال فاتورة منفصلة بهذه المبالغ في شهر أكتوبر.
                    </li>

                    <li>
                      رسم حفل التخرج ويدفعه طالب الصف الثاني عشر الذي يرغبون بالمشاركة في حفل التخرج.
                    </li>

                  </ul>
                </td>
              </tr>

              <tr>
                <td className="bg-gray text-center"><span className="text-link">New Students</span></td>
                <td className="bg-gray text-center"><span className="text-link">
                  الطالب الجدد</span></td>
              </tr>

              <tr>
                <td>
                  <ul className="text-xs normal-list">
                    <li>BD30 new Student Application Fee.</li>
                    <li>BD100 Admission Fee for new students only.</li>
                    <li>The School borrows BD500 from parents that will enroll their children at IKNS, in the
                      form of a loan. The loan applies to a maximum of two children per family. Once the
                      student graduates, or leaves the school, the amount will be paid back to the parent.</li>
                  </ul>
                </td>

                <td>
                  <ul className="text-xs normal-list" dir="rtl" lang="ar">
                    <li>٣٠ دينار طلب انتساب</li>
                    <li>١٠٠ دينار رسم قبول الطالب في المدرسة.</li>
                    <li>تقترض المدرسة مبلغ ٥٠٠ دينار من أولياء األمور الذين يلتحق أبنائهم بالمدرسة على شكل سلفة.
                      تطبق السلفة على طفلين كحد أقصى لكل ولي أمر، وتعاد السلفة إلى ولي األمر عند تخرج ابنه/ابنته من
                      المدرسة أو انتقاله إلى مدرسة أخرى.</li>
                  </ul>
                </td>
              </tr>

            </tbody>
          </table>

          <small><a href="https://drive.google.com/file/d/11gOKgFbiCbXTx2uGzkGPtYSApQD41ugN/view" className="text-primary" target="_blank" rel="noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-printer" viewBox="0 0 16 16"> <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" /> <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" /> </svg> Print</a></small>

        </div>



        <div className="row mt-5">
          <div className="col-md-6">
            <p><strong>School Fees Refund Policy:</strong></p>

            <p className="text-xs">In order to process the fee refund, parents who wish to withdraw their child
              must complete the <a href="https://docs.google.com/forms/d/e/1FAIpQLScOjGAqTfA5AodRVQTmMGkJKRL2TSfAUmR-Bw6DneV_6fOZAA/viewform" className="text-link" target="_blank" rel="noreferrer">Student Withdrawal Form</a> within the below mentioned time
              frame. Kindly note that a verbal notification cannot be considered as an official withdrawal request.</p>

            <p className="text-xs">After reviewing the Student Withdrawal Form and ensuring that all required
              tuition fees have been paid, the Accounts Department will issue a Financial
              Release Certificate. This certificate is a financial disclaimer and is separate from a
              student&#39;s transcript and academic record.</p>

            <p className="text-xs">In the event of a new student withdrawing, the below points apply only when
              the parent provides the school with an official certificate showing that the
              guardian will relocate out of Bahrain.</p>

            <p className="text-xs">In case there is no photocopy of the parent&#39;s Identification Card in the student&#39;s
              file, the Admission Office will contact you to provide a copy.</p>

            <ul>
              <li><small>The first installment is nonrefundable in case the school was not informed in
                writing before 20 June.</small></li>
              <li><small>Withdrawals within the first 20 school days, 70% of the Second Installment
                will be refunded.</small></li>
              <li><small>Withdrawals within 21 to 40 school days, 40% of the Second Installment will
                be refunded</small></li>
              <li><small>Withdrawals after 40 days from the start of the school year, no refund is
                payable.</small></li>
              <li><small>The cost of textbooks is nonrefundable.</small></li>
            </ul>
          </div>

          <div className="col-md-6">
            <p dir="rtl" lang="ar"><strong>سياسة استرجاع الرسوم الدراسية</strong></p>


            <p dir="rtl" lang="ar" className="text-sm">
              في حال رغب ولي الأمر بسحب ابنه/ابنته من المدرسة، ولكي يتمكن قسم المحاسبة من إعادة الرسوم وفقا للبنود أدناه، على ولي الأمر ملء <a href="https://docs.google.com/forms/d/e/1FAIpQLScOjGAqTfA5AodRVQTmMGkJKRL2TSfAUmR-Bw6DneV_6fOZAA/viewform" className="text-link" target="_blank" rel="noopener noreferrer">"نموذج انسحاب طالب"</a> ضمن الفترة الزمنية المحددة، ولا يمكن اعتبار إبلاغ المدرسة بذلك شفهيًا بالانسحاب إشعارًا رسميًا بذلك.
            </p>


            <p dir="rtl" lang="ar">
              بعد مراجعة نموذج الانسحاب من قبل إدارة المدرسة والتأكد من سداد كافة الرسوم الدراسیة المطلوبة حتى تاریخھ، یقوم قسم المحاسبة بإصدار شھادة إخلاء مسؤولیة مالیة من طرف المدرسة وذلك لإستخدام ولي الأمر، علما أن شھادة إخلاء المسؤولیة المالیة لا علاقة لھا بأداء الطالب الأكادیمي.
            </p>

            <p dir="rtl" lang="ar">
              في حال انسحاب طالب جدید، تنطبق النقاط أدناه ذاتھا فقط عند تزوید المدرسة بوثیقة معتمدة تبین انتقال ولي الأمر للعمل خارج البحرین.
            </p>

            <p dir="rtl" lang="ar">
              في حال عدم توفر صورة عن بطاقة ولي الأمر الشخصیة في ملف الطالب، سیقوم مكتب القبول والتسجیل بالتواصل معكم لتزویدنا بصورة من البطاقة.
            </p>



            <ul dir="rtl" lang="ar">
              <li><small>
                القسط األول غير مسترجع في حال عدم إعالم المدرسة بالرغبة باالنسحاب قبل تاريخ ٢٠
                يونيو
              </small></li>

              <li><small>
                يعاد ٪٧٠ من القسط الثاني في حالة انسحاب الطالب خالل ٢٠ يوم من بداية العام
                الدراسي
              </small></li>


              <li><small>
                يعاد ٪٤٠ من القسط الثاني في حالة انسحاب الطالب خالل ٢١ إلى ٤٠ يوم من بدء العام
                الدراسي
              </small></li>

              <li><small>
                ال تعاد الرسوم في حال انسحاب الطالب بعد مرور ٤٠ يوم من بداية العام الدراسي.
              </small></li>

              <li><small>
                أسعار الكتب المدرسية غير مسترجعة
              </small></li>
            </ul>

          </div>
        </div>




        <hr />

        <h4 className="page-title">Previous School Fees</h4>
        <a href="https://drive.google.com/file/d/1CJXNpGkzlM-DmcMhExv4LMk7-9GGOohC/view" className="d-block mb-1 text-danger" target="_blank" rel="noreferrer">
          School Fees for the School year 2024 - 2025
        </a>

        <a href="https://drive.google.com/file/d/13wyLuPNgI4n5p27pHvTXIeCn8AOe4EJz/view" className="d-block mb-1 text-danger" target="_blank" rel="noreferrer">
          School Fees for the School year 2023 - 2024
        </a>


        <a href="https://drive.google.com/file/d/1nPfZOpFj0xDECT_LlbKkHH2YTfg3-akq/view" className="d-block mb-1 text-danger" target="_blank" rel="noreferrer">
          School Fees for the School year 2022 - 2023
        </a>

        <a href="https://drive.google.com/file/d/1jIoVTFI1iKCnvTdAl7OwKVQUeEzjU9GB/view" className="d-block mb-1 text-danger" target="_blank" rel="noreferrer">
          School Fees for the School year 2021 - 2022
        </a>

        <a href="https://drive.google.com/file/d/1WFvSn1IWmUM6UI9nFkmtjv_e2HNrx7Kv/view" className="d-block mb-1 text-danger" target="_blank" rel="noreferrer">
          School Fees for the School year 2020 - 2021</a>


        <div className="contact-section col-lg-9 mx-auto mt-5">
          <h4 className="mt-3 page-title">Contact Us</h4>
          <p>If you have questions about the  School Fees, we are here to help.</p>
          <Link to="/contact-us" className="text-link">Go to contact form <i className="fas fa-arrow-right text-right"></i></Link>
        </div>

      </div>
      <Footer />
    </div >
  )
}


