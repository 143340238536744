import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/external-exam-results';
import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const IbdpResults = () => {

  const location = useLocation();

  return (
    <div>
      <Navigation />
      <div id="external_exam_results" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="d-block d-md-none">
              <select
                className="form-select"
                onChange={(e) => window.location.href = e.target.value}
                value={location.pathname}
              >
                {sidebarLinks.map((link, index) => (
                  <option key={index} value={link.path}>
                    {link.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                  <li className="breadcrumb-item"><Link to="#">External Exam Results</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>IBDP</strong></Link></li>
                </ol>

              </div>
            </div>


            <h4 className="mt-3 page-title">Ibn Khuldoon National School May 2024 IBDP Results</h4>

            <p>
              The IBDP results of the May 2024 session were outstanding! We are very proud of the distinguished Mean Points Score of 34 points - one of
              the highest in the history of the IBDP programme at IKNS. The Worldwide Mean Points Score was 30 points in comparison.
            </p>

            <p>
              We are also pleased with the number of students who were able to achieve outstanding diploma point scores. Special congratulations go to
              Peter Atef Sefen for achieving 43 points, Abdulla Mohamed Abdulrahim for achieving 42 points, and Abdulrahman Abdulrahim Alhashemi and
              Youssef Walid Diab for achieving 41 diploma points.
            </p>


            <p>
              The number of the IKNS students who sat for the full IB Diploma was forty-three, and all of them successfully met all the IBDP requirements
              achieving a 100% pass rate. In addition, another thirty-three students registered as Course Candidates for the May 2024 examinations
              session, sitting for one or more IBDP courses, and also achieved very good results in these subjects. It is very pleasing to see this many IKNS
              students challenging themselves and achieving IBDP Diplomas and Certificates.
            </p>

            <p>
              The average grade achieved by IKNS students per subject was 5.37 (out of a maximum grade of 7), compared to the worldwide average grade
              of 4.90.
            </p>

            <p>
              Congratulations to all the students who worked hard towards achieving these outstanding results. This was a highly motivated and dedicated
              group, and these results are well deserved. Well done to the Class of 2024!
            </p>



            <p className="mt-5">
              <a href="https://drive.google.com/file/d/1cSsMSC3EspX49JZqJAANSyECOZ3D-gq1/view" target="_blank" rel="noreferrer" className="text-link">
                View Ibn Khuldoon National School May 2024 IBDP Results
              </a>
            </p>

            <p>
              <a href="https://drive.google.com/file/d/1RifjuVQuPJeZUjsYGYPyiH3BjbYZjgkh/view" target="_blank" rel="noreferrer" className="text-link">
                View Ibn Khuldoon National School May 2022 IBDP Results
              </a>
            </p>


          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}


