import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './styles/navbar.css?v=1';
import './styles/buttons.css?v=1';
import './styles/global.css?v=2';
import './styles/typography.css?v=1';


const container = document.getElementById('app');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <BrowserRouter future={{
      v7_relativeSplatPath: true,
      v7_startTransition: true,
    }}>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);


reportWebVitals();
