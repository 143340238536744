import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/ibdp-programme';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const IbdpSubjectOffered = () => {

  const location = useLocation();

  return (
    <div>
      <Navigation />
      <div id="ibdp" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="d-block d-md-none">
              <select
                className="form-select"
                onChange={(e) => window.location.href = e.target.value}
                value={location.pathname}
              >
                {sidebarLinks.map((link, index) => (
                  <option key={index} value={link.path}>
                    {link.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                  <li className="breadcrumb-item"><Link to="#">The IB Diploma Programme (G11-G12)</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>IBDP Subjects Offered at IKNS</strong></Link></li>
                </ol>
                <h4 className="mt-3 page-title">IBDP Subjects Offered at IKNS</h4>
              </div>
            </div>

            {/* <p>
              .
              </p> */}
            <div className="row">
              <div className="col-md-12">
                <div className="card border-0 mb-3 bg-primary">
                  <div className="card-body">
                    <p className="mt-3"><small className="d-block text-muted">SUBJECT GROUP 1</small>
                      <strong>Studies in Language and Literature (Language A)</strong>
                    </p>

                    <div className="row">
                      <div className="col-md-6">
                        <ul className="small">
                          <li>Arabic Language and Literature SL</li>
                          <li>Arabic Language and Literature HL</li>
                        </ul>
                      </div>

                      <div className="col-md-6">
                        <ul className="small">
                          <li>English Language and Literature SL</li>
                          <li>English Language and Literature HL</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <div className="card border-0 mb-3 bg-primary">
                  <div className="card-body">
                    <p className="mt-3"><small className="d-block text-muted">SUBJECT GROUP 2</small>
                      <strong>Language Acquisition (Language B)</strong>
                    </p>

                    <div className="row">
                      <div className="col-md-6">
                        <ul className="small">
                          <li>Arabic B SL</li>
                          <li>Arabic B HL</li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <ul className="small">
                          <li>English B SL</li>
                          <li>English B HL</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <div className="card border-0 mb-3 bg-primary">
                  <div className="card-body">
                    <p className="mt-3"><small className="d-block text-muted">SUBJECT GROUP 3</small>
                      <strong>Individuals & Society</strong>
                    </p>
                    <div className="row">
                      <div className="col-md-6">
                        <ul className="small">
                          <li>Economics SL</li>
                          <li>Economics HL</li>
                          <li>Business & Management HL</li>
                          <li>Psychology SL</li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <ul className="small">
                          <li>Psychology HL</li>
                          <li>History SL</li>
                          <li>Information Technology in a Global Society SL</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-md-12 mt-3">
                <div className="card border-0 mb-3 bg-primary">
                  <div className="card-body">
                    <p className="mt-3"><small className="d-block text-muted">SUBJECT GROUP 4</small>
                      <strong>Sciences</strong>
                    </p>
                    <div className="row">
                      <div className="col-md-6">
                        <ul className="small">
                          <li>Biology SL</li>
                          <li>Biology HL</li>
                          <li>Chemistry SL</li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <ul className="small">
                          <li>Chemistry HL</li>
                          <li>Physics SL</li>
                          <li>Physics HL</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <div className="card border-0 mb-3 bg-primary">
                  <div className="card-body">
                    <p className="mt-3"><small className="d-block text-muted">SUBJECT GROUP 5</small>
                      <strong>Mathematics</strong>
                    </p>
                    <div className="row">
                      <div className="col-md-6">
                        <ul className="small">
                          <li>Mathematics Analysis & Approaches HL</li>
                          <li>Mathematics Analysis & Approaches SL</li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <ul className="small">
                          <li>Mathematics Applications & Interpretation SL</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <div className="card border-0 mb-3 bg-primary">
                  <div className="card-body">
                    <p className="mt-3"><small className="d-block text-muted">SUBJECT GROUP 6</small>
                      <strong>The Arts</strong>
                    </p>
                    <div className="row">
                      <div className="col-md-6">
                        <ul className="small">
                          <li>Visual Arts SL</li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <ul className="small">
                          <li>Visual Arts HL</li>
                        </ul>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}


