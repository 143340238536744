import { Link } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import '../../styles/headers.css';
import '../../styles/innerpage.css';
// import IframeResizer from 'iframe-resizer-react'
import IframeResizer from "@iframe-resizer/react";

import React, { useRef, useState } from 'react'

export const Uniform = () => {

  const iframeRef = useRef(null)
  const [setMessageData] = useState()
  const onResized = data => setMessageData(data)
  const onMessage = data => {
    setMessageData(data)
    iframeRef.current.sendMessage('...')
  }


  return (
    <div>
      <Navigation />

      <div id="uniform" className="container-fluid col-md-12 page-header"></div>

      <div id="one-page" className="wrapper">
        <div className="row">
          <div id="xcontent" className="">
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Key Info</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>Uniform</strong></Link></li>
                </ol>

              </div>
            </div>



            <h4 className="mt-3 page-title">K-12 School Uniform starting September 2023</h4>
            <p>As of September 2023, IKNS introduced newly designed school uniforms,
              PE uniforms, and winter-wear. The uniform was designed in collaboration
              with <a href="https://www.zaksstore.com" className="text-primary" target="_blank" rel="noreferrer">"Zaks"</a>, a specialised
              school uniform company that has branches in several countries, including Bahrain.</p>

            <p>IKNS has always been distinguished by its maroon and grey colours. In the
              process of selecting the new uniforms, the aim was to create a modern, comfortable
              and smart look, while maintaining the IKNS colour identity and consistency across
              the entire school.</p>

            <p>The regular school uniform unifies the colour and design of the
              trousers from Grade 1 to Grade 12. This change has been made to
              make use of school uniform trousers that are in good condition
              when transitioning from Elementary School to Middle School, or
              from Middle School to Secondary School. Yet the tops (polo shirts)
              have different designs to distinguish between the students in the
              different sections of the school.</p>

            <p>The PE uniform also unifies the colour and design of the trousers across the entire school, K-12.</p>
            <p>The PE tops on the other hand, are a different colour for each school section.</p>
            <p>We also introduced newly designed "hoodies" for students from K to 12. The hoodie is to be used as part of school uniform, including the PE uniform. Hoodies in the Secondary School (9-12) are distinguished with their black colour, while the rest of the school (K-8), will have maroon hoodies.</p>
            <p>Please note that sweatshirts, tops or jackets that are not part of the IKNS uniform are not permitted on campus.</p>

            <IframeResizer
              forwardRef={iframeRef}
              heightCalculationMethod="lowestElement"
              inPageLinks
              log
              onMessage={onMessage}
              onResized={onResized}
              style={{ height: '631px', width: '1px', minWidth: '100%' }}
              src="https://docs.google.com/presentation/d/e/2PACX-1vQzHTJq8Qz9MSTwltk_I_9cPFimNRnuJzy-pwL9zuBv3DsYokji3hREmuJvfofGIoYefj1lNobXw4vU/embed?start=true&loop=true&delayms=3000" />


          </div>
        </div>
      </div>





      <Footer />
    </div>
  )
}


