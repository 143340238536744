import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../../components/Navigation.jsx";
import { Footer } from "../../../components/Footer.js";
import sidebarLinks from '../../../components/sidebar/photo-gallery.js';
import "../../../styles/innerpage.css";
import '../../../styles/headers.css';
import React from "react";
import Drive from '../api/drive.js';

export const CarnivalPhotos = () => {
  const location = useLocation();


  return (
    <div>
      <Navigation />
      <div id="facilities" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/">Photo Gallery</Link>
                  </li>

                  <li className="breadcrumb-item">
                    <Link to="#">
                      <strong>IKNS Carnival</strong>
                    </Link>
                  </li>
                </ol>

              </div>
            </div>

            <div className="col-md-12">
              <Drive googleFolder="1Vb9SVRDn4C5Z2XOhJU-cUmh3cykf9o3J" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
