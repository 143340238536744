import React, { useState, useEffect } from "react";
import { useParams, Link, useLocation, useNavigate } from 'react-router-dom';
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import '../../styles/headers.css';
import '../../styles/innerpage.css';
import tourSidebarData from './tourMenu.json';
import dummyPosts from './tourPost.json'


export const Vtour = () => {
  const { slug } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState({ posts: [] });
  const [expandedItems, setExpandedItems] = useState({});

  // Find the current post based on slug
  const currentPost = dummyPosts.posts.find(post => post.slug === slug) || dummyPosts.posts[0];

  useEffect(() => {
    // Set sidebar data from JSON
    setSidebar(tourSidebarData);
  }, []);

  useEffect(() => {
    // If no slug is provided, navigate to the default post
    if (!slug && dummyPosts.posts.length > 0) {
      navigate(`/vtour/${dummyPosts.posts[0].slug}`, { replace: true });
    }
  }, [slug, navigate]);

  useEffect(() => {
    // Load Kuula embed script
    const script = document.createElement('script');
    script.src = 'https://static.kuula.io/embed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const toggleExpand = (index) => {
    setExpandedItems(prev => {
      const newState = {};
      // Collapse all items
      Object.keys(prev).forEach(key => {
        newState[key] = false;
      });
      // Toggle the clicked item
      newState[index] = !prev[index];
      return newState;
    });
  };

  return (
    <div>
      <Navigation />
      <div id="vtour_header" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {/* Desktop view */}
                <div className="d-none d-md-block">
                  {sidebar.posts.map((link, index) => (
                    <div key={index}>
                      <div
                        className={location.pathname === `/vtour/${link.slug}` ? "active-menu" : "inactive"}
                        onClick={() => toggleExpand(index)}
                        style={{ cursor: 'pointer' }}
                      >
                        <Link to={`/vtour/${link.slug}`} className="text-sm">
                          {link.title}
                        </Link>
                      </div>
                      {link.subItems && expandedItems[index] && (
                        <div className="sub-menu ms-3">
                          {link.subItems.map((subItem, subIndex) => (
                            <div key={subIndex} className={location.pathname === `/vtour/${subItem.slug}` ? "active-menu" : "inactive"}>
                              <Link to={`/vtour/${subItem.slug}`} className="text-sm">
                                {subItem.title}
                              </Link>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>


              </div>
            </div>
          </div>

          {/* Content Area */}
          <div id="content" className="col-md-8 col-lg-9">
            {/* Mobile view */}
            <div className="d-block d-md-none">
              <select
                className="form-select"
                onChange={(e) => navigate(`/vtour/${e.target.value}`)}
                value={location.pathname.replace('/vtour/', '')}
              >
                {sidebar.posts.map((link, index) => (
                  <React.Fragment key={index}>
                    <option value={link.slug}>{link.title}</option>
                    {link.subItems && link.subItems.map((subItem, subIndex) => (
                      <option key={subIndex} value={subItem.slug}>&nbsp;&nbsp;&nbsp;{subItem.title}</option>
                    ))}
                  </React.Fragment>
                ))}
              </select>
            </div>

            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">IKNS Virtual Tour</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>{currentPost.title}</strong></Link></li>
                </ol>
              </div>
            </div>


            <h4 className="mt-3 page-title">{currentPost.title}</h4>

            <div className="kuula-embed-wrapper" style={{ position: 'relative', width: '100%', paddingBottom: '56.25%' }}>
              <iframe
                title="Virtual Campus Tour"
                src={`https://kuula.co/share/${currentPost.frameId}/collection/7Z1md?logo=1&info=0&logosize=151&fs=1&vr=1&zoom=1&gyro=1&sd=1&initload=0&thumbs=3`}
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                frameBorder="0"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
