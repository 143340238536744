export const SidebarLinks = [
  {
    label: "Media Center",
    path: "/media-center"
  },
  {
    label: "Borrowing Policies",
    path: "/borrowing-policies"
  },
  {
    label: "Opening Hours",
    path: "/media-center-opening-hours"
  },
  {
    label: "Online Catalog",
    path: "/online-catalog"
  },
  {
    label: "Online Database (A-Z)",
    path: "/online-database"
  },
  {
    label: "Ask-A-Librarian",
    path: "/contact-us"
  }
];

export default SidebarLinks;
