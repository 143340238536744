export const SidebarLinks = [
  {
    label: "Graduation Requirements",
    path: "/graduation-requirements"
  },

  {
    label: "CPISP",
    path: "/cpisp"
  },
];

export default SidebarLinks;
