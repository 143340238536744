import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/media-center';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const OnlineCatalog = () => {

  const location = useLocation();


  return (
    <div>
      <Navigation />
      <div id="media_center" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="d-block d-md-none">
              <select
                className="form-select"
                onChange={(e) => window.location.href = e.target.value}
                value={location.pathname}
              >
                {sidebarLinks.map((link, index) => (
                  <option key={index} value={link.path}>
                    {link.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="/">Media Center</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>Online Catalog</strong></Link></li>
                </ol>
              </div>
            </div>



            <h4 className="mt-3 page-title">Media Centers Online Catalog</h4>
            <div className="col-md-12 mx-auto">
              <div className="">
                <img src={require('../../assets/img/media-center/logo_destiny.jpg')} className="" alt='...' width="200" />
              </div>
              <a href="https://ikns.follettdestiny.com/common/welcome.jsp?context=saas903_8501222" target="_blank" rel="noreferrer" className="btn btn-primary">Access Destiny Catalog</a>

              <p className="mt-5">Follett destiny is the library catalog that allows anyone access from home or other locations the media centers' books, DVDs, CDs, digital and online resources. Search any items by keywords, title, author, subject, etc.</p>
              <div className="">
                <img src={require('../../assets/img/media-center/ikns_follett_scr.jpg')} alt='...' className="img-fluid" />
              </div>

              <ol>
                <li>Click Ibn Khuldoon Elementary Library to access the elementary media center catalog</li>
                <li>Click Al Gosaibi Media Center to access the middle/high school media center catalog</li>
              </ol>

              <hr />

              <div className="mt-5">
                <img src={require('../../assets/img/media-center/follett_logo.jpg')} className="" alt='...' width="250" />
              </div>
              <a href="https://ikns.follettdestiny.com/district/servlet/presentdistrictloginform.do" target="_blank" rel="noreferrer" className="btn btn-primary">Access Destiny Discover</a>

              <p className="mt-5">Destiny Catalog also allows students access the media Centers' ebook and audiobooks at home and anywhere in the world.</p>

              <p>To read and check out ebooks, please log-in to Destiny Catalog with your school email account. Once you sign-in, your name will be displayed on Destiny Discover page. For more details, please see Quick Search to Check Out EBooks.</p>


              <p><strong>Quick Search to Checkout EBooks</strong></p>

              <ol>
                <li>Click the media center where you are registered as a patron.</li>
                <li>Login/enter username: <strong>2010058@ikns.edu.bh</strong> and <strong>password: iknsxxx</strong></li>
                <li>(Elementary students user their School ID to Login)</li>
                <li>Under <strong>Catalog Tab</strong>, click <strong>Destiny Discover</strong>.</li>
                <li>In the <strong>Search Box</strong>, type any Keywords then <strong>click search icon</strong></li>
                <li>To read Ebook, click <strong>"Open Tab"</strong> and to Checkout, <strong>"Checkout Tab"</strong></li>
              </ol>

            </div>
          </div>
        </div>
      </div>



      <Footer />
    </div>
  )
}

