import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import '../../styles/headers.css';
import '../../styles/innerpage.css';
import sidebarLinks from '../../components/sidebar/admission';

export const AvailabilityofSeatsAndDeadlines = () => {

  const location = useLocation();

  return (
    <div>
      <Navigation />
      <div id="admission" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">

        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      {link.target ? (
                        <a className="text-sm" href={link.path} target={link.target}>
                          {link.label}
                        </a>
                      ) : (
                        <Link className="text-sm" to={link.path}>
                          {link.label}
                        </Link>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="d-block d-md-none">
              <select
                className="form-select"
                onChange={(e) => window.location.href = e.target.value}
                value={location.pathname}
              >
                {sidebarLinks.map((link, index) => (
                  <option key={index} value={link.path}>
                    {link.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="/">Admission</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>Availability of Seats & Deadlines</strong></Link></li>
                </ol>
              </div>
            </div>

            <h4 className="mt-3 page-title">Availability of Seats & Deadlines</h4>
            <div className="col-md-12 mx-auto">

              <p>We are pleased to announce that admissions will open to all prospective students for the 2025-2026 academic year from the <strong>3rd to 13th of November 2024</strong>.</p>

              <ul>
                <li>KG1 Entrance Interviews will be conducted on <strong>Saturday, the 30th November</strong></li>
                <li>KG2 to Grade 5 Entrance Examinations will be conducted in the period from the <strong>1st to the 12th of December</strong></li>
                <li>Grades 6 to 11 Entrance Exams will be conducted in <strong>January/February 2025</strong>.</li>
              </ul>

              <p>The table below shows the availability of seats at IKNS per grade level. It also shows the IKNS Grade Level equivalency with the British System. Kindly note that as the grade levels are already full, the seats available are limited.</p>


              <table className="table table-bordered text-center">
                <thead>
                  <tr>
                    <th style={{ background: '#2b2729', color: '#fff', verticalAlign: 'middle', textAlign: 'center', width: '150px' }}>Grade Level @ IKNS</th>
                    <th style={{ background: '#2b2729', color: '#fff', verticalAlign: 'middle', textAlign: 'center', width: '200px' }}>Grade level equivalency in British System</th>
                    <th style={{ background: '#2b2729', color: '#fff', verticalAlign: 'middle', textAlign: 'center' }}>Corresponding Age</th>
                    <th style={{ background: '#2b2729', color: '#fff', verticalAlign: 'middle', textAlign: 'center' }}>Seat Availability</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>KG1</td>
                    <td>Reception</td>
                    <td>4 years old (Born in 2021)</td>
                    <td>Available</td>
                  </tr>

                  <tr>
                    <td>KG2</td>
                    <td>Year 1</td>
                    <td>5 years old (Born in 2020)</td>
                    <td>Waiting list</td>
                  </tr>

                  <tr>
                    <td>Grade 1</td>
                    <td>Year 2</td>
                    <td>6 years old (Born in 2019)</td>
                    <td>Waiting list</td>
                  </tr>

                  <tr>
                    <td>Grade 2</td>
                    <td>Year 3</td>
                    <td>7 years old</td>
                    <td>Waiting list</td>
                  </tr>

                  <tr>
                    <td>Grade 3</td>
                    <td>Year 4</td>
                    <td>8 years old</td>
                    <td>Waiting list</td>
                  </tr>

                  <tr>
                    <td>Grade 4</td>
                    <td>Year 5</td>
                    <td>9 years old</td>
                    <td style={{ color: '#ff0000' }}>Closed</td>
                  </tr>

                  <tr>
                    <td>Grade 5</td>
                    <td>Year 6</td>
                    <td>10 years old</td>
                    <td style={{ color: '#ff0000' }}>Closed</td>
                  </tr>

                  <tr>
                    <td>Grade 6</td>
                    <td>Year 7</td>
                    <td>11 years old</td>
                    <td>Waiting list</td>
                  </tr>

                  <tr>
                    <td>Grade 7</td>
                    <td>Year 8</td>
                    <td>12 years old</td>
                    <td>Waiting list</td>
                  </tr>

                  <tr>
                    <td>Grade 8</td>
                    <td>Year 9</td>
                    <td>13 years old</td>
                    <td>Waiting list</td>
                  </tr>

                  <tr>
                    <td>Grade 9</td>
                    <td>Year 10</td>
                    <td>14 years old</td>
                    <td>Waiting list</td>
                  </tr>

                  <tr>
                    <td>Grade 10</td>
                    <td>Year 11</td>
                    <td>15 years old</td>
                    <td>Waiting list</td>
                  </tr>

                  <tr>
                    <td>Grade 11</td>
                    <td>Year 12</td>
                    <td>16 years old</td>
                    <td>Waiting list</td>
                  </tr>

                  <tr>
                    <td>Grade 12</td>
                    <td colSpan="3">The school does not accept applications for Grade 12</td>
                  </tr>
                </tbody>
              </table>

              <p>There are limited seats available in the grade levels that have waiting lists. Interested parents may fill an Application Form. The Admissions Office will update you via SMS/email regarding the status of your child's application.</p>

              <p><strong>For inquiries, please contact Ms. Rima AlKady, School Registrar, at 17-780661, Extension 127 or Email: r.kady@ikns.edu.bh</strong></p>


            </div>
          </div>
        </div>

        <div className="contact-section col-lg-9 mx-auto">
          <h4 className="mt-3 page-title">Contact Us</h4>
          <p>If you have questions about the School Admission, we are here to help.</p>
          <Link to="/contact-us" className="text-link">Go to contact form <i className="fas fa-arrow-right text-right"></i></Link>
        </div>

      </div>
      <Footer />
    </div>
  )
}


