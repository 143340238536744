import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import '../../styles/headers.css';
import '../../styles/innerpage.css';
import sidebarLinks from '../../components/sidebar/admission';
import { useState } from 'react';


export const Admission = () => {

  const location = useLocation();
  const [showPopup, setShowPopup] = useState(true);

  return (
    <div>
      {showPopup && (
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '5px',
          boxShadow: '0 0 10px rgba(0,0,0,0.3)',
          zIndex: 1000
        }}>
          <button
            style={{
              position: 'absolute',
              right: '10px',
              top: '0px',
              border: 'none',
              background: 'none',
              fontSize: '30px',
              cursor: 'pointer'
            }}
            onClick={() => setShowPopup(false)}
          >
            ×
          </button>
          <h4 className="mt-3 page-title">IKNS Admissions</h4>
          <p>Applications for admission are now closed.</p>
        </div>
      )}

      <Navigation />
      <div id="admission" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">

        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      {link.target ? (
                        <a className="text-sm" href={link.path} target={link.target}>
                          {link.label}
                        </a>
                      ) : (
                        <Link className="text-sm" to={link.path}>
                          {link.label}
                        </Link>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="d-block d-md-none">
              <select
                className="form-select"
                onChange={(e) => window.location.href = e.target.value}
                value={location.pathname}
              >
                {sidebarLinks.map((link, index) => (
                  <option key={index} value={link.path}>
                    {link.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>Admission</strong></Link></li>
                </ol>
              </div>
            </div>

            <h4 className="mt-3 page-title">Welcome to the Ibn Khuldoon National School (IKNS) Admissions page.</h4>
            <div className="col-md-12 mx-auto">

              <p>We thank you for your interest in IKNS and look forward to getting to know you and your child as
                we work through the admission process. Please visit the different sections of the <a href="https://ikns.edu.bh/" className="text-danger"><u>School website</u></a> to learn about the school in general and the educational programmes we offer.</p>

              <p>IKNS accepts eligible students from KG1 to Grade 11, aged 4 to 18 years old. The school is authorized by the International Baccalaureate (IB) to offer
                the <a href="https://www.ibo.org/programmes/primary-years-programme" className="text-danger" target="_blank" rel="noreferrer"><u>IB Primary Years Program</u></a> (KG1 to Grade 5),
                the <a href="https://www.ibo.org/programmes/middle-years-programme/" className="text-danger" target="_blank" rel="noreferrer"><u>IB Middle Years Program</u></a> (Grades 6 to 10).
                In Grades 11 & 12, IKNS offers two parallel programs, the <a href="https://www.ibo.org/programmes/diploma-programme/" className="text-danger" target="_blank" rel="noreferrer"><u>IB Diploma Program</u></a> and the <Link to="/american-high-school-diploma" className="text-danger"><u>American High School Diploma</u></Link>.</p>


              <p>Parents who are interested in enrolling their children at IKNS are expected to go through
                the <a href="https://heyzine.com/flip-book/fd108de51b.html" className="text-danger" target="_blank" rel="noreferrer"><u>IKNS Brochure</u></a> in order to familiarize themselves with the school culture.</p>


              <p>The school has been accredited by "Middle States Association for Colleges
                and Schools" <a href="https://msa-cess.org/" className="text-danger" target="_blank" rel="noreferrer"><u>(MSA)</u></a> since 1994 and operates under the authorization of the
                Ministry of Education in Bahrain as a non-profit educational institution.
                The <a href="https://drive.google.com/file/d/1Ib-Bt33kFWLbFZ6QIRgqRHCwHiXhhfUd/view" className="text-danger" target="_blank" rel="noreferrer"><u>MSA Accreditation Team Report</u></a> for the most recent accreditation cycle 2023-2030 is available for your reference.</p>

              <p>By enrolling your child at IKNS, you implicitly vow to accept the
                school's <Link to="/philosophy-statement" className="text-danger"><u>Philosophy Statement</u></Link>, <Link to="/mission-vision" className="text-danger"><u>Vision & Mission</u></Link>, <Link to="/core-values" className="text-danger"><u>Core Values</u></Link> and <Link to="/graduate-profile" className="text-danger"><u>Graduate Profile</u></Link>. You also
                accept the school's rules and regulations as stipulated in the Student & Parent Handbook. This is necessary for a long term partnership that is based on transparency and trust.</p>

              <p><strong>For inquiries, please contact Ms. Rima AlKady, School Registrar, at 17-780661, Extension 127 or Email: r.kady@ikns.edu.bh</strong></p>
            </div>
          </div>
        </div>


        <div className="contact-section col-lg-9 mx-auto">
          <h4 className="mt-3 page-title">Contact Us</h4>
          <p>If you have questions about the School Admission, we are here to help.</p>
          <Link to="/contact-us" className="text-link">Go to contact form <i className="fas fa-arrow-right text-right"></i></Link>
        </div>

      </div>
      <Footer />
    </div>
  )
}


