import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/media-center';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const MediaCenterOpeningHours = () => {

  const location = useLocation();


  return (
    <div>
      <Navigation />
      <div id="media_center" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="d-block d-md-none">
              <select
                className="form-select"
                onChange={(e) => window.location.href = e.target.value}
                value={location.pathname}
              >
                {sidebarLinks.map((link, index) => (
                  <option key={index} value={link.path}>
                    {link.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="/">Media Center</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>Opening Hours</strong></Link></li>
                </ol>
              </div>
            </div>



            <h4 className="mt-3 page-title">Media Centers Opening Hours</h4>
            <div className="col-md-12 mx-auto">
              <p className="mt-5"><strong>Elementary School Library</strong></p>
              <p>7:30 AM - 3:00 PM (Sunday - Thursday)<br />
                7:30 AM - 3:00 PM (Tuesday)</p>


              <p className="mt-5"><strong>Middle School/Secondary School Library</strong></p>
              <p>7:30 AM - 3:00 PM (Sunday - Thursday)<br />
                7:30 AM - 3:00 PM (Tuesday)</p>

              <p><em>The Media Centers are open during school day, during break time and after school</em></p>


            </div>
          </div>
        </div>
      </div>



      <Footer />
    </div>
  )
}

