import axios from 'axios';

// Access environment variables
const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

/**
 * Fetches images from a specified Google Drive folder.
 * @param {string} googleFolder - The ID of the Google Drive folder to fetch images from.
 * @returns {Promise<Array>} An array of image file objects with accessible URLs.
 */

export const fetchImages = async (googleFolder) => {

  if (!googleFolder) {
    throw new Error('Folder ID is required to fetch images.');
  }

  // Construct the query to fetch images from the specified folder
  const query = `'${googleFolder}' in parents and mimeType contains 'image/' and trashed=false`;
  const fields = 'files(id, name, mimeType, thumbnailLink, webContentLink)'; // Specify fields to retrieve
  const url = `https://www.googleapis.com/drive/v3/files?q=${encodeURIComponent(
    query
  )}&fields=${fields}&key=${API_KEY}`;

  try {
    const response = await axios.get(url);
    const files = response.data.files;

    // Construct accessible URLs for the images
    const imageFiles = files.map((file) => ({
      id: file.id,
      name: file.name,
      mimeType: file.mimeType,
      thumbnail: file.thumbnailLink, // Google Drive-provided thumbnail
      url: `https://drive.google.com/uc?id=${file.id}`, // Public image URL
    }));

    console.log('Fetched images:', imageFiles); // Debugging
    return imageFiles;
  } catch (error) {
    console.error('Error fetching images:', error);
    throw error; // Propagate error to handle in component
  }
};
