import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import '../../styles/headers.css';
import '../../styles/innerpage.css';
import sidebarLinks from '../../components/sidebar/admission';

export const ApplicationFormRequiredDocuments = () => {

  const location = useLocation();

  return (
    <div>
      <Navigation />
      <div id="admission" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">

        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      {link.target ? (
                        <a className="text-sm" href={link.path} target={link.target}>
                          {link.label}
                        </a>
                      ) : (
                        <Link className="text-sm" to={link.path}>
                          {link.label}
                        </Link>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="d-block d-md-none">
              <select
                className="form-select"
                onChange={(e) => window.location.href = e.target.value}
                value={location.pathname}
              >
                {sidebarLinks.map((link, index) => (
                  <option key={index} value={link.path}>
                    {link.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="/">Admission</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>Application Form Required Documents</strong></Link></li>
                </ol>
              </div>
            </div>

            <h4 className="mt-3 page-title">Application Form Required Documents</h4>
            <div className="col-md-12 mx-auto">
              <p>Below are the documents required to be uploaded when the parent fills the Admission Application Form. Kindly, have clearly scanned copies of these documents to be uploaded at the end of the Application Form.</p>



              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th style={{ background: '#2b2729', width: '50%', color: '#fff', textAlign: 'center' }} className="p-3">KG1</th>
                    <th style={{ background: '#2b2729', width: '50%', color: '#fff', textAlign: 'center' }} className="p-3">KG2 to Grade 11</th>
                  </tr>
                </thead>

                <tbody style={{ fontSize: '13px' }}>
                  <tr>
                    <td>Scanned copy of the student's Passport</td>
                    <td>Scanned copy of student's Passport</td>
                  </tr>

                  <tr>
                    <td>Scanned copy of the student's Birth Certificate</td>
                    <td>Scanned copy of student's Birth Certificate</td>
                  </tr>

                  <tr>
                    <td>Scanned copy of the student's Identity Card (both sides)</td>
                    <td>Scanned copy of student's Identity Card (both sides)</td>
                  </tr>

                  <tr>
                    <td>Scanned copy of the father's Identity Card (both sides)</td>
                    <td>Scanned copy of father's Identity Card (both sides)</td>
                  </tr>

                  <tr>
                    <td>Scanned copy of the mother's Identity Card (both sides)</td>
                    <td>Scanned copy of mother's Identity Card (both sides)</td>
                  </tr>

                  <tr>
                    <td></td>
                    <td>Last school year's Grade Report and this school year's latest Progress/Grade report</td>
                  </tr>
                </tbody>
              </table>





            </div>
          </div>
        </div>

        <div className="contact-section col-lg-9 mx-auto">
          <h4 className="mt-3 page-title">Contact Us</h4>
          <p>If you have questions about the School Admission, we are here to help.</p>
          <Link to="/contact-us" className="text-link">Go to contact form <i className="fas fa-arrow-right text-right"></i></Link>
        </div>

      </div>
      <Footer />
    </div>
  )
}


