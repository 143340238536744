import { Link } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import '../../styles/innerpage.css';
import '../../styles/headers.css';

export const KgAndElementary = () => {
  return (
    <div>
      <Navigation />
      <div id="kg_and_elementary" className="container-fluid col-md-12 page-header"></div>
      <div id="one-page" className="wrapper">
        <div className="row">
          <div className="col-md-10">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item"><Link to="#">Key Info</Link></li>
              <li className="breadcrumb-item"><Link to="#"><strong>Kindergarten & Elementary (K-5)</strong></Link></li>
            </ol>

          </div>
        </div>

        <div className="row g-0">
          <div className="col-md-12 col-lg-6">
            <img src={require('../../assets/img/kg-and-elementary/cover.png')} className="w-100 h-100 img-fluid" alt='...' />
          </div>
          <div className="col-md-12 col-lg-6 bg-gray p-3">
            <div className="container d-flex h-100">
              <div className="row justify-content-center align-self-center">
                <h4 className="mt-3 page-title">Kindergarten & Elementary (K-5)</h4>

                <p className="mt-3">The Kindergarten and Elementary School is from KG 1 to Grade 5. These grades offer the International
                  Baccalaureate Primary Years Programme (IB PYP) as an authorized school as of 2019.
                  More information about the PYP at IKNS can be found under the tab <Link to="/pyp" className="text-danger">ACADEMICS</Link> on the main page of the website.</p>

                <a href="https://drive.google.com/file/d/1ngB1YTDp_jPtwvD70urIF83uj1DLmv-v/view" target="_blank" rel="noreferrer" className="mt-2 d-block text-link">KG and Elementary Rules and Procedures</a>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-section col-lg-9 mx-auto">
          <h4 className="mt-3 page-title">Contact Us</h4>
          <p>If you have questions about the Kindergarten and Elementary School, we are here to help.</p>
          <Link to="/contact-us" className="text-link">Go to contact form <i className="fas fa-arrow-right text-right"></i></Link>
        </div>

      </div>
      <Footer />
    </div>
  )
}


