import { Link } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const MiddleSchool = () => {

  return (
    <div>
      <Navigation />

      <div id="middle_school" className="container-fluid col-md-12 page-header"></div>
      <div id="one-page" className="wrapper">
        <div className="row">
          <div className="col-md-10">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item"><Link to="#">Key Info</Link></li>
              <li className="breadcrumb-item"><Link to="#"><strong>Middle School (6-8)</strong></Link></li>
            </ol>
          </div>
        </div>


        <div className="row g-0">
          <div className="col-md-12 col-lg-6">
            <img src={require('../../assets/img/middle_school/cover.png')} className="w-100 h-100 img-fluid" alt='...' />
          </div>
          <div className="col-md-12 col-lg-6 bg-gray p-3">
            <div className="container d-flex h-100">
              <div className="row justify-content-center align-self-center">
                <h4 className="mt-3 page-title">Middle School (6-8)</h4>
                <p className="mt-3">
                  The Middle School is from Grade 6 to Grade 8. The educational programmes in the
                  Middle School are designed and structured based on the IB Middle Years Programme (MYP).
                  IKNS is an IB MYP candidate school and is seeking authorisation for the programme.
                  More information about the MYP at IKNS can be found under the
                  tab <Link to="/myp">ACADEMICS</Link> on the main page of the website.
                </p>

                <Link to="/myp" className="mt-2 d-block text-link">The IB Middle Years Programme (G6-G10)</Link>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-section col-lg-9 mx-auto">
          <h4 className="mt-3 page-title">Contact Us</h4>
          <p>If you have questions about the Middle School, we are here to help.</p>
          <Link to="/contact-us" className="text-link">Go to contact form <i className="fas fa-arrow-right text-right"></i></Link>
        </div>

      </div>
      <Footer />
    </div>
  )
}


