import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/ib-learner-profile';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const IbLearnerProfile = () => {

  const location = useLocation();


  return (
    <div>
      <Navigation />
      <div id="ib_learner_profile" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>IB Learner Profile</strong></Link></li>
                </ol>
              </div>
            </div>



            <h4 className="mt-3 page-title">IB Learner Profile</h4>


            <div className="col-md-12 mx-auto">
              <p>The IB identifies ten personal traits that it refers to as the IB Learner Profile. The IB learner profile is at the core of the PYP, MYP and DP models. It aims to develop learners who are inquirers, knowledgeable, thinkers, communicators, principled, open-minded, caring, risk takers, balanced and reflective.</p>
              <p>The IB&#39;s programmes aim to develop students according to the IB learner profile. The profile aims to develop learners who are:</p>

              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td className="align-middle" style={{ color: "#fff", backgroundColor: "#6A1F41" }}>Inquirers</td>
                    <td>We nurture our curiosity, developing skills for inquiry and research. We know how to learn independently and with others. We learn with enthusiasm and sustain our love of learning throughout life.</td>
                  </tr>

                  <tr>
                    <td className="align-middle" style={{ color: "#fff", backgroundColor: "#6A1F41" }}>Knowledgeable</td>
                    <td style={{ background: "#ddd" }}>We develop and use conceptual understanding, exploring knowledge across a range of disciplines. We engage with issues and ideas that have local and global significance.</td>
                  </tr>

                  <tr>
                    <td className="align-middle" style={{ color: "#fff", backgroundColor: "#6A1F41" }}>Thinkers</td>
                    <td>We use critical and creative thinking skills to analyze and take responsible action on complex problems. We exercise initiative in making reasoned, ethical decisions.</td>
                  </tr>

                  <tr>
                    <td className="align-middle" style={{ color: "#fff", backgroundColor: "#6A1F41" }}>Communicators</td>
                    <td style={{ background: "#ddd" }}>We express ourselves confidently and creatively in more than one language and in many ways. We collaborate effectively, listening carefully to the perspectives of other individuals and groups.</td>
                  </tr>

                  <tr>
                    <td className="align-middle" style={{ color: "#fff", backgroundColor: "#6A1F41" }}>Principled</td>
                    <td>We act with integrity and honesty, with a strong sense of fairness and justice, and with respect for the dignity and rights of people everywhere. We take responsibility for our actions and their consequences. </td>
                  </tr>


                  <tr>
                    <td className="align-middle" style={{ color: "#fff", backgroundColor: "#6A1F41" }}>Open-minded</td>
                    <td style={{ background: "#ddd" }}>We critically appreciate our own cultures and personal histories, as well as the values and traditions of others. We seek and evaluate a range of points of view, and we are willing to grow from the experience. </td>
                  </tr>


                  <tr>
                    <td className="align-middle" style={{ color: "#fff", backgroundColor: "#6A1F41" }}>Caring</td>
                    <td>We show empathy, compassion and respect. We have a commitment to service, and we act to make a positive difference in the lives of others and in the world around us. </td>
                  </tr>


                  <tr>
                    <td className="align-middle" style={{ color: "#fff", backgroundColor: "#6A1F41" }}>Risk-takers</td>
                    <td style={{ background: "#ddd" }}>We approach uncertainty with forethought and determination; we work independently and cooperatively to explore new ideas and innovative strategies. We are resourceful and resilient in the face of challenges and change. </td>
                  </tr>


                  <tr>
                    <td className="align-middle" style={{ color: "#fff", backgroundColor: "#6A1F41" }}>Balanced</td>
                    <td>We understand the importance of balancing different aspects of our lives—intellectual, physical, and emotional—to achieve well-being for ourselves and others. We recognize our interdependence with other people and with the world in which we live. </td>
                  </tr>

                  <tr>
                    <td className="align-middle" style={{ color: "#fff", backgroundColor: "#6A1F41" }}>Reflective</td>
                    <td style={{ background: "#ddd" }}>We thoughtfully consider the world and our own ideas and experience. We work to understand our strengths and weaknesses in order to support our learning and personal development.</td>
                  </tr>
                </tbody>
              </table>

              <p style={{ fontSize: "13px" }}><em>Source: The IB Learner Profile. International Baccalaureate, 2013, <a href="https://www.ibo.org/contentassetsfd82f70643ef4086b7d3f292cc214962/learner-profile-en.pdf" className="text-link" target="_blank" rel="noreferrer">
                www.ibo.org/contentassetsfd82f70643ef4086b7d3f292cc214962/learner-profile-en.pdf.
              </a></em></p>

            </div>
          </div>
        </div>
      </div>



      <Footer />
    </div>
  )
}

