import { Link } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const Parents = () => {


  return (
    <div>
      <Navigation />
      <div id="parent" className="container-fluid col-md-12 page-header"></div>

      <div id="one-page" className="wrapper">
        <div className="row">
          <div className="row">
            <div className="col-md-10">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item"><Link to="#">IKNS Community</Link></li>
                <li className="breadcrumb-item"><Link to="#"><strong>Parents Teachers Students Association (PTSA)</strong></Link></li>
              </ol>
              {/* <h4 className="mt-3 page-title">Parents</h4> */}
            </div>
          </div>



          <div className="row g-0">
            <div className="col-md-12 col-lg-6">
              <img src={require('../../assets/img/parent/cover.png')} className="w-100 h-100 img-fluid" alt='...' />
            </div>
            <div className="col-md-12 col-lg-6 bg-gray p-3">
              <div className="container d-flex h-100">
                <div className="row justify-content-center align-self-center">
                  <h4 className="mt-3 page-title">Parents Teachers Students Association (PTSA)</h4>
                  <p className="mt-3">
                    The School elects a Parents Teachers Students Association (PTSA), which acts as a bridge between parents and the school. The PTSA organizes presentations, workshops and activities for the IKNS community at large. They also work towards raising funds to help the school enhance its facilities.ttee and the Legal Committee. The Chair of the PTSA is an ex-officio member of the Board of Directors.
                  </p>

                  {/* <Link to="#" className="mt-2 mb-1 d-block text-link">Volunteering Opportunities</Link>
                  <Link to="#" className="mb-1 d-block text-link">Volunteers Code of Conduct</Link> */}

                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="contact-section col-lg-9 mx-auto">
          <h4 className="mt-3 page-title">Contact Us</h4>
          <p>If you have questions about the Parents Teachers Students Association (PTSA), we are here to help.</p>
          <Link to="/contact-us" className="text-link">Go to contact form <i className="fas fa-arrow-right text-right"></i></Link>
        </div>

      </div>

      <Footer />
    </div>
  )
}


