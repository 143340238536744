import '../../styles/homepage.css';
import { Navigation } from "../../components/Navigation"
import { Hero } from './Hero';
import { Bandlinks } from './Bandlinks';
import { NewsEvents } from './NewsEvents';
import { Ourcampus } from "./Ourcampus"
import { HomeAffiliation } from './Affiliations';
// import { HomepageModal } from '../../components/HomepageModal';
import { Footer } from "../../components/Footer"
import { Brands } from '../brands/logos';

export const Homepage = () => {
  return (
    <>
      <Navigation />
      <Hero />
      <div className="custom-graybg">
        <Bandlinks />
        <Brands />
        <NewsEvents />
        <Ourcampus />
      </div>
      {/* <HomepageModal /> */}
      <HomeAffiliation />
      <Footer />
    </>
  )
}