import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/graduation-requirements';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const Cpisp = () => {

  const location = useLocation();

  const cpisp = [
    { year: 2001, recipients: "Fawaz Yusuf Abdulla" },
    { year: 2001, recipients: "Lubna Mohamed Altajir" },
    { year: 2001, recipients: "Aysha Munem Alshirawi" },
    { year: 2002, recipients: "Yousif Mohamed" },
    { year: 2003, recipients: "Nadeem Ma'mun Al Moayyed" },
    { year: 2004, recipients: "Faisal Khalid Buali" },
    { year: 2004, recipients: "Fajer Ali Al Binali" },
    { year: 2005, recipients: "Ahmed Yusuf Abdulla" },
    { year: 2005, recipients: "Dana Mohamed Maki" },
    { year: 2005, recipients: "Faten Alyes Alqaseer" },
    { year: 2007, recipients: "Mishal Emad Awadeh" },
    { year: 2007, recipients: "Subah Khalid Buali" },
    { year: 2008, recipients: "Abdulla Saleh Abdulaal" },
    { year: 2008, recipients: "Rawan Mohamed Maki" },
    { year: 2008, recipients: "Sarah Ameen" },
    { year: 2009, recipients: "Maryam Ahmed Alireza" },
    { year: 2010, recipients: "Maryam Mohamed Maki" },
    { year: 2013, recipients: "Khalid Abdulmajeed Abdulkarim" },
    { year: 2016, recipients: "Saif Najeeb Jamsheer" },
    { year: 2017, recipients: "Lulwa Mohamed Al Khalifa" },
    { year: 2019, recipients: "Yasmeen Nabeel Nooruddin" },
    { year: 2020, recipients: "Faris Ammar Al Haddad" },
    { year: 2022, recipients: "Zain Yaser Al Khaja" },
    { year: 2023, recipients: "Abdulrahman Faisal Rafeea" },
    { year: 2023, recipients: "Ebrahim Ahmed Al Khalifa" },
    { year: 2024, recipients: "Ebrahim Abdulla" },
  ];

  return (
    <div>
      <Navigation />
      <div id="graduation_requirements" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>The Crown Prince&#39;s International Scholarship Program (CPISP)</strong></Link></li>
                </ol>
                <h4 className="mt-3 page-title">The Crown Prince&#39;s International Scholarship Program (CPISP)</h4>
              </div>
            </div>


            <div className="col-md-6 mb-3 mt-3">
              <img src={require('../../assets/img/academic/CPISP-Newlogo2.png')} className="img-fluid rounded" alt='...' />
            </div>

            <p>The Crown Prince&#39;s International Scholarship Program (CPISP) was established in 1999 by His Royal Highness Prince Salman bin Hamad Al Khalifa, the Crown Prince and Prime Minister.The Program is privately funded by HRH Prince Salman and various local and international sponsoring organizations and individuals who share HRH&#39;s vision of equipping outstanding young Bahrainis with the necessary tools to excel and achieve their aspirations and contribute to the building of a modern Bahraini state.</p>

            <p>We are proud of our students who won the prestigious CPISP scholarship over the years. </p>


            <div className="col-md-10">
              <table className="table table-bordered" id="table2">
                <thead>
                  <tr>
                    <th style={{ backgroundColor: '#2b2729', color: '#fafafa', border: '1px solid #000' }} className="col-md-1 p-3">Year</th>
                    <th style={{ backgroundColor: '#2b2729', color: '#fafafa', border: '1px solid #000' }} className="p-3">Recipients Names</th>

                  </tr>
                </thead>
                <tbody>
                  {cpisp.map((item, index) => (
                    <tr key={index}>
                      <td>{item.year}</td>
                      <td>{item.recipients}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>




          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}


