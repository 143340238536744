import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/pyp';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const AssessmentInPyp = () => {

  const location = useLocation();

  return (
    <div>
      <Navigation />
      <div id="pyp" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>

            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="d-block d-md-none">
              <select
                className="form-select"
                onChange={(e) => window.location.href = e.target.value}
                value={location.pathname}
              >
                {sidebarLinks.map((link, index) => (
                  <option key={index} value={link.path}>
                    {link.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>Assessment in PYP</strong></Link></li>
                </ol>
                <h4 className="mt-3 page-title">Assessment in PYP</h4>
              </div>
            </div>

            <p>
              Assessment is an ongoing process of gathering, analysing, reflecting and acting
              on evidence of student learning to inform teaching. Formative Assessments are
              ongoing and Summative Assessments are taken at the end of each unit. Grading is
              based on levels 1-5. Detailed information about assessment at IKNS can be found
              in the KG and Elementary Assessment Policy.
            </p>

            <p className="content-title mt-5">
              Level Descriptors
            </p>


            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="table-active" scope="col">Levels</th>
                  <th className="table-active" scope="col">Descriptors</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center align-middle">1</td>
                  <td>A limited understanding of the learning objectives. The student has difficulty applying the required knowledge and skills.</td>
                </tr>
                <tr>
                  <td className="text-center align-middle">2</td>
                  <td>A partial understanding of the learning objectives. The student applies some of the basic elements of the required knowledge and skills.</td>
                </tr>
                <tr>
                  <td className="text-center align-middle">3</td>
                  <td>A satisfactory understanding of the learning objectives. The student applies the appropriate knowledge and skills in familiar classroom situations.</td>
                </tr>

                <tr>
                  <td className="text-center align-middle">4</td>
                  <td>A good understanding of the learning objectives. The student competently applies the required knowledge and skills in a wide variety of situations.</td>
                </tr>

                <tr>
                  <td className="text-center align-middle">5</td>
                  <td>A thorough and consistent understanding of the learning objectives. The student independently applies and transfers the knowledge and skills in a variety of classroom and real world situations. The student always produces work of a high standard.</td>
                </tr>

              </tbody>
            </table>


          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}


