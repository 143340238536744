import { Link } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const TheSchool = () => {

  return (
    <div className="teacher-testimonial">
      <Navigation />
      <div id="the_school" className="container-fluid col-md-12 page-header"></div>
      <div id="one-page" className="wrapper">
        <div className="row">
          <div className="col-md-10">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item"><Link to="#">Our School</Link></li>
              <li className="breadcrumb-item"><Link to="#"><strong>The School</strong></Link></li>
            </ol>
            <h4 className="mt-3 page-title">The School</h4>
          </div>
        </div>

        <div className="img-right col-md-6">
          <img src={require('../../assets/img/the_school/ikns.jpg')} className="img-fluid rounded" alt='...' />
        </div>

        <p>Ibn Khuldoon National School (IKNS) is a non-profit, self-supporting, coeducational institution
          that is dedicated to providing high quality education for local and expatriate students. The school
          is licensed by the Bahrain Ministry of Education to offer a bilingual programme of study
          for students from Kindergarten to Grade 12.</p>

        <p>The school received its first accreditation from the Middle States Association Commissions
          for Elementary and Secondary Schools (MSA) in 1994, and it continues to be in good standing
          with the association. As articulated by MSA, the accredited status of the school validates
          the integrity of the school's programme and students' transcripts. It also affirms that
          the school's purposes are appropriate and accomplished through a viable educational
          programme and justifies the faith and resources stakeholders place in the school.</p>

        <p>IKNS received an "Outstanding" rating by the Directorate of Private Schools & Kindergartens
          Reviews, which is a part of the Education & Training Quality Authority (BQA) in both
          the first and second review cycles that were conducted in March 2014 and October 2017 respectively.
          During both reviews, "reviewers observed lessons and other activities, scrutinised students' written
          works and analysed school's performance data and other documents. They met
          with staff members, students and parents.”</p>

        <p>IKNS is administratively divided into three divisions. The Kindergarten and Elementary
          School (K1 - G5), the Middle School (G6 - G8) and the Secondary School (G9 - G12). Each
          division has its own principal, who is responsible for the day-to-day running of his/her
          school. The three principals report to the School President.</p>

        <div className="col-md-12 mt-5">
          <img src={require('../../assets/img/the_school/facilities.jpg')} className="img-fluid rounded" alt='...' />
        </div>

        <p className="content-title mt-3">The Facilities</p>
        <p>The school campus sits on over 62,000 square meters of land in the Isa Town Educational Area, twelve kilometers away from Manama, the capital city of the Kingdom of Bahrain. The campus is divided geographically into two sections, Kindergarten and Elementary (KG1 - G5) and Middle and Secondary (G6 - G12)</p>
        <p>The Middle School and the Secondary School has five buildings, including an indoor sports complex (Farouk Almoayyed Sports Complex), a media center, five computer labs, three science labs, careers/universities lab, two meeting halls and sixty three classrooms, including four art spaces and one pottery space.</p>
        <p>The indoor sports complex has a total area of 2,314 square meters. It houses a five-lane swimming pool, two basketball-sized courts (that are also used for volleyball and badminton), a well-equipped fitness hall, a multipurpose hall, a class/meeting room and a first aid room.</p>
        <p>The Sports facilities in the Middle and Secondary divisions of the school also include an outdoor mini soccer field and a multipurpose outdoor field . The outdoor field can be used as basketball and Tennis courts, two of each, and handball.</p>
        <p>The Kindergarten and Elementary School has a 300 seater purpose-built auditorium, media center, four Music, Drama and Dance halls, three Art spaces, two Science labs, one ICT/STEM center, and a dining hall. There is a total of forty-four classrooms, fourteen of them are used by KG1 and KG2 students and thirty for G1 to G5.</p>
        <p>The Kindergarten and Elementary sports facilities include four indoor sports halls and an outdoor mini soccer field. Explore our campus through
          our <Link to="/vtour/start" className="text-link fw-normal">Virtual Walkthrough</Link>, or view our
          campus <Link to="/facilities-video" className="text-link fw-normal">facilities video here</Link>.</p>

        <div className="img-left col-md-6 mt-5">
          <img src={require('../../assets/img/the_school/media_center.jpg')} className="img-fluid rounded" alt='...' />
        </div>

        <p className="content-title mt-5">The Media Centers</p>
        <p>IKNS Media Centers are committed to provide updated resources and advanced technology services that support the curricula, teaching and research needs of the school community. The services are designed for K-12 students, faculty members, alumni and parents.</p>
        <p>There are two media centers at IKNS. The first is located in the Kindergarten and Elementary School and the second is in the Middle and Secondary schools (Fahad Abdulrahman Al Gosaibi Media Center).</p>
        <p>The Kindergarten and Elementary Media Center (K1- G5) provides 36,317 collections, including 24,895 titles and 1700 audio-visual materials.</p>
        <p>Fahad Abdulrahman Al Gosaibi Media Center (G6- G12) houses a wide range of over 41,500 collections consisting of 37,834 books and 3,588 audio-visual materials. The media center also subscribes to a growing number of online resources such as e-books, thousands of periodicals, and online databases (Ebsco, Infobase Learning, JSTOR and Worldbook).</p>
        <p>The media centers have an advanced network of technological support. In each media center, there are 24 computers and a Promethean interactive smartboard for group research and presentations. In addition, Fahad Abdulraham Al Gosaibi Media Center provides 13 individual computer workstations for students to access the online resources and Destiny catalogue.</p>

        <div className="img-right col-md-6 mt-5">
          <img src={require('../../assets/img/the_school/tech.jpg')} className="img-fluid rounded" alt='...' />
        </div>

        <p className="content-title mt-5">Technology</p>
        <p>The campus is fully wireless with connections available in several learning areas. All classrooms are equipped with Promethean Interactive Panels/boards. All teachers are issued Macbook laptops for their professional and personal use.</p>
        <p>With regards to the students&#39; computing devices, IKNS implements
          a <a href="https://drive.google.com/file/d/1WikR4z5_VQnLkihNv2u4zyiDZqbffqu5/view" target="_blank" rel="noreferrer" className="text-link fw-normal">'Bring Your Own Devices' (BYOD) Policy</a> for students in Grades 3 to 12. In Grades 3 to 5 (Upper Elementary), the students bring their devices when requested by their teachers, whereas in Grades 6 to 12, students are expected to bring their own computing device to school. Students in KG1 up to Grade 2 are not requested to bring their own devices. The School provides access to computing devices upon the teacher&#39;s request.</p>

        <p className="content-title mt-5">The Instructional Programmes</p>
        <p>The instructional programmes that IKNS offers are based on the International Baccalaureate curricula, enhanced by Bahrain&#39;s national curricula in Arabic Language, Islamic Studies and Social and National Studies.</p>
        <p>For students in Grades 11 and 12, IKNS offers the <Link to="/ibdp" className="text-link fw-normal">International Baccalaureate Diploma Programme (IBDP)</Link> and the <Link to="/american-high-school-diploma" className="text-link fw-normal">American High School Diploma (AHSD)</Link>, as two parallel
          programmes. Both the IBDP and the AHSD are tertiary education preparatory programmes, and are considered to be equivalent to the Bahraini General Certificate of Education.</p>
        <p>The School has been affiliated with the International Baccalaureate since 1990, as a Diploma Programme provider. The first group of students sat for the IBDP examinations in 1992.</p>
        <p>In July 2018, IKNS became an <Link to="/pyp" className="text-link fw-normal">International Baccalaureate Primary Years Programme (IB PYP)</Link> candidate school, and started offering the programme in September 2018 for all students in KG1 up to Grade 5.</p>
        <p>In July 2019, IKNS also became an <Link to="/myp" className="text-link fw-normal">International Baccalaureate Middle Years Programme (IB MYP)</Link> candidate school, and started offering the programme starting September 2019. The IB MYP is for students in Grade 6 up to Grade 10.</p>
        <p>In June 2020, Ibn Khuldoon National School became an authorized <a href="https://www.ibo.org/programmes/primary-years-programme" target="_blank" rel="noreferrer" className="text-link fw-normal">International Baccalaureate (IB)</a> <Link to="/pyp" className="text-link fw-normal">Primary Years Programme (PYP)</Link> school.</p>
        <p>As a candidate school for the Middle Years Programme, Ibn Khuldoon National School is pursuing authorization as a Continuum IB World School. IB World Schools share a common philosophy - a commitment to high-quality, challenging, international education - that we believe is important for our students.</p>

        <div className="col-md-12 mt-5">
          <img src={require('../../assets/img/the_school/faculty.jpg')} className="img-fluid rounded" alt='...' />
        </div>

        <p className="content-title mt-3">The Faculty</p>
        <p>A diverse faculty, whether teaching in Arabic or English, provides a rich and supportive learning environment for students. There are 150 Teachers from K1 to G12, and over 30 Teaching Assistants serving in the Lower Elementary Section (KG1 - G2). The faculty is supported by a dedicated team of over 70 administrative, IT and support staff.</p>
        <p>About a third of the faculty are Bahrainis, a third come from neighboring Arab countries and a third come from the English speaking world, including Canada, Ireland, South Africa, UK and USA. Most of the Teaching Assistants are Bahrainis.</p>


        <div className="img-left col-md-6 mt-5">
          <img src={require('../../assets/img/the_school/students.jpg')} className="img-fluid rounded" alt='...' />
        </div>
        <p className="content-title mt-5">The Students</p>
        <p>IKNS students come from families that greatly value education. Students can communicate fluently in Arabic and English from an early stage of their lives. They go on to complete their tertiary education in local, regional and international universities, including Bahrain, UK, USA, Canada and Arab countries, many gaining admission to prestigious institutions.</p>
        <p>About 82% to 85% of the students are Bahrainis while the rest are mostly Arab nationals.</p>

        <p className="content-title mt-5">The School Year</p>
        <p>The school year extends into two semesters. Semester 1 starts in September and extends until the end of January. It is followed by an end of semester break. Semester 2 starts in February and ends in June. It is followed by the summer holiday.</p>
        <p>The school year includes a minimum of 180 days of instruction. There is a winter vacation in December and short breaks in the middle of each semester. The school also closes on all Bahrain&#39;s official holidays. More information about the school year can be found on the School Calendar.</p>

        <p className="content-title mt-5">The Instructional Day</p>
        <p>The instructional days are Sunday through Thursday. School begins at 7:45 am for students in all levels. Kindergarten students are dismissed at 12:30 pm, Elementary students (Grades 1 - 5) at 1:50 pm, and Middle and Secondary students (Grades 6 - 12) at 2:50 pm. </p>
        <p>Tuesday is an early dismissal day for students and is referred to as “Short Day”. On the Short Day, the Elementary School students finish at 12:30 pm and the Middle and Secondary schools at 1:25 pm. Faculty members make use of the Short Day to meet and plan collaboratively. Detailed bell schedule can be found <Link to="/bell-schedule" className="text-link fw-normal">here</Link>.</p>

        <p className="content-title mt-5">The Administrative Structure of IKNS</p>
        <p>IKNS is administratively divided into three divisions. The Kindergarten and Elementary School (K1 - G5), the Middle School (G6 - G8) and the Secondary School (G9 - G12). Each division has its own principal, who is responsible for the day-to-day running of his/her school. The three principals report to the School President.</p>
      </div>
      <Footer />
    </div>
  );
}

