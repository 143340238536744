export const SidebarLinks = [

  {
    label: "IKNS In Motion",
    path: "/in-motion"
  },


];

export default SidebarLinks;
