import React, { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import '../../styles/headers.css';
import '../../styles/innerpage.css';
import menuSidebar from './menu.json';

export const Committees = () => {

  const location = useLocation();
  const [sidebar, setSidebar] = useState({ sideMenu: [] });
  const [expandedItems, setExpandedItems] = useState({});

  useEffect(() => {
    setSidebar(menuSidebar);
  }, []);

  const toggleExpand = (index) => {
    setExpandedItems(prev => {
      const newState = {};
      // Collapse all items
      Object.keys(prev).forEach(key => {
        newState[key] = false;
      });
      // Toggle the clicked item
      newState[index] = !prev[index];
      return newState;
    });
  };

  // Helper function to render menu item
  const renderMenuItem = (item) => {
    // For items with '#' slug
    if (item.slug === '#') {
      return (
        <Link to="#" className="text-sm">
          {item.title}
        </Link>
      );
    }

    // For external links (starting with http/https)
    if (item.slug.startsWith('http')) {
      return (
        <a
          href={item.slug}
          className="text-sm"
          target={item.target || '_self'}
          rel={item.target === '_blank' ? "noopener noreferrer" : undefined}
        >
          {item.title}
        </a>
      );
    }

    // For internal links
    return (
      <Link to={`/${item.slug}`} className="text-sm">
        {item.title}
      </Link>
    );
  };

  return (
    <div>
      <Navigation />

      <div id="governing_body" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {/* Desktop view */}
                <div className="d-none d-md-block">
                  {sidebar.sideMenu.map((link, index) => (
                    <div key={index}>
                      <div
                        className={location.pathname === `/${link.slug}` ? "active-menu" : "inactive"}
                        onClick={() => toggleExpand(index)}
                        style={{ cursor: 'pointer' }}
                      >
                        {renderMenuItem(link)}
                      </div>
                      {link.subItems && expandedItems[index] && (
                        <div className="sub-menu ms-3">
                          {link.subItems.map((subItem, subIndex) => (
                            <div
                              key={subIndex}
                              className={location.pathname === `/vtour/${subItem.slug}` ? "active-menu" : "inactive"}
                            >
                              {renderMenuItem(subItem)}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">IKNS Community</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>Committees</strong></Link></li>
                </ol>
                {/* <h4 className="mt-3 page-title">Committees</h4> */}
              </div>
            </div>


            <p className="mt-5 text-center">This Website is Under Construction. Come Back Soon.</p>

            {/* <p>Finance Committee</p>
              <p>Technical Committee</p>
              <p>Education Committee</p> */}


          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}


