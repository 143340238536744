import React, { useEffect, useState } from 'react';
import { fetchImages } from './googleDriveApi.js';

const masonryStyles = {
  columnCount: 3,
  columnGap: '4px',
  padding: '16px',
};

const masonryItemStyles = {
  display: 'inline-block',
  width: '100%',
  marginBottom: '4px',
};

const imageItemStyles = {
  width: '100%',
  height: 'auto',
  objectFit: 'cover',
  borderRadius: '4px',
  cursor: 'pointer',
  transition: 'transform 0.2s',
};

const modalStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 100000,
};

const modalImageStyles = {
  maxWidth: '90%',
  maxHeight: '90%',
  borderRadius: '8px',
};




/**
 * Fetches images from a specified Google Drive folder.
 * @param {string} googleFolder - The ID of the Google Drive folder to fetch images from.
 * @returns {Promise<Array>} An array of image file objects with accessible URLs.
 */

function Drive({ googleFolder }) {

  console.log(googleFolder);


  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null); // For the large image modal

  useEffect(() => {
    const getImages = async () => {
      try {

        const files = await fetchImages(googleFolder);

        const imageFiles = files.map(file => ({
          id: file.id,
          name: file.name,
          url: `https://drive.google.com/uc?export=view&id=${file.id}`,
        }));
        setImages(imageFiles);
      } catch (err) {
        console.error('Error setting images:', err);
        setError('Failed to load images.');
      } finally {
        setLoading(false);
      }
    };

    getImages();
  }, [googleFolder]);

  const handleImageClick = (image) => {
    setSelectedImage(image); // Open the modal with the selected image
  };

  const closeModal = () => {
    setSelectedImage(null); // Close the modal
  };

  if (loading) {
    return (
      <div className="App">
        <p>Loading images...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="App">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="App">
      <div style={masonryStyles}>
        {images.length > 0 ? (
          images.map(image => (
            <div key={image.id} style={masonryItemStyles}>
              <img
                src={`https://drive.google.com/thumbnail?id=${image.id}&sz=s512`}
                alt={image.name}
                style={imageItemStyles}
                onClick={() => handleImageClick(image)}
              />
            </div>
          ))
        ) : (
          <p>No images found in the folder.</p>
        )}
      </div>

      {/* Modal for large image */}
      {selectedImage && (
        <div style={modalStyles} onClick={closeModal}>
          <img
            src={`https://drive.google.com/thumbnail?id=${selectedImage.id}&sz=s1024`}
            alt={selectedImage.name}
            style={modalImageStyles}
          />
        </div>
      )}
    </div>
  );
}

export default Drive;
