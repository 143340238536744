import { Link } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";

import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const AccreditationAffiliation = () => {
  return (
    <div>
      <Navigation />

      <div id="accreditation_affiliation" className="container-fluid col-md-12 page-header"></div>
      <div id="one-page" className="wrapper">
        <div className="row">
          <div className="col-md-10">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item"><Link to="#">Key Info</Link></li>
              <li className="breadcrumb-item"><Link to="#"><strong>Accreditation & Affiliation</strong></Link></li>
            </ol>
          </div>

          <h4 className="mt-3 page-title text-center">Accreditation & Affiliation</h4>
        </div>


        <div className="row mt-5">
          <div className="col-md-12">
            <div className="row justify-content-center align-self-center">
              <div className="col-md-5">
                <img src={require('../../assets/img/accreditation-affiliation/ib.png')} className="img-fluid" alt='...' />
              </div>

              <h4 className="page-title text-center">International Baccalaureate Organization</h4>
              <p className="mt-3">
                Ibn Khuldoon National School (IKNS) has been affiliated with the International Baccalaureate Organization since September of 1990. The first group of IKNS graduates sat for the IB examinations in May 1992.
                <p className="mt-3">Today, IKNS is an IB Continuum school
                  offering <Link to="/ib-learner-profile" className="text-link fw-normal">three IB programmes</Link> from K-12. The school
                  was accredited to offer the IB PYP programme in 2020 and the IB MYP programme in 2022. To review the 2024 IB Evaluation Report,
                  kindly <a href="https://drive.google.com/file/d/1pVU4JkwB5hmHlKkk9zg5PKUgOZgCw76_/view?usp=sharing" target="_blank" rel="noreferrer" className="text-link fw-normal">click here</a>.</p>
              </p>
            </div>
          </div>
        </div>



        <div className="row g-0 mt-5">
          <div className="col-md-12">
            <div className="row justify-content-center align-self-center">
              <div className="col-md-5">
                <img src={require('../../assets/img/accreditation-affiliation/msa.png')} className="img-fluid" alt='...' />
              </div>

              <h4 className="page-title text-center">The Middle States Association of Colleges and Schools (MSA)</h4>
              <p className="mt-3">
                Ibn Khuldoon National
                School is fully accredited by Middle States Association of Colleges and Schools (MSA)
                since the year 1994, and by the Ministry of Education in Bahrain since its inception
                in 1983.
              </p>

              <ul className="ms-5 text-link">
                <li className="mb-2"><a href="https://drive.google.com/file/d/1PcsZnaIWa0TqI8R-ODAg5t4UzntfO57C/view" target="_blank" rel="noreferrer" className="text-link fw-normal">MSA Certificate of Accreditation 2023-2030</a></li>
                <li className="mb-2"><a href="https://drive.google.com/file/d/1Ib-Bt33kFWLbFZ6QIRgqRHCwHiXhhfUd/view" target="_blank" rel="noreferrer" className="text-link fw-normal">MSA Standard Self Assessment & Visiting Team Report, May 2023</a></li>
                <li className="mb-2"><a href="https://drive.google.com/file/d/1lCz5KZpLaT8ub6T5iReTnRXWUzb8_8oQ/view" target="_blank" rel="noreferrer" className="text-link fw-normal">Sustaining Excellence phase II (a) School-Wide Action Research Design and Action Plans</a></li>
                <li className="mb-2"><a href="https://drive.google.com/file/d/169Kb3Vwco2PCtrNwnMkXdXgEzI2ZYdRY/view" target="_blank" rel="noreferrer" className="text-link fw-normal">Application and School-Wide Action Research Proposal Approval</a></li>
                <li className="mb-2"><a href="https://drive.google.com/file/d/1d4owhsgxxoDUm0Hq8eLRgGfBnx8h_l8D/view" target="_blank" rel="noreferrer" className="text-link fw-normal">Application and School-Wide Action Research Proposal</a></li>

                <li className="mb-2"><a href="https://drive.google.com/file/d/1ExgptQ_YNOI9o1lvcmf4Ze5hYTjljyXm/view" target="_blank" rel="noreferrer" className="text-link fw-normal">MSA Accreditation Award Letter, 2016</a></li>
                <li className="mb-2"><a href="https://drive.google.com/file/d/1RxpWQRILsrq0B6utWsRm8DTP5EQIPJSt/view" target="_blank" rel="noreferrer" className="text-link fw-normal">MSA Accreditation Team Report, 2016</a></li>
              </ul>
            </div>
          </div>
        </div>







        {/* <div className="row mt-5">
          <div className="col-md-12">
            <div className="row justify-content-center align-self-center">
              <div className="col-md-5">
                <img src={require('../../assets/img/accreditation-affiliation/maiba.png')} className="img-fluid" alt='...' />
              </div>

              <h4 className="page-title text-center">Middle East IB Association (MEIBA)</h4>
              <p className="mt-3">
                Ibn Khuldoon National School has been involved in founding MEIBA during
                the 1994-95 academic year and has been affiliated with it since then. MEIBA provides a
                forum for the IB schools of the Middle East to meet and discuss matter of common
                interest and communicates with the office of the Regional Director of the IBAEM
                (Africa/Europe/Middle East).
              </p>
            </div>
          </div>
        </div> */}



        <div className="row mt-5">
          <div className="col-md-12">
            <div className="row justify-content-center align-self-center">
              <div className="col-md-4">
                <img src={require('../../assets/img/accreditation-affiliation/moe.png')} className="img-fluid" alt='...' />
              </div>

              <h4 className="page-title text-center">Ministry of Education (MOE)</h4>
              <p className="mt-3">
                IKNS follows the leadership and guidance of the Ministry of Education (MoE). Being a national private school, the school aims to continually meet the requirements of MoE. The school currently uses the Arabic, National, Social, and Islamic studies books prescribed by the Ministry from K-12.
              </p>
            </div>
          </div>
        </div>



        <div className="row mt-5">
          <div className="col-md-12">
            <div className="row justify-content-center align-self-center">
              <div className="col-md-6 mx-auto text-center">
                <img src={require('../../assets/img/accreditation-affiliation/bqa.png')} className="img-fluid" alt='...' />
              </div>

              <h4 className="page-title text-center">Education & Training Quality Authority (BQA)</h4>

              <div className="col-md-7 mx-auto">
                <ul className="ms-5 text-link">
                  <li className="mb-2"><a href="https://drive.google.com/file/d/1GQbWqWs_mgxjrsHo6_Fz8FuoyrgxcBqn/view" target="_blank" rel="noreferrer" className="text-link fw-normal">Third Cycle Report, July 2024 <span className="d-block text-xs">Self-Evaluation Form and Pre-Review Brief</span></a></li>
                  <li className="mb-2"><a href="https://drive.google.com/file/d/1t6O7dGk6QokiUjOtSv01KeJKuiXgZsRA/view" target="_blank" rel="noreferrer" className="text-link fw-normal">Second Cycle Report, March 2017</a></li>
                  <li className="mb-2"><a href="https://drive.google.com/file/d/179omjtyUSfAKstUFoY21W6BGq3ucOQZ1/view" target="_blank" rel="noreferrer" className="text-link fw-normal">First Cycle Report, September 2013</a></li>
                </ul>
              </div>

            </div>
          </div>
        </div>






        <div className="contact-section col-lg-9 mx-auto mt-5">
          <h4 className="mt-3 page-title">Contact Us</h4>
          <p>If you have questions about the Accreditation & Affiliation, we are here to help.</p>
          <Link to="/contact-us" className="text-link">Go to contact form <i className="fas fa-arrow-right text-right"></i></Link>
        </div>
      </div>
      <Footer />
    </div>
  )
}


