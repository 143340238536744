import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/external-exam-results';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const Rbt = () => {

  const location = useLocation();

  return (
    <div>
      <Navigation />
      <div id="external_exam_results" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="d-block d-md-none">
              <select
                className="form-select"
                onChange={(e) => window.location.href = e.target.value}
                value={location.pathname}
              >
                {sidebarLinks.map((link, index) => (
                  <option key={index} value={link.path}>
                    {link.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                  <li className="breadcrumb-item"><Link to="#">External Exam Results</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>RBT</strong></Link></li>
                </ol>
                <h4 className="mt-3 page-title">RBT</h4>
              </div>
            </div>

            <p>
              IKNS introduced the Reading Benchmark Test (RBT), an Arabic standardized assessment designed to evaluate reading levels based on Arabic language standards. The RBT accurately places students at their appropriate reading levels and tracks their progress over time.
            </p>

            <p>
              During the 2023-2024 academic year, students in Grades 4 and 9 took the RBT twice. In the 2024-2025 academic year, students in Grades 4, 5, and 9 will participate in the assessment.
            </p>



            <p className="content-title mt-5">
              2023-2024 RBT Results
            </p>


            <div className="col-md-12">
              <img src={require('../../assets/img/external-exams-results/rtb.png')} className="img-fluid rounded" alt='...' />
            </div>



          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}


