import { Link } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const TheStudentTeacherHandbook = () => {

  return (
    <div>

      <Navigation />
      <div id="student_teacher_handbook" className="container-fluid col-md-12 page-header"></div>
      <div id="one-page" className="wrapper">
        <div className="row">
          <div className="col-md-10">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item"><Link to="#">Key Info</Link></li>
              <li className="breadcrumb-item"><Link to="#"><strong>Student & Parent Handbook</strong></Link></li>
            </ol>
          </div>
        </div>


        <div className="row g-0">
          <div className="col-md-12 col-lg-6">
            <img src={require('../../assets/img/the_student_teacher_handbook/cover.png')} className="w-100 h-100 img-fluid" alt='...' />
          </div>
          <div className="col-md-12 col-lg-6 bg-gray p-3">
            <div className="container d-flex h-100">
              <div className="row justify-content-center align-self-center">
                <h4 className="mt-3 page-title">Student & Parent Handbook</h4>
                <p className="mt-3">
                  The Student/Parent Handbook has been developed to provide you with general information
                  regarding the IKNS&#39;s procedures and guidelines. Parents are encouraged to download
                  and save this interactive PDF as a quick reference guide to answer any questions they may have.
                </p>

                <a href="https://drive.google.com/file/d/1GQHnvBeykgBFBqx8i6JSQ5Y5v7vLWvOz/view?usp=sharing" className="text-link" target="_blank" rel="noreferrer">
                  Ibn Khuldoon National School An IB Continuum School Student & Parent Handbook</a>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-section col-lg-9 mx-auto">
          <h4 className="mt-3 page-title">Contact Us</h4>
          <p>If you have questions about the Student & Parent Handbook, we are here to help.</p>
          <Link to="/contact-us" className="text-link">Go to contact form <i className="fas fa-arrow-right text-right"></i></Link>
        </div>

      </div>
      <Footer />
    </div>
  )
}


