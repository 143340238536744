import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/recruitment';
import "../../styles/innerpage.css";
import '../../styles/headers.css';

export const Recruitment = () => {
  const location = useLocation();

  return (
    <div>
      <Navigation />

      <div id="recruitment" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>

              <div className="vertical-menu d-none d-md-block">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="d-block d-md-none">
              <select
                className="form-select"
                onChange={(e) => window.location.href = e.target.value}
                value={location.pathname}
              >
                {sidebarLinks.map((link, index) => (
                  <option key={index} value={link.path}>
                    {link.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>Recruitment</strong></Link></li>
                </ol>
                <h4 className="mt-3 page-title">Join our Faculty</h4>
              </div>
            </div>




            <div className="col-md-12">
              <p>Do you want to share your IB Primary Years Programme (PYP) teaching experience or learn about the IB PYP in a school that is dedicated to offering the best educational programmes and services?</p>
              <p>Do you want to share your IB Middle Years Programme (MYP) teaching experience or learn about the IB MYP in a school that is dedicated to offering the best educational programmes and services?</p>
              <p>Do you want to share your IB Diploma Programme (DP) teaching experience or learn about the IB DP in a school that is dedicated to offering the best educational programmes and services?</p>
              <p>Do you want to share your High School teaching experience in a school that is dedicated to offering the best educational programmes and services?</p>
              <p>Ibn Khuldoon National School (IKNS) is a non-profit, self-supporting, coeducational institution that is dedicated to providing high quality education for over 1677 local and expatriate students.</p>
              <p>IKNS offers instructional programmes that are based on the International Baccalaureate curricula, enhanced by Bahrain&#39;s national curricula in Arabic Language, Islamic Studies and Social and National Studies. The school is licensed by the Bahrain Ministry of Education to offer a bilingual programme of study for students from Kindergarten to Grade 12.</p>
              <p>The school received its first accreditation from the Middle States Association Commissions for Elementary and Secondary Schools (MSA) in 1994, and it continues to be in good standing with the association.</p>
              <p>Students in Grades 11 and 12 study the IB Diploma Programme (IBDP) or the American High School Diploma (AHSD), as two parallel programmes. Both the IBDP and the AHSD are tertiary education preparatory programmes, and are considered to be equivalent to the Bahraini General Certificate of Education.</p>
              <p>The School has been affiliated with the International Baccalaureate since 1990, as a Diploma Programme provider. The first group of students sat for the IBDP examinations in 1992.</p>
              <p>In July 2018, IKNS became an International Baccalaureate Primary Years Programme (IB PYP) candidate school, and started offering the programme in September 2018 for all students in KG1 up to Grade 5.</p>
              <p>In July 2019, IKNS also became an International Baccalaureate Middle Years Programme (IB MYP) candidate school, and started offering the programme starting September 2019. The IB MYP is for students in Grade 6 up to Grade 10.</p>

              <p>A diverse faculty, whether teaching in Arabic or English, provides a rich and supportive learning environment for students. There are 150 Teachers from K1 to G12, and over 30 Teaching Assistants serving in the Lower Elementary Section (KG1 - G2). The faculty is supported by a dedicated team of over 70 administrative, IT and support staff.</p>
              <p>About a third of the faculty are Bahrainis, a third come from neighboring Arab countries and a third come from the English speaking world, including Canada, Ireland, South Africa, UK and USA. Most of the Teaching Assistants are Bahrainis.</p>


              <p className="content-title mt-5">Outstanding Rating</p>
              <p>IKNS received an &&#34;Outstanding&&#34; rating by the Directorate of Private Schools & Kindergartens Reviews, which is a part of the Education & Training Quality Authority (BQA) in both the first and second review cycles that were conducted in March 2014 and October 2017 respectively. During both reviews, &&#34;reviewers observed lessons and other activities, scrutinised students&#39; written works and analysed school&#39;s performance data and other documents. They met with staff members, students and parents.</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
