import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/american-highschool-diploma';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const CoursesOfferedAhsd = () => {

	const location = useLocation();

	return (
		<div>
			<Navigation />
			<div id="ahsd" className="container-fluid col-md-12 page-header"></div>

			<div className="wrapper">
				<div className="row">
					<div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
						<div className="sticky-top">
							<h4 className="mt-3 quick-links">In this Section</h4>
							<div className="vertical-menu">
								{sidebarLinks.map((link, index) => {
									return (
										<div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
											<Link className="text-sm" to={link.path}>
												{link.label}
											</Link>
										</div>
									)
								})}
							</div>
						</div>
					</div>

					<div id="content" className="col-md-8 col-lg-9">
						<div className="d-block d-md-none">
							<select
								className="form-select"
								onChange={(e) => window.location.href = e.target.value}
								value={location.pathname}
							>
								{sidebarLinks.map((link, index) => (
									<option key={index} value={link.path}>
										{link.label}
									</option>
								))}
							</select>
						</div>
						<div className="row">
							<div className="col-md-10">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><Link to="/">Home</Link></li>
									<li className="breadcrumb-item"><Link to="#">Academics</Link></li>
									<li className="breadcrumb-item"><Link to="#">American High School Diploma (G11-G12)</Link></li>
									<li className="breadcrumb-item"><Link to="#"><strong>Courses Offered in the AHSD Programme</strong></Link></li>
								</ol>
								<h4 className="mt-3 page-title">Courses Offered in the AHSD Programme</h4>
							</div>
						</div>



						<img src={require('../../assets/img/ahsd/CoursesofferedintheAHSD-programme.png')} className="rounded img-fluid" alt='pyp' />

						<p>
							IKNS offers a wide range of courses for students enrolled in the AHSD programme.
							The table below is an example of AHSD programme courses offered in one school
							year.
						</p>


						<table className="table table-bordered">
							<thead>
								<tr>
									<th scope="col"><strong>Subject</strong></th>
									<th scope="col"><strong>Course</strong></th>
									<th scope="col" colspan="2" className="text-center"><strong>Grade level</strong></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="align-middle">Arabic</td>
									<td>
										Arabic, High School<br />
										Arabic Core Level, High School
									</td>
									<td>
										G11<br />
										G11
									</td>
									<td>
										G12<br />
										G12
									</td>
								</tr>

								<tr>
									<td className="align-middle">English</td>
									<td>
										IB English Language and Literature, HL & SL<br />
										IB English B, HL & SL<br />
										English, Core Level
									</td>
									<td>
										G11<br />
										G11<br />
										G11
									</td>
									<td>
										G12<br />
										G12<br />
										G12
									</td>
								</tr>

								<tr>
									<td className="align-middle">Social Studies</td>
									<td>
										History, High School<br />
										Business Organization & Marketing, High School<br />
										Accounting & Management, High School<br />
										Microeconomics, High School<br />
										Macroeconomics, High School<br />
										Psychology, High School<br />
										Global Issues, High School <br />
										IB Economics, HL & SL<br />
										IB History, SL<br />
										IB Business & Management, HL
									</td>
									<td>
										G11<br />
										G11<br />
										-<br />
										G11/12<br />
										G11/12<br />
										-<br />
										-<br />
										G11<br />
										G11<br />
										G11
									</td>
									<td>
										-<br />
										-<br />
										G12<br />
										G11/12<br />
										G11/12<br />
										G12<br />
										G12<br />
										G12<br />
										G12<br />
										G12
									</td>
								</tr>



								<tr>
									<td className="align-middle">Science</td>
									<td>
										Biology, High School<br />
										Chemistry, High School<br />
										Physics, High School<br />
										Forensic Science, Core Level<br />
										Environmental Studies, High School<br />
										IB Biology, HL & SL<br />
										IB Chemistry, HL & SL<br />
										IB Physics, HL & SL
									</td>
									<td>
										G11/12<br />
										G11/12<br />
										G11/12<br />
										G11/12<br />
										G11/12<br />
										G11<br />
										G11<br />
										G11
									</td>
									<td>
										G11/12<br />
										G11/12<br />
										G11/12<br />
										G11/12<br />
										G11/12<br />
										G12<br />
										G12<br />
										G12
									</td>
								</tr>

								<tr>
									<td className="align-middle">Mathematics</td>
									<td>
										Mathematics, High School<br />
										Mathematics, Core Level<br />
										IB Mathematics Applications & Interpretation, SL
									</td>
									<td>
										G11<br />
										G11<br />
										G11
									</td>
									<td>
										G12<br />
										G12<br />
										G12
									</td>
								</tr>


								<tr>
									<td className="align-middle">The Arts</td>
									<td>
										Art, High School<br />
										Pottery, High School<br />
										Art Sculpture, High School<br />
										IB Visual Arts HL & SL
									</td>
									<td>
										G11/12<br />
										G11/12<br />
										-<br />
										G11
									</td>
									<td>
										G11/12<br />
										G11/12<br />
										G12<br />
										G12
									</td>
								</tr>

							</tbody>
						</table>


					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}


