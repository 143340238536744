import { Link } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const PostResponsibility = () => {

  return (
    <div>
      <Navigation />

      <div id="post_responsibility" className="container-fluid col-md-12 page-header"></div>
      <div id="one-page" className="wrapper">
        <div className="row">
          <div className="col-md-10">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item"><Link to="#">Key Info</Link></li>
              <li className="breadcrumb-item"><Link to="#"><strong>Post Responsibility</strong></Link></li>
            </ol>
          </div>
        </div>

        <div className="row g-0">
          <div className="col-md-12 col-lg-6">
            <img src={require('../../assets/img/post-responsibility/prImage.jpg')} className="w-100 h-100 img-fluid" alt='...' />
          </div>
          <div className="col-md-12 col-lg-6 bg-gray p-3">
            <div className="container d-flex h-100">
              <div className="row justify-content-center align-self-center">
                <h4 className="mt-3 page-title">Post Responsibility</h4>
                <p className="mt-3">
                  School posts or positions refer to various roles within a school community that
                  have specific responsibilities and duties. These roles can include administrative
                  positions such as principal, assistant principal, and department heads, as well as
                  teaching positions such as classroom teachers and subject area specialists.
                </p>

                <p><a href="https://drive.google.com/file/d/11xGx-WRE91xuU56P1WTSyQ-TN6-xc9v4/view" className="text-link" target="_blank" rel="noreferrer">
                  View Posts of Responsibility List</a></p>


              </div>
            </div>
          </div>
        </div>

        <div className="contact-section col-lg-9 mx-auto">
          <h4 className="mt-3 page-title">Contact Us</h4>
          <p>If you have questions about the Post Responsibility, we are here to help.</p>
          <Link to="/contact-us" className="text-link">Go to contact form <i className="fas fa-arrow-right text-right"></i></Link>
        </div>

      </div>
      <Footer />
    </div>
  )
}


