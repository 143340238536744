import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";
import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const SchoolCalendar = () => {

  return (
    <div>
      <Navigation />
      <div id="school_calendar" className="container-fluid col-md-12 page-header"></div>
      <div id="one-page" className="wrapper">
        <div className="row">
          <div className="col-md-10">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item"><Link to="#">Key Info</Link></li>
              <li className="breadcrumb-item"><Link to="#"><strong>School Calendar</strong></Link></li>
            </ol>
            <h4 className="mt-3 page-title">School Calendar</h4>
          </div>
        </div>

        <p>Our comprehensive calendar is thoughtfully crafted to keep students, parents, and staff informed about all key dates, events, and activities throughout the academic year.</p>
        <p>At Ibn Khuldooon National School, we recognize the importance of effective planning and organization. Our calendar is more than just a schedule—it&#39;s a vital tool designed to help our community stay connected and engaged. Whether you&#39;re tracking important academic deadlines, special events, extracurricular activities, or school-wide celebrations, our calendar ensures you never miss a moment in our vibrant school life.</p>
        <p>Stay organized, stay involved, and make the most of the exciting opportunities our school has to offer with the Ibn Khuldooon National School Calendar.</p>

        <a target="_blank" rel="noreferrer" className="d-block mb-1 text-link" href="https://drive.google.com/file/d/1h747pVIbjKrFyabWr2iap8onHV0N8SFH/view">Ibn Khuldoon National School School Calendar 2025 - 2026</a>
        <a target="_blank" rel="noreferrer" className="d-block mb-1 text-link" href="https://drive.google.com/file/d/186kmr-prNO7x4sZ6FQ3asGBaXwm_lkRs/view">Ibn Khuldoon National School School Calendar 2024 - 2025</a>

        <div className="contact-section col-lg-9 mx-auto mt-5">
          <h4 className="mt-3 page-title">Contact Us</h4>
          <p>If you have questions about the  School Calendar, we are here to help.</p>
          <Link to="/contact-us" className="text-link">Go to contact form <i className="fas fa-arrow-right text-right"></i></Link>
        </div>
      </div>
      <Footer />
    </div>
  )
}


