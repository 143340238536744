import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import '../../styles/headers.css';
import '../../styles/innerpage.css';
import sidebarLinks from '../../components/sidebar/admission';

export const AdmissionProcedureAndApplicationForm = () => {

  const location = useLocation();

  return (
    <div>
      <Navigation />
      <div id="admission" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">

        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      {link.target ? (
                        <a className="text-sm" href={link.path} target={link.target}>
                          {link.label}
                        </a>
                      ) : (
                        <Link className="text-sm" to={link.path}>
                          {link.label}
                        </Link>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="d-block d-md-none">
              <select
                className="form-select"
                onChange={(e) => window.location.href = e.target.value}
                value={location.pathname}
              >
                {sidebarLinks.map((link, index) => (
                  <option key={index} value={link.path}>
                    {link.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="/">Admission</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>Admission Procedure & Application Form</strong></Link></li>
                </ol>
              </div>
            </div>

            <h4 className="mt-3 page-title">Admission Procedure & Application Form</h4>
            <div className="col-md-12 mx-auto">

              <p>IKNS accepts applications for new students in November yearly. The application process opens to grade levels from KG1 to Grade 11 upon availability of seats.</p>
              <p>Parents who are interested in applying for their child to join IKNS can fill the Application Form available electronically at the end of this section. Please make sure to read through the full procedure before you start filling the form.</p>
              <p>Some grade levels have waiting lists while others are closed. The school apologizes for not being able to accept applications for grade levels that are closed. In the case where a grade level is closed, the Application Form will be deactivated. The school accepts applications only for grade levels that have waiting lists. The school does not charge parents for submitting an Application Form.</p>

              <p>Once a parent submits an Application Form, the school administration will review it, including the documents submitted. Based on the number of applications being submitted for admission and the number of available seats in the requested grade level, the Admission Office will send an SMS/email invitation to the parent, advising him/her to proceed to pay the non-refundable Application Processing Fee.</p>
              <p>Please note that payment of the Application Processing Fee means that the school accepted the Application Form. Your child is still to be invited to sit for the entrance interview and /or entrance examinations to qualify to be offered a seat in the requested grade level.</p>

              <p><strong>Kindly note the following:</strong></p>

              <ul>
                <li style={{ marginBottom: '10px' }}>The Application Processing Fee is non-refundable.</li>
                <li style={{ marginBottom: '10px' }}>The Application Processing Fee is to be paid ONLY upon the receipt of the parent of an invitation SMS/email from the school, directing him/her to pay the requested fee.</li>
                <li style={{ marginBottom: '10px' }}>The school takes no responsibility for contacting the parent who paid the non-refundable Application Processing Fee without receiving an invitation SMS/email from the school advising them to do so.</li>
                <li style={{ marginBottom: '10px' }}>Application Forms that do not meet the grade/age level requirements and those with missing documents will be automatically disqualified.</li>
                <li style={{ marginBottom: '10px' }}>The Application Processing Fee is not an admission offer.</li>
              </ul>

              <p>The parents who receive an invitation SMS/email and pay the Application Processing Fee within the allocated time frame, will be contacted by the Admission Office inviting their child to move to the entrance interview and/or the entrance examinations stage.</p>

              <p>The students applying for KG1 will be doing an entrance interview, and the students applying to KG2 to Grade 11 will be asked to sit for entrance examinations, followed by interviews. The length of each exam per grade level is shown in the table below.</p>



              <table className="table table-bordered text-center">
                <thead>
                  <tr>
                    <th style={{ background: '#2b2729', color: '#fff' }} className="p-3">Grades</th>
                    <th style={{ background: '#2b2729', color: '#fff' }} className="p-3">Entrance Exams / Interviews</th>
                    <th style={{ background: '#2b2729', color: '#fff' }} className="p-3">Duration</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>KG1</td>
                    <td>Interview by appointment</td>
                    <td>15 minutes</td>
                  </tr>

                  <tr>
                    <td>KG 11</td>
                    <td>Arabic, English, and Mathematics</td>
                    <td>15 minutes per subject</td>
                  </tr>

                  <tr>
                    <td>Grades 1 - 3</td>
                    <td>Arabic, English, and Mathematics</td>
                    <td>35-45 minutes per subject</td>
                  </tr>

                  <tr>
                    <td>Grades 4 - 5</td>
                    <td>Arabic, English, and Mathematics</td>
                    <td>45-55 minutes per subject</td>
                  </tr>

                  <tr>
                    <td>Grades 6 - 9</td>
                    <td>Arabic, English, and Mathematics</td>
                    <td>90 minutes per subject</td>
                  </tr>

                  <tr>
                    <td>Grades 10 - 11</td>
                    <td>Arabic, English, Mathematics, and Science</td>
                    <td>90 minutes per subject</td>
                  </tr>
                </tbody>
              </table>

              <p className="mt-5"><strong>What happens after the entrance interview/examinations</strong></p>
              <p>The students who sat for the entrance interview and/or entrance examinations will be placed on a waiting list for the grade level they applied for. Based on the performance of the student and number of seats available in the sought grade level, the Admission Office will send an SMS/email to the parent regarding the status of the application. The SMS/email will inform the parent if his/her child has been offered a seat or not.</p>

              <p>Students who are offered seats, may then proceed to the next step in the admission procedure as informed by the Admission Office.</p>

              <p className="mt-5"><strong>Late Registration</strong></p>
              <p>Depending on the availability of seats in any grade levels, admission will continue to be open in that grade level till early June. The standard Admission Procedure will remain in effect.</p>

              <p className="mt-5"><strong>To Register your interest, please fill</strong></p>



              <p><a href="https://docs.google.com/forms/d/e/1FAIpQLSe2hXFTUgAWS-6rpe8kPVfIeHYCCIgL3XVKNI5F1AqVSJPcEg/viewform?usp=sf_link" target="_blank" rel="noreferrer" className="text-link">KG1 Application Form <i className="fas fa-arrow-right text-right"></i></a></p>
              <p><a href="https://forms.gle/q3qXCrYVugLQdxPv9" target="_blank" rel="noreferrer" className="text-link">KG2 to Grade 11 Application Form <i className="fas fa-arrow-right text-right"></i></a></p>



            </div>
          </div>
        </div>

        <div className="contact-section col-lg-9 mx-auto">
          <h4 className="mt-3 page-title">Contact Us</h4>
          <p>If you have questions about the School Admission, we are here to help.</p>
          <Link to="/contact-us" className="text-link">Go to contact form <i className="fas fa-arrow-right text-right"></i></Link>
        </div>

      </div>
      <Footer />
    </div>
  )
}


