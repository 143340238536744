import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/college-placement-career-counseling';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const CollegePlacementAndCareerCounseling = () => {

  const location = useLocation();

  return (
    <div>
      <Navigation />
      <div id="college_placement_and_career_counseling" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>College Placement and Career Counseling</strong></Link></li>
                </ol>
                <h4 className="mt-3 page-title">College Placement and Career Counseling</h4>
              </div>
            </div>

            <p>
              Grade 12 students have a semester course of college placement and career
              guidance. During this course students research university courses, their
              entry requirements and application deadlines. They also prepare personal
              statements, complete and submit letters of application forms and work on
              portfolios (if required).
            </p>

            <p>
              The College Counselor holds two College Nights every year where
              parents and students are informed about the requirements and
              application processes to universities in different countries.
              These events are open to Grades 10, 11 and 12. Parents and
              students can also arrange a meeting with the College Counselor
              at any time.
            </p>

            <p>
              Career Day is held every year where IKNS alumni and other members of
              the community working in different fields visit the school and
              speak to interested Grade 11 and 12 students about their university
              studies and professions, and answer the students' questions.
            </p>

            <p>
              For students in Grades 9 and 10, Career Day involves visiting an
              institution to see the workplace first hand. Examples of institutions
              that students visit are law firms, banks, hospitals, architecture
              firms, marketing agencies, government offices, and telecoms.
            </p>

            <p>
              University representatives from several universities around the
              world visit IKNS every year to meet with interested students,
              and several college fairs are held at school.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}


