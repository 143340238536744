export const SidebarLinks = [

  {
    label: "First Day of School",
    path: "/photo-gallery"
  },
  {
    label: "Facilities",
    path: "/facilities-photo"
  },
  {
    label: "IKNS Carnival",
    path: "/carnival-photos"
  },
  {
    label: "IKMUN",
    path: "/ikmun-photos"
  },
  {
    label: "IKNS DAY",
    path: "/ikns-day-photos"
  },

  {
    label: "Performing Arts",
    path: "/performing-arts-photos"
  },

  {
    label: "Trips Abroad",
    path: "/trips-abroad-photos"
  },

  {
    label: "School Life",
    path: "/school-life-photos"
  },
  {
    label: "Bahrain National Day",
    path: "/bahrain-national-day-photos"
  },

  {
    label: "IKNS Virtual Tour",
    path: "/vtour/start"
  },

];

export default SidebarLinks;
