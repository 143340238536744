import "../../styles/Brands.css";

const brandLinks = [
  { href: "https://www.ikns.edu.bh/School_PYP_DP_Multi-Prog_PSP2020_Eva_%20Visit_report.pdf", src: require('../../assets/logo/ib_report.png'), alt: "IB Main" },
  { href: "https://www.ibo.org/globalassets/new-structure/brochures-and-infographics/pdfs/ib-continuum-brochure-en.pdf", src: require('../../assets/logo/ib_continuum3.png'), alt: "IB Continuum Brochure" },
  { href: "https://www.ibo.org/programmes/primary-years-programme/", src: require('../../assets/logo/ib_pyp.png'), alt: "IB PYP" },
  { href: "https://www.ibo.org/programmes/middle-years-programme", src: require('../../assets/logo/ib_myp.png'), alt: "IB MYP" },
  { href: "https://www.ibo.org/programmes/diploma-programme", src: require('../../assets/logo/ib_dp.png'), alt: "IB DP" },
];

const BrandLink = ({ href, src, alt }) => (
  <a href={href} target="_blank" rel="noreferrer">
    <img className="brands d-inline-block" alt={alt} src={src} />
  </a>
);

export const Brands = () => {
  return (
    <div className="container mb-5 mt-5 text-center">
      <div className="row">
        <div className="col">
          {brandLinks.map((link, index) => (
            <BrandLink key={index} {...link} />
          ))}
        </div>
      </div>
    </div>

  )
}