import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
// import sidebarLinks from '../../components/sidebar/student-life';
import '../../styles/headers.css';
import '../../styles/innerpage.css';
import React, { useState, useEffect } from "react";
import menuSidebar from './menu.json';




export const Injaz = () => {

  const location = useLocation();
  const [sidebar, setSidebar] = useState({ sideMenu: [] });
  const [expandedItems, setExpandedItems] = useState({});

  useEffect(() => {
    setSidebar(menuSidebar);
  }, []);

  const toggleExpand = (index) => {
    setExpandedItems(prev => {
      const newState = {};
      // Collapse all items
      Object.keys(prev).forEach(key => {
        newState[key] = false;
      });
      // Toggle the clicked item
      newState[index] = !prev[index];
      return newState;
    });
  };

  // Helper function to render menu item
  const renderMenuItem = (item) => {
    // For items with '#' slug
    if (item.slug === '#') {
      return (
        <Link to="#" className="text-sm">
          {item.title}
        </Link>
      );
    }

    // For external links (starting with http/https)
    if (item.slug.startsWith('http')) {
      return (
        <a
          href={item.slug}
          className="text-sm"
          target={item.target || '_self'}
          rel={item.target === '_blank' ? "noopener noreferrer" : undefined}
        >
          {item.title}
        </a>
      );
    }

    // For internal links
    return (
      <Link to={`/${item.slug}`} className="text-sm">
        {item.title}
      </Link>
    );
  };


  const injaz = [
    { year: 2016, company: "Roshena", award: "Company of the Year Award - Injaz Bahrain" },
    { year: 2016, company: "Roshena", award: "Company of the Year Award - Injaz AlArab" },
    { year: 2017, company: "Machla", award: "Company of the Year Award - Injaz Bahrain" },
    { year: 2018, company: "Masque", award: "Product of the Year Award - Injaz Bahrain" },
    { year: 2019, company: "Magtag", award: "Company of the Year Award - Injaz Bahrain" },
    { year: 2020, company: "Sahim", award: "Company of the Year Award - Injaz Bahrain" },
    { year: 2020, company: "Sahim", award: "Product of the Year Award - Injaz AlArab" },
    { year: 2022, company: "Plantmate", award: "-" },
    { year: 2022, company: "Salamtek", award: "Company of the Year Award - Injaz Bahrain" },
    { year: 2022, company: "Salamtek", award: "Product of the Year Award - Injaz AlArab" },
    { year: 2023, company: "Sheela", award: "-" },
    { year: 2023, company: "Numu", award: "Best Social Impact Award + Khaleeji's Rising CEO Award" },
    { year: 2024, company: "Istamr", award: "Product of the Year Award - Injaz Bahrain" },
  ];



  return (
    <div>
      <Navigation />
      <div id="student_life" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {/* Desktop view */}
                <div className="d-none d-md-block">
                  {sidebar.sideMenu.map((link, index) => (
                    <div key={index}>
                      <div
                        className={location.pathname === `/${link.slug}` ? "active-menu" : "inactive"}
                        onClick={() => toggleExpand(index)}
                        style={{ cursor: 'pointer' }}
                      >
                        {renderMenuItem(link)}
                      </div>
                      {link.subItems && expandedItems[index] && (
                        <div className="sub-menu ms-3">
                          {link.subItems.map((subItem, subIndex) => (
                            <div
                              key={subIndex}
                              className={location.pathname === `/vtour/${subItem.slug}` ? "active-menu" : "inactive"}
                            >
                              {renderMenuItem(subItem)}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">IKNS Community</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Students' Life</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>Injaz</strong></Link></li>
                </ol>

              </div>
            </div>

            <h4 className="mt-3 page-title text-center">Injaz Bahrain</h4>


            <div className="img-left col-md-5">
              <img src={require('../../assets/img/student_life/Injaz.jpg')} className="img-fluid rounded" alt='...' />
            </div>
            <p>Every year, a team of students take part in Injaz Bahrain. Injaz Bahrain is a non-profit organization that was established in 2005 as part of Junior Achievement Worldwide with the aim of empowering young people to own their economic success and be prepared for today's business challenges. Injaz Bahrain delivers hands on, immersive learning in work readiness, financial literacy, entrepreneurship, sustainability, STEM, digital literacy, and more. The company that wins Injaz Bahrain later gets the chance to participate in Injaz Al Arab. </p>

            <div className="col-md-12">
              <table className="table table-bordered" id="table2">

                <thead>
                  <tr>
                    <th style={{ backgroundColor: '#2b2729', color: '#fafafa', border: '1px solid #000' }} className="col-md-1 p-3">Year</th>
                    <th style={{ backgroundColor: '#2b2729', color: '#fafafa', border: '1px solid #000' }} className="col-md-2 p-3">Name&nbsp;of&nbsp;Company</th>
                    <th style={{ backgroundColor: '#2b2729', color: '#fafafa', border: '1px solid #000' }} className="p-3">Award</th>
                  </tr>
                </thead>
                <tbody>
                  {injaz.map((item, index) => (
                    <tr key={index}>
                      <td>{item.year}</td>
                      <td>{item.company}</td>
                      <td>{item.award}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>


          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}


