export const SidebarLinks = [
  {
    label: "School Policies",
    path: "/school-policies"
  },
  // {
  //   label: "Student Laptop Specifications",
  //   path: "/students-device-specifications"
  // }

];

export default SidebarLinks;
