import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import '../../styles/headers.css';
import '../../styles/innerpage.css';
import menuSidebar from './menu.json';


export const StudentCongressPresidentsList = () => {
  const location = useLocation();
  const [sidebar, setSidebar] = useState({ sideMenu: [] });
  const [expandedItems, setExpandedItems] = useState({});

  useEffect(() => {
    setSidebar(menuSidebar);
  }, []);

  const toggleExpand = (index) => {
    setExpandedItems(prev => {
      const newState = {};
      // Collapse all items
      Object.keys(prev).forEach(key => {
        newState[key] = false;
      });
      // Toggle the clicked item
      newState[index] = !prev[index];
      return newState;
    });
  };

  // Helper function to render menu item
  const renderMenuItem = (item) => {
    // For items with '#' slug
    if (item.slug === '#') {
      return (
        <Link to="#" className="text-sm">
          {item.title}
        </Link>
      );
    }

    // For external links (starting with http/https)
    if (item.slug.startsWith('http')) {
      return (
        <a
          href={item.slug}
          className="text-sm"
          target={item.target || '_self'}
          rel={item.target === '_blank' ? "noopener noreferrer" : undefined}
        >
          {item.title}
        </a>
      );
    }

    // For internal links
    return (
      <Link to={`/${item.slug}`} className="text-sm">
        {item.title}
      </Link>
    );
  };




  return (
    <div>
      <Navigation />
      <div id="student_life" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {/* Desktop view */}
                <div className="d-none d-md-block">
                  {sidebar.sideMenu.map((link, index) => (
                    <div key={index}>
                      <div
                        className={location.pathname === `/${link.slug}` ? "active-menu" : "inactive"}
                        onClick={() => toggleExpand(index)}
                        style={{ cursor: 'pointer' }}
                      >
                        {renderMenuItem(link)}
                      </div>
                      {link.subItems && expandedItems[index] && (
                        <div className="sub-menu ms-3">
                          {link.subItems.map((subItem, subIndex) => (
                            <div
                              key={subIndex}
                              className={location.pathname === `/vtour/${subItem.slug}` ? "active-menu" : "inactive"}
                            >
                              {renderMenuItem(subItem)}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">IKNS Community</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Students' Life</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>Student Congress Presidents List</strong></Link></li>
                </ol>
              </div>
            </div>

            <h4 className="mt-4 page-title text-center">Student Congress Presidents List</h4>
            <p className="text-center">IBN KHULDOON NATIONAL SCHOOL</p>

            <div className="col-md-12">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td className="align-middle">Farouk Hadeed</td>
                    <td className="col-md-2 text-center ">1992/1993</td>
                  </tr>

                  <tr>
                    <td className="align-middle">Ola Ayaso</td>
                    <td className="col-md-2 text-center">1993/1994</td>
                  </tr>

                  <tr>
                    <td className="align-middle">Mohamed Darwish AlTattan</td>
                    <td className="col-md-2 text-center">1994/1995</td>
                  </tr>

                  <tr>
                    <td className="align-middle">Mohamed Adel Fakhro</td>
                    <td className="col-md-2 text-center">1995/1996</td>
                  </tr>

                  <tr>
                    <td className="align-middle">Mashaal AlBayat</td>
                    <td className="col-md-2 text-center">1996/1997</td>
                  </tr>

                  <tr>
                    <td className="align-middle">Noor AlHamar</td>
                    <td className="col-md-2 text-center">1997/1998</td>
                  </tr>

                  <tr>
                    <td className="align-middle">Tareq Saleh</td>
                    <td className="col-md-2 text-center">1998/1999</td>
                  </tr>

                  <tr>
                    <td className="align-middle">Mohamed Ahmed Fakhro</td>
                    <td className="col-md-2 text-center">1999/2000</td>
                  </tr>

                  <tr>
                    <td className="align-middle">Reem Ahmadi</td>
                    <td className="col-md-2 text-center">2000/2001</td>
                  </tr>

                  <tr>
                    <td className="align-middle">Tala Saleh</td>
                    <td className="col-md-2 text-center">2001/2002</td>
                  </tr>

                  <tr>
                    <td className="align-middle">Abdulla Rashed Isa</td>
                    <td className="col-md-2 text-center">2002/2003</td>
                  </tr>

                  <tr>
                    <td className="align-middle">Bassam Darwish AlTattan</td>
                    <td className="col-md-2 text-center">2003/2004</td>
                  </tr>

                  <tr>
                    <td className="align-middle">Hassan Mahmoud</td>
                    <td className="col-md-2 text-center">2004/2005</td>
                  </tr>

                  <tr>
                    <td className="align-middle">Aysha AlMoayyed</td>
                    <td className="col-md-2 text-center">2005/2006</td>
                  </tr>

                  <tr>
                    <td className="align-middle">Faisal Rashed Isa</td>
                    <td className="col-md-2 text-center">2006/2007</td>
                  </tr>

                  <tr>
                    <td className="align-middle">Moayyed AlMoayyed</td>
                    <td className="col-md-2 text-center">2007/2008</td>
                  </tr>

                  <tr>
                    <td className="align-middle">Eshaq Eshaq</td>
                    <td className="col-md-2 text-center">2008/2009</td>
                  </tr>

                  <tr>
                    <td className="align-middle">Abdulla Mhamood</td>
                    <td className="col-md-2 text-center">2009/2010</td>
                  </tr>

                  <tr>
                    <td className="align-middle">Hala Hashemi</td>
                    <td className="col-md-2 text-center">2010/2011</td>
                  </tr>

                  <tr>
                    <td className="align-middle">Ahmed Awadhi</td>
                    <td className="col-md-2 text-center">2011/2012</td>
                  </tr>

                  <tr>
                    <td className="align-middle">Yacoub Hamar</td>
                    <td className="col-md-2 text-center">2012/3013</td>
                  </tr>

                  <tr>
                    <td className="align-middle">Fahed AlJaber</td>
                    <td className="col-md-2 text-center">2013/2014</td>
                  </tr>

                  <tr>
                    <td className="align-middle">Alya AlMulla</td>
                    <td className="col-md-2 text-center">2014/2015</td>
                  </tr>

                  <tr>
                    <td className="align-middle">Noor Abdulla</td>
                    <td className="col-md-2 text-center">2015/2016</td>
                  </tr>

                  <tr>
                    <td className="align-middle">Fahad AlKhaja</td>
                    <td className="col-md-2 text-center">2016/2017</td>
                  </tr>


                  <tr>
                    <td className="align-middle">Alia Buachala</td>
                    <td className="col-md-2 text-center">2017/2018</td>
                  </tr>

                  <tr>
                    <td className="align-middle">Yasmeen Nooreddine</td>
                    <td className="col-md-2 text-center">2018/2019</td>
                  </tr>

                  <tr>
                    <td className="align-middle">Lulwa Al Khaja</td>
                    <td className="col-md-2 text-center">2019/2020</td>
                  </tr>

                  <tr>
                    <td className="align-middle">Maya Bseisu</td>
                    <td className="col-md-2 text-center">2020/2021</td>
                  </tr>

                  <tr>
                    <td className="align-middle">Shareefa Kanoo</td>
                    <td className="col-md-2 text-center">2021/2022</td>
                  </tr>


                  <tr>
                    <td className="align-middle">Abdulrahman Rafeea</td>
                    <td className="col-md-2 text-center">2022/2023</td>
                  </tr>

                  <tr>
                    <td className="align-middle">Fahad Almuqla</td>
                    <td className="col-md-2 text-center">2023/2024</td>
                  </tr>



                </tbody>
              </table>
            </div>


          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}


