import { Link } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const StrategicPlan = () => {

  return (
    <div>
      <Navigation />

      <div id="strategic_plan" className="container-fluid col-md-12 page-header"></div>
      <div id="one-page" className="wrapper">
        <div className="row">
          <div className="col-md-10">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item"><Link to="#">Our School</Link></li>
              <li className="breadcrumb-item"><Link to="#"><strong>IKNS Strategic Plan</strong></Link></li>
            </ol>
          </div>
        </div>


        <div className="row g-0">
          <div className="col-md-12 col-lg-6">
            <img src={require('../../assets/img/the_school/ikns.jpg')} className="w-100 h-100 img-fluid" alt='...' />
          </div>
          <div className="col-md-12 col-lg-6 bg-gray p-3">
            <div className="container d-flex h-100">
              <div className="row justify-content-center align-self-center">
                <h4 className="mt-3 page-title">Ibn Khuldoon National School<br /> Strategic Plan</h4>
                <p className="mt-3">
                  The strategic plan is a comprehensive one. Achieving our vision depends on achieving these goals. The four goals are aligned to strategic objectives and implementation initiatives that will drive our thinking and actions in the coming seven years. Ultimately, the achievement of these four goals will serve each IKNS family and the Bahraini community in general by graduating bilingual life-long learners ready for university study and prepared to assume leading roles at the national and international levels.
                </p>

                <a href="https://drive.google.com/file/d/1fGzioedzgVQvBP4TCikdnUKCHlgK_b7R/view?usp=sharing" target="_blank" rel="noreferrer" className="d-block mb-1 mt-2 text-link">IKNS Strategic Plan 2023 - 2030</a>
                <a href="https://drive.google.com/file/d/1laEqLY_18Go5PXIL4UCQeQuXE3VdJEVS/view?usp=sharing" target="_blank" rel="noreferrer" className="d-block mb-1 text-link">IKNS Strategic Plan 2015 - 2022</a>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-section col-lg-9 mx-auto">
          <h4 className="mt-3 page-title">Contact Us</h4>
          <p>If you have questions about the Ibn Khuldoon National School
            Strategic Plan, we are here to help.</p>
          <Link to="/contact-us" className="text-link">Go to contact form <i className="fas fa-arrow-right text-right"></i></Link>
        </div>
      </div>
      <Footer />
    </div>
  )
}


