import { Link, useLocation } from "react-router-dom";
import { useState } from 'react';
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/schoology-powerschool';
import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const SchoologyPowerschool = () => {
  const location = useLocation();

  const [showPopup, setShowPopup] = useState(false);

  return (
    <div>
      <Navigation />

      <div id="schoology_powerschool" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>




          <div id="content" className="col-md-8 col-lg-9">
            <div className="d-block d-md-none">
              <select
                className="form-select"
                onChange={(e) => window.location.href = e.target.value}
                value={location.pathname}
              >
                {sidebarLinks.map((link, index) => (
                  <option key={index} value={link.path}>
                    {link.label}
                  </option>
                ))}
              </select>
            </div>




            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Key Info</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>Schoology/Powerschool Tutorial and Information </strong></Link></li>
                </ol>
                <h4 className="mt-3 page-title">Schoology/Powerschool Tutorial and Information </h4>
              </div>

            </div>

            <p>IKNS uses two main systems for management and communication: Schoology, our Learning Management System (LMS), and Powerschool, Student Information System (SIS).</p>
            <p>Kindly refer to the below links for guidelines on creating accounts and using these systems:</p>

            <p className="fw-bold mt-5">Schoology:</p>
            <ul className="pt-0 mt-0">
              <li>
                <a href="https://uc.powerschool-docs.com/en/schoology/latest/parents" target="_blank" rel="noreferrer" className="fw-normal text-link">
                  Schoology Parents Guide</a>
              </li>
              <li>
                <a href="https://uc.powerschool-docs.com/en/schoology/latest/" target="_blank" rel="noreferrer" className="fw-normal text-link">
                  Schoology Student Guide</a>
              </li>
            </ul>


            <p className="fw-bold mt-5">Powerschool:</p>
            <ul className="pt-0 mt-0">
              <li>
                <a href="https://docs.google.com/document/d/1rqPYH_ipWEBkj_UZzMdYXKBQ2JXl3lVpyAMlill7Bow/edit?tab=t.0" target="_blank" rel="noreferrer" className="fw-normal text-link">
                  Powerschool Parent Guide
                </a>
              </li>
              <li>
                <a href="https://docs.google.com/document/d/1yrDWJoYirxFkUFnFn6OM0L3ZC601Mdiu_evACgpm3Bc/edit?tab=t.0" target="_blank" rel="noreferrer" className="fw-normal text-link">
                  Powerschool Student Guide
                </a>
              </li>
              <li>
                <span onClick={() => setShowPopup(true)} className="fw-normal text-link" style={{ cursor: 'pointer' }}>
                  Finding Students Schedule
                </span>
              </li>
            </ul>




          </div>


        </div>
      </div>
      <Footer />


      {showPopup && (
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '5px',
          boxShadow: '0 0 10px rgba(0,0,0,0.3)',
          zIndex: 100000,
          width: '800px',
          maxWidth: '90%'
        }}>
          <button
            style={{
              position: 'absolute',
              right: '5px',
              top: '0px',
              border: 'none',
              background: 'none',
              fontSize: '30px',
              cursor: 'pointer'
            }}
            onClick={() => setShowPopup(false)}
          >
            ×
          </button>

          <div className="embed-responsive embed-responsive-16by9 p-2">
            <div className="video"
              style={{
                position: "relative",
                paddingBottom: "56.25%" /* 16:9 */,
                paddingTop: 25,
                height: 0,
              }}
            >
              <iframe
                title="Embeded"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                src={`https://www.youtube.com/embed/TvluQfIQB9Y`}
              />
            </div>
          </div>

        </div>
      )}


    </div>
  )
}


