import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/american-highschool-diploma';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const CourseSelectionAhsd = () => {

  const location = useLocation();

  return (
    <div>
      <Navigation />
      <div id="ahsd" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="d-block d-md-none">
              <select
                className="form-select"
                onChange={(e) => window.location.href = e.target.value}
                value={location.pathname}
              >
                {sidebarLinks.map((link, index) => (
                  <option key={index} value={link.path}>
                    {link.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                  <li className="breadcrumb-item"><Link to="#">American High School Diploma (G11-G12)</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>Course Selection</strong></Link></li>
                </ol>
                <h4 className="mt-3 page-title">Course Selection</h4>
              </div>
            </div>



            <div className="img-right col-md-6">
              <img src={require('../../assets/img/ahsd/course_selection.png')} className="rounded img-fluid" alt='pyp' />
            </div>

            <p>
              Students are advised to select their courses based on their interests,
              abilities and skills in different subjects, and the requirements for the
              courses they are considering at the university level. The school publishes a
              list of prerequisites (course and grade required to pursue a course in Grade 11)
              to help students in selecting the courses they qualify for. Students achieving a
              GPA above 2.70 are encouraged to consider enrolling in the IB Diploma Programme.
            </p>

            <p>
              Courses are arranged in six scheduling blocks, one set for students following the
              IB Diploma Programme and another for students selecting the American High School
              Diploma programme. Students select one course from each block. The blocks are
              modified when possible to meet the majority of students' preferences.
            </p>

            <p>
              The College Counselor and IB Coordinator hold several orientation meetings with
              the Grade 10 students in February to discuss all these considerations. Heads of
              Departments also visit Grade 10 classes to explain their departments' subject
              offerings in Grade 11 and answer any questions the students have.
            </p>

          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}


