import React, { useState, useEffect } from "react";
import { useParams, Link, useLocation } from 'react-router-dom';
import axios from "axios";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import * as Sidebar from "../../queries/constants";
import '../../styles/headers.css';
import '../../styles/innerpage.css';
// import YouTube from 'react-youtube'; // If you choose to use react-youtube

export const ViewAllArticles = () => {
  const { slug } = useParams();
  const location = useLocation();
  const [sidebar, setSidebar] = useState({ posts: [] });



  const fetchSidebar = async () => {
    try {
      const queryResult = await axios.post(
        Sidebar.GRAPHQL_API,
        {
          query: Sidebar.GET_POSTS_QUERY
        }
      );
      const result = queryResult.data.data.posts;
      setSidebar({ posts: result.nodes });
    } catch (error) {
      console.error("Error fetching sidebar posts:", error);
    }
  };

  useEffect(() => {
    fetchSidebar();
  }, [slug]);



  // useEffect(() => {
  //   fetchApi();
  // }, [slug]); // Added [slug] dependency to avoid infinite loop

  // Example YouTube Embed Component
  // const YouTubeEmbed = ({ videoId }) => {
  //   const opts = {
  //     height: '390',
  //     width: '640',
  //     playerVars: {
  //       autoplay: 0,
  //     },
  //   };
  //   return <YouTube videoId={videoId} opts={opts} />;
  // };

  // Example function to extract YouTube video IDs from content
  // const extractYouTubeID = (content) => {
  //   const regex = /https?:\/\/www\.youtube\.com\/embed\/([a-zA-Z0-9_-]{11})/;
  //   const match = content.match(regex);
  //   return match ? match[1] : null;
  // };

  return (
    <div>
      <Navigation />
      <div id="article_header" className="container-fluid col-md-12 page-header-article"></div>

      <div id="one-page" className="wrapper">
        <div className="row">
          <div className="col-md-10">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item"><Link to="#"><strong>Articles</strong></Link></li>
            </ol>
          </div>
        </div>



        <div className="row g-0">

          {sidebar.posts.map((link, index) => (
            <div key={index} className={location.pathname === "/article/" + link.slug ? "active-menu d-flex align-items-center mt-3" : "mt-3 inactive d-flex align-items-center"}>
              <Link to={`/article/${link.slug}`}>
                <img src={link.featuredImage?.node.sourceUrl} className="mt-3 rounded" alt='...' style={{ width: '300px' }} />
              </Link>
              <Link to={`/article/${link.slug}`} className="text-sm ms-3">
                <h4 className="page-title text-link"> {link.title}</h4>
                <h5 className="page-title"> {new Date(link.date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</h5>

              </Link>
            </div>
          ))}




        </div>
      </div>
      <Footer />
    </div>
  );
}
