import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/media-center';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const OnlineDatabase = () => {

  const location = useLocation();


  return (
    <div>
      <Navigation />
      <div id="media_center" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="d-block d-md-none">
              <select
                className="form-select"
                onChange={(e) => window.location.href = e.target.value}
                value={location.pathname}
              >
                {sidebarLinks.map((link, index) => (
                  <option key={index} value={link.path}>
                    {link.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="/">Media Center</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>Online Database</strong></Link></li>
                </ol>
              </div>
            </div>



            <h4 className="mt-3 page-title">Media Centers Online Database</h4>
            <div className="col-md-12 mx-auto">

              <p className="mt-5"><strong>RESOURCES FOR VIRTUAL LEARNING AND TEACHING</strong></p>
              <p>The media centers are currently subscribing to world known research platforms that support the virtual learning and teaching of IKNS. Faculty and staff members and students need the database login account to access its resources. To get started, just click the database where your research topic falls under. Contact the librarians for more details.</p>




              <div className="mt-5">
                <img src={require('../../assets/img/media-center/ebsco_logo.jpg')} className="" alt='...' width="150" />
              </div>
              <a href="http://search.ebscohost.com" target="_blank" rel="noreferrer" className="btn btn-primary">Access EBSCO</a>

              <p className="mt-3"><strong>Ebsco (6-12)</strong> provides unlimited access to a variety of full text research database, academic journals, popular magazines, newspapers, ebooks and other scholarly resources.</p>

              <hr />
              <div className="mt-5">
                <img src={require('../../assets/img/media-center/britannica.jpg')} className="" alt='...' width="250" />
              </div>
              <a href="https://school.eb.com/levels" target="_blank" rel="noreferrer" className="btn btn-primary">Access Britannica</a>

              <p className="mt-3"><strong>Britannica (K-12)</strong> is an online reference database that provides various resources including encyclopedia articles, multimedia, primary sources, games, and other learning resources.</p>



              <hr />
              <div className="mt-5">
                <img src={require('../../assets/img/media-center/infobase_logo.jpg')} className="" alt='...' width="200" />
              </div>
              <a href="https://school.eb.com/levels" target="_blank" rel="noreferrer" className="btn btn-primary mt-2">Access Infobase Learning</a>

              <p className="mt-3"><strong>Infobase Learning (6-12)</strong> offers five subject-specific database covering History, Science & Mathematics, Geography & Culture and Current Issues. It also includes thousands of valuable support materials for teachers.</p>


              <hr />
              <div className="mt-5">
                <img src={require('../../assets/img/media-center/jstor_logo.jpg')} className="" alt='...' width="200" />
              </div>
              <a href="https://www.jstor.org/" target="_blank" rel="noreferrer" className="btn btn-primary mt-2">Access JSTOR</a>

              <p className="mt-3"><strong>JSTOR (9-12)</strong> contains thousands of peer-reviewed academic journals and books and millions of primary source collections ideal for supporting the IB Diploma students extended essays and the high school independent reseach. JSTOR will also be useful to teachers and parents.</p>



              <hr />
              <div className="mt-5">
                <img src={require('../../assets/img/media-center/learningAZ_logo.jpg')} className="" alt='...' width="180" />
              </div>
              <a href="https://accounts.learninga-z.com/accountsweb/account/login.do" target="_blank" rel="noreferrer" className="btn btn-primary mt-2">Access Learning A-Z</a>

              <p className="mt-3"><strong>Learning A-Z (K-6)</strong> deliver resources in Reading, Writing Science and Vocalbulary.</p>



              <hr />
              <div className="mt-5">
                <img src={require('../../assets/img/media-center/myon_logo.jpg')} className="" alt='...' width="200" />
              </div>
              <a href="https://www.myon.com" target="_blank" rel="noreferrer" className="btn btn-primary mt-2">Access myON</a>

              <p className="mt-3"><strong>MyON (K-5)</strong> is a personalized reading platform that provides the students access to thousands of enhanced digital books.</p>


              <hr />
              <div className="mt-5">
                <img src={require('../../assets/img/media-center/tumblebook_logo.jpg')} className="" alt='...' width="200" />
              </div>
              <a href="https://www.tumblebooklibrary.com/Default.aspx?ReturnUrl=%2f" target="_blank" rel="noreferrer" className="btn btn-primary mt-2">Access Tumblebook Library</a>

              <p className="mt-3"><strong>Tumblebook Library (K-6)</strong> offers students access to story books, read alongs, eBooks, graphics novels, videos, games and more.</p>



              <hr />
              <div className="mt-5">
                <img src={require('../../assets/img/media-center/worldbook_logo.jpg')} className="" alt='...' width="200" />
              </div>
              <a href="https://www.worldbookonline.com" target="_blank" rel="noreferrer" className="btn btn-primary mt-2">Access Worldbook</a>

              <p className="mt-3"><strong>Worldbook (4-12)</strong> is an online version of the widely known Worldbook Encyclopedia that includes thousands of topics, supplemental links, videos, and other resources for all students and teachers at all levels.</p>




            </div>
          </div>
        </div>
      </div>



      <Footer />
    </div>
  )
}

