import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/media-center';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const MediaCenter = () => {

  const location = useLocation();


  return (
    <div>
      <Navigation />
      <div id="media_center" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="d-block d-md-none">
              <select
                className="form-select"
                onChange={(e) => window.location.href = e.target.value}
                value={location.pathname}
              >
                {sidebarLinks.map((link, index) => (
                  <option key={index} value={link.path}>
                    {link.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>Media Center</strong></Link></li>
                </ol>
              </div>
            </div>



            <h4 className="mt-3 page-title">Welcome to the Media Centers</h4>
            <div className="col-md-12 mx-auto">
              <p>IKNS Media Centers are committed to provide updated resources and advanced technology services that support the curricula, teaching and research needs of the school community. The services are designed for K-12 students, faculty members, alumni and parents.</p>
              <p>There are two media centers at IKNS. The first is located in the Kindergarten and Elementary School and the second is in the Middle and Secondary schools (Fahad Abdulrahman Al Gosaibi Media Center).</p>
              <p>The Kindergarten and Elementary Media Center (K1- G5) provides 36,317 collections, including 24,895 titles and 1700 audio-visual materials.</p>
              <p>Fahad Abdulrahman Al Gosaibi Media Center (G6- G12) houses a wide range of over 41,500 collections consisting of 37,834 books and 3,588 audio-visual materials. The media center also subscribes to a growing number of online resources such as e-books, thousands of periodicals, and online databases (Ebsco, Infobase Learning, JSTOR and Worldbook).</p>
              <p>The media centers have an advanced network of technological support. In each media center, there are 24 computers and a Promethean interactive smartboard for group research and presentations. In addition, Fahad Abdulraham Al Gosaibi Media Center provides 13 individual computer workstations for students to access the online resources and Destiny catalogue.</p>


              <p className="content-title mt-5">PHILOSOPHY</p>
              <p>The Media Centers seek to support the IKNS curriculum by providing access to an extensive range of resources in varied formats, advanced technology services and a learning environment that encourages collaboration among the school community members.</p>
              <p>The Media Centers strive to offer instruction programs that equip students with academic integrity, research and 21st century literacy skills. These programs are designed to support students become better accomplished lifelong learners, critical thinkers and internationally minded learners.</p>

              <p className="content-title mt-5">VISION</p>
              <p>The vision of the Media Centers is to be the IKNS hub of collaborative learning, striving to offer a proactive program that empowers students to become life-long learners, skilled inquirers, globally minded citizens and productive future leaders.</p>


              <p className="content-title mt-5">MISSION</p>
              <p>The mission of the Media Centers is to provide a 21st century learning environment that encourages collaboration among the school community members and promotes the development of the accomplished life-long learners who are principled researchers and effective information and technology users.</p>


              <p className="content-title mt-5">GOALS AND OBJECTIVES:</p>
              <ul>
                <li className="mb-2">To establish a close partnership with administration, faculty members, alumni and parents for the improvement of the media center services and activities.</li>
                <li className="mb-2">To provide an open, safe, caring, collaborative and welcoming environment.</li>
                <li className="mb-2">To offer advanced technology services and updated resources in many languages that support the different subject areas and programs of PYP/MYP/DP, AHSD curriculum, units of inquiry, extracurricular activities, and teachers and students needs and interests.</li>

                <li className="mb-2">To collaborate with teachers to plan and teach students a library program that integrates ATL and library skills in academic integrity, research, technology, information and digital literacy.</li>

                <li className="mb-2">To widely promote the love of reading through book displays, book talks, library visits, book fairs, and many more reading activities and programs.</li>

                <li className="mb-2">To provide effective access to various library resources for students, faculty members and parents.</li>

                <li className="mb-2">To offer assistance to students finding relevant resources for their exhibitions, extended essays, personal and classroom research projects.</li>
              </ul>


            </div>
          </div>
        </div>
      </div>



      <Footer />
    </div>
  )
}

