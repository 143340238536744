import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/pyp';
import '../../styles/headers.css';
import '../../styles/innerpage.css';


export const KGElementaryRulesAndProcedures = () => {

  const location = useLocation();

  return (
    <div>
      <Navigation />
      <div id="pyp" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>

            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="d-block d-md-none">
              <select
                className="form-select"
                onChange={(e) => window.location.href = e.target.value}
                value={location.pathname}
              >
                {sidebarLinks.map((link, index) => (
                  <option key={index} value={link.path}>
                    {link.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item"><Link to="#">Academics</Link></li>
                  <li className="breadcrumb-item"><Link to="#"><strong>KG and Elementary Rules and Procedures</strong></Link></li>
                </ol>
                <h4 className="mt-3 page-title">KG and Elementary Rules and Procedures</h4>
              </div>
            </div>

            <p>
              At Ibn Khuldoon National School, we believe in fostering an environment that
              empowers our students to excel not only academically but also in their personal
              development. Our Code of Conduct plays a pivotal role in shaping the character
              and behavior of our students. It is a collective effort involving our students,
              their parents or guardians, our dedicated administrative and educational staff,
              and our teachers.
            </p>

            <a href="https://drive.google.com/file/d/1ngB1YTDp_jPtwvD70urIF83uj1DLmv-v/view?usp=sharing" target="_blank" rel="noreferrer" className="text-link">
              View KG and Elementary Rules and Procedures <i className="fas fa-arrow-right text-right"></i></a>


          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}


