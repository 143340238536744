import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";
import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const CycleCalendar = () => {

  return (
    <div>
      <Navigation />

      <div id="cycle_calendar" className="container-fluid col-md-12 page-header"></div>

      <div id="one-page" className="wrapper">
        <div className="row">


          <div className="col-md-10">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item"><Link to="#">Key Info</Link></li>
              <li className="breadcrumb-item"><Link to="#"><strong>Middle & Secondary Schools 6-day Cycle Calendar</strong></Link></li>
            </ol>
            <h4 className="mt-3 page-title">Middle & Secondary Schools 6-day Cycle Calendar</h4>
          </div>
        </div>


        <p className="text-sm">Our 6-day cycle is more than just a schedule—it&#39;s a framework that supports our mission to nurture engaged, curious, and capable students. By embracing this innovative approach, IKNS ensures that every student has the opportunity to reach their full potential both inside and outside the classroom.</p>

        <p className="text-sm">
          Explore our 6-day cycle calendar today and take advantage of all the exciting opportunities IKNS has to offer!
        </p>


        <a target="_blank" className="text-link d-block mb-1" rel="noreferrer" href="https://drive.google.com/file/d/1qfHU6SGGoZKRMua38XmmFI8eY7Sc-oje/view">Middle & Secondary Schools 6-day Cycle Calendar 2024-2025 </a>
        <a target="_blank" className="text-link d-block mb-1" rel="noreferrer" href="https://drive.google.com/file/d/1pv6qaV5y39kXHneFRfpYDjZw5u6AAzcc/view?usp=sharing">Middle & Secondary Schools 6-day Cycle Calendar 2023-2024</a>


        <div className="contact-section col-lg-9 mx-auto mt-5">
          <h4 className="mt-3 page-title">Contact Us</h4>
          <p>If you have questions about the Middle & Secondary Schools 6-day Cycle Calendar, we are here to help.</p>
          <Link to="/contact-us" className="text-link">Go to contact form <i className="fas fa-arrow-right text-right"></i></Link>
        </div>


      </div>
      <Footer />
    </div>
  )
}


