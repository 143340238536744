export const SidebarLinks = [

  {
    label: "Our Campus",
    path: "/our-campus"
  },

];

export default SidebarLinks;
